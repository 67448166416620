import { createSlice } from '@reduxjs/toolkit';
import { ParamModalActions } from '@redux/paramModal/actions';
import { ParamModalType } from './interfaces';

const initialState: ParamModalType = {
  valueKey: null,
  parameter: null,
};

const paramModal = createSlice({
  name: 'paramModal',
  initialState,
  reducers: {},
  extraReducers: {
    [ParamModalActions.OPEN]: (state, { payload }): void => {
      state.valueKey = payload.valueKey;
      state.parameter = payload.parameter;
    },
    [ParamModalActions.CLOSE]: (state): void => {
      state.valueKey = null;
    },
  },
});

const { reducer } = paramModal;

export default reducer;
