export enum FetchParametersAction {
  REQUESTED = '@parameters/FETCH_PARAMETERS_REQUESTED',
  FULFILLED = '@parameters/FETCH_PARAMETERS_FULFILLED',
  REJECTED = '@parameters/FETCH_PARAMETERS_REJECTED',
}

export enum FetchParametersWithoutReloadAction {
  REQUESTED = '@parameters/FETCH_PARAMETERS_WITHOUT_RELOAD_REQUESTED',
  FULFILLED = '@parameters/FETCH_PARAMETERS_WITHOUT_RELOAD_FULFILLED',
  REJECTED = '@parameters/FETCH_PARAMETERS_WITHOUT_RELOAD_REJECTED',
}

export enum UpdateParametersAction {
  REQUESTED = '@parameters/UPDATE_PARAMETERS_REQUESTED',
  FULFILLED = '@parameters/UPDATE_PARAMETERS_FULFILLED',
  REJECTED = '@parameters/UPDATE_PARAMETERS_REJECTED',
}
