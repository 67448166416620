import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@redux/store';
import { HistoryType } from './interfaces';
import { isEngineerSelector } from '@redux/user/selectors';

import { formatChartData, formatChartDataMode } from 'utils/charts';

const rootSelector = (state: RootState): HistoryType => state.dataHistory;

export const selectedParamsSelector = createSelector(rootSelector, ({ selectedParams }) => selectedParams);

export const isCdBoilerOperationModeActiveSelector = createSelector(
	selectedParamsSelector,
	selectedParams => !!selectedParams.cdBoilerOperationMode
)

export const isHistoryPendingSelector = createSelector(rootSelector, (dataHistory) => dataHistory.isPending);

export const dataHistoryItemsSelector = createSelector(
	[ rootSelector ],
	(dataHistory) => dataHistory.items
);

export const filtredItemsSelector = createSelector(
	[ dataHistoryItemsSelector, selectedParamsSelector ],
	(items, params) => Object.entries(items).filter(([key, value]) => params[key] && value.length > 0)
)

export const formattedHistoryDataSelector = createSelector(
	[ rootSelector, isHistoryPendingSelector ],
	(dataHistory, isPending) => !isPending && formatChartData(dataHistory.items)
);

export const formattedHistoryDataModeSelector = createSelector(
	[ rootSelector, isHistoryPendingSelector ],
	(dataHistory, isPending) => !isPending && formatChartDataMode(dataHistory.items)
);

const isVisibleData = (data: {value: string}[], key: string, isEngineer: boolean) => {
	const visibleForUser = isEngineer || key !== 'controllerActivity';

	return visibleForUser && (key === 'cdBoilerTemperature' || data.some(({value}) => value !== "0.00"));
}

export const graphParamsSelector = createSelector(
	[ rootSelector, isHistoryPendingSelector, isEngineerSelector ],
	(dataHistory, isPending, isEngineer) => 
		isPending ? {} : Object.keys(dataHistory.items).filter(key => isVisibleData(dataHistory.items[key], key, isEngineer)).sort(),
);

export const dateFromSelector = createSelector(rootSelector, (dataHistory) => dataHistory.dateFrom);

export const dateToSelector = createSelector(rootSelector, (dataHistory) => dataHistory.dateTo);
