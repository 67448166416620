import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import camelCase from 'lodash/camelCase';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import camelize from 'utils/camelize';
import { Channel } from 'actioncable';

import {
  FetchParametersWithoutReloadAction,
  UpdateParametersAction,
} from '@redux/parameters/actions';
import { UpdateAlarmsAction } from '@redux/alarms/actions';
import { UpdateDeviceSettingsActions } from '@redux/deviceSettings/actions';
import { isPushActiveSelector } from '@redux/deviceSettings/selectors';
import { SocketContext } from 'components/SocketProvider';
import ModalContext from '../../context/ModalContext';

export default (deviceId: string): void => {
  const cable = useContext(SocketContext);
  const { enqueueSnackbar } = useSnackbar();
  const isPushActive = useSelector(isPushActiveSelector);
  const { openModal, closeModal } = useContext(ModalContext);
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const updateParameters = React.useCallback(
    (payload) => {
      const parametersWithKey: { [key: string]: any } = {};

      payload.parameters.forEach((parameter: any) => {
        parametersWithKey[parameter.key] = parameter;
      });

      dispatch({
        type: UpdateParametersAction.FULFILLED,
        payload: parametersWithKey,
      });
    },
    [dispatch],
  );

  const handleReceiver = React.useCallback(
    (data) => {
      const { context, payload } = camelize.camel(data);

      if (
        context.action === 'update' &&
        (context.payloadType === 'update_module_software' ||
          context.payloadType === 'update_controller_software')
      ) {
        dispatch({ type: UpdateDeviceSettingsActions.REJECTED });
      }

      // Trigger modal closing when hardware update successful
      if (
        context.action === 'update' &&
        context.status === 'success' &&
        context.payloadType === 'empty_payload' &&
        context.guid
      ) {
        closeModal();
      }

      if (
        context.action === 'create' &&
        (context.payloadType === 'update_module_software' ||
          context.payloadType === 'update_controller_software')
      ) {
        openModal('hardWareUpdateModal', {});
      }

      if (
        context.action === 'update' &&
        context.payloadType === 'alarm_data' &&
        isPushActive
      ) {
        dispatch({ type: UpdateAlarmsAction, payload });

        return enqueueSnackbar(t(`alarmItems.${payload.key}`), {
          variant: 'success',
        });
      }

      if (context.payloadType === 'error' && isPushActive) {
        const key = get(payload, 'error.key', null);
        const translation = key
          ? t(`alarmItems.${key}`)
          : t(`parameters.${camelCase(payload.key)}`);

        return enqueueSnackbar(!isEmpty(payload) ? translation : '', {
          variant: 'error',
        });
      }

      if (
        context.action === 'create' &&
        context.payloadType === 'parameters_data' &&
        isPushActive
      ) {
        return enqueueSnackbar(
          t(`parameters.${camelCase(payload.parameters[0].key)}`),
          {
            variant: 'success',
          },
        );
      }

      if (
        context.action === 'create' &&
        context.payloadType === 'error' &&
        isPushActive
      ) {
        dispatch({
          type: FetchParametersWithoutReloadAction.REQUESTED,
          payload: { id },
        });

        return enqueueSnackbar(t(`parameters.${camelCase(payload.key)}`), {
          variant: 'error',
        });
      }

      if (
        context.action === 'create' &&
        context.payloadType === 'alarm_data' &&
        isPushActive
      ) {
        dispatch({ type: UpdateAlarmsAction, payload });

        return enqueueSnackbar(t(`alarmItems.${payload.key}`), {
          variant: 'error',
        });
      }

      if (context.payloadType === 'parameters_data') {
        return updateParameters(payload);
      }
    },
    [isPushActive, enqueueSnackbar, updateParameters],
  );

  const isSubscribed = React.useRef(false);

  React.useEffect(() => {
    let subscription: Channel | null = null;

    const setSubscription = (): void => {
      subscription = cable.addSubscribe(
        {
          channel: 'Users::DeviceChannel',
        },
        handleReceiver,
      );
    };

    if (id) {
      /**
       * The first subscription can be sent synchronously, but the next ones
       * should be send with a delay. Because the cleanup function of the effect
       * will be triggered exactly at the same time as the effect itself (sequentially),
       * so the backend does not understand which message to process first - for
       * unsubscribing or re-subscribing.
       */
      if (isSubscribed.current) setTimeout(setSubscription, 100);
      else setSubscription();

      isSubscribed.current = true;
    }

    return (): void => {
      if (id) {
        cable.removeSubscribe(subscription);
      }
    };
  }, [deviceId, handleReceiver]);
};
