export enum FetchDeviceSettingsActions {
  REQUESTED = '@devices/FETCH_DEVICE_SETTINGS_REQUESTED',
  FULFILLED = '@devices/FETCH_DEVICE_SETTINGS_FULFILLED',
  REJECTED = '@devices/FETCH_DEVICE_SETTINGS_REJECTED',
}

export enum DeviceSettingsModuleVerisonModalActions {
  OPEN = '@devices/DEVICE_SETTINGS_MODULE_VERSION_MODAL_OPEN',
  CLOSE = '@devices/DEVICE_SETTINGS_MODULE_VERSION_MODAL_CLOSE',
}

export enum DeviceSettingsControllerVerisonModalActions {
  OPEN = '@devices/DEVICE_SETTINGS_CONTROLLER_VERSION_MODAL_OPEN',
  CLOSE = '@devices/DEVICE_SETTINGS_CONTROLLER_VERSION_MODAL_CLOSE',
}

export enum FetchModuleVersionsActions {
  REQUESTED = '@devices/FETCH_MODULE_VERSIONS_REQUESTED',
  FULFILLED = '@devices/FETCH_MODULE_VERSIONS_FULFILLED',
  REJECTED = '@devices/FETCH_MODULE_VERSIONS_REJECTED',
}

export enum FetchControllerVersionsActions {
  REQUESTED = '@devices/FETCH_CONTROLLER_VERSIONS_REQUESTED',
  FULFILLED = '@devices/FETCH_CONTROLLER_VERSIONS_FULFILLED',
  REJECTED = '@devices/FETCH_CONTROLLER_VERSIONS_REJECTED',
}

export enum UpdateModuleVersionActions {
  REQUESTED = '@devices/UPDATE_MODULE_VERSION_REQUESTED',
  FULFILLED = '@devices/UPDATE_MODULE_VERSION_FULFILLED',
  REJECTED = '@devices/UPDATE_MODULE_VERSION_REJECTED',
}

export enum UpdateControllerVersionActions {
  REQUESTED = '@devices/UPDATE_CONTROLLER_VERSION_REQUESTED',
  FULFILLED = '@devices/UPDATE_CONTROLLER_VERSION_FULFILLED',
  REJECTED = '@devices/UPDATE_CONTROLLER_VERSION_REJECTED',
}

export enum UpdateDeviceSettingsActions {
  REQUESTED = '@devices/UPDATE_DEVICE_SETTINGS_REQUESTED',
  FULFILLED = '@devices/UPDATE_DEVICE_SETTINGS_FULFILLED',
  REJECTED = '@devices/UPDATE_DEVICE_SETTINGS_REJECTED',
}

export enum ChangeDeviceSettingsActions {
  REQUESTED = '@devices/CHANGE_DEVICE_SETTINGS_REQUESTED',
}
