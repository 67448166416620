import React, { useEffect, memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Page from 'components/Page';
import PageWrapper from 'components/PageWrapper';
import Paper from 'components/Interfaces/Paper';
import Typography from 'components/Interfaces/Typography';
import Progress from 'components/Interfaces/Progress';
import DeviceDeletedModal from 'components/Modal/DeviceDeletedModal';
import Header from 'components/Header';
import Button from 'components/Interfaces/NewButton';
import DeviceBlock from './DeviceBlock';
import FilterSwitch from './FilterSwitch';
import Search from './Search';

import { Box } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

import { CustomRouteComponentProps } from 'constants/routes';

import {
  devicesSelector,
  numberOfDevicesSelector,
  numberOfPagesSelector,
  areDevicesPendingSelector,
} from '@redux/devices/selectors';

import { isModalOpenSelector } from '@redux/modal/selectors';
import { FetchDevicesActions } from '@redux/devices/actions';
import { ModalActions } from '@redux/modal/actions';

import { Device } from '@redux/devices/interfaces';

import * as Styled from './Devices.styled';
import UserRole from '../../components/UserRole';

const Devices: React.FC<CustomRouteComponentProps> = (
  props: CustomRouteComponentProps,
) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [filter, setFilter] = useState<string | null>('all');
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [isCloseBtnVisible, setCloseBtnVisible] = useState(false);

  const devices = useSelector(devicesSelector);
  const deviceCount = useSelector(numberOfDevicesSelector);
  const pageCount = useSelector(numberOfPagesSelector);
  const isPending = useSelector(areDevicesPendingSelector);
  const isModalOpen = useSelector(isModalOpenSelector);

  const handleFilterChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, newFilter: string | null) => {
      setFilter(newFilter);
    },
    [],
  );

  const handleFetchDevices = useCallback(
    (page, filter, search) => {
      setPage(page);
      setFilter(filter);
      setSearch(search);
      dispatch({
        type: FetchDevicesActions.REQUESTED,
        payload: { page, filter, search },
      });
    },
    [dispatch],
  );

  const handlePageChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      handleFetchDevices(value, filter, search);
    },
    [filter, search, handleFetchDevices],
  );

  const handleFetchAll = useCallback(() => {
    handleFetchDevices(1, 'all', '');
  }, [handleFetchDevices]);

  const handleFetchActive = useCallback(() => {
    handleFetchDevices(1, 'active', '');
  }, [handleFetchDevices]);

  const handleFetchDisabled = useCallback(() => {
    handleFetchDevices(1, 'disabled', '');
  }, [handleFetchDevices]);

  const handleSearch = useCallback(() => {
    handleFetchDevices(1, 'all', search);
  }, [handleFetchDevices, search]);

  useEffect(() => {
    handleFetchAll();
  }, [handleFetchAll]);

  const handleSearchChange = useCallback(
    (event: any) => {
      const { value } = event.target;
      setSearch(value);
      setCloseBtnVisible(value.length >= 3);
    },
    [setSearch],
  );

  const handleSearchClear = useCallback(() => {
    setSearch('');
    setCloseBtnVisible(false);
  }, [setSearch]);

  const handleModalClose = useCallback(() => {
    dispatch({ type: ModalActions.CLOSE });
  }, [dispatch]);

  return (
    <Page title={props.title}>
      <Header title="devices" lang back profile fixed />
      <PageWrapper>
        <Styled.Root>
          <Progress visible={isPending}>
            <Paper
              width={{ xs: '735px', sm: '871px' }}
              minHeight="230px"
              px={{ xs: 2, md: 7 }}
              py={{ xs: 4, md: 10 }}
              pb="70px"
            >
              <Box
                alignItems="flex-start"
                paddingBottom="10px"
                display="flex"
                justifyContent="space-between"
                px={{ xs: '10px', sm: '12px' }}
              >
                <Typography variant="h1" mb={3}>
                  {t('foundDevices')}
                  <Box component="span" fontWeight="600" ml={1}>
                    {deviceCount}
                  </Box>
                </Typography>
                <UserRole role="customer">
                  <Button component={Link} variant="outlined" to="/add-device">
                    {t('addDevice')}
                  </Button>
                </UserRole>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                px={{ xs: '10px', sm: '12px' }}
                mb={5}
              >
                <FilterSwitch
                  value={filter}
                  onChangeFilter={handleFilterChange}
                  onClickAll={handleFetchAll}
                  onClickActive={handleFetchActive}
                  onClickDisabled={handleFetchDisabled}
                />
                <Search
                  search={search}
                  isCloseBtnVisible={isCloseBtnVisible}
                  onChange={handleSearchChange}
                  onSubmit={handleSearch}
                  onClear={handleSearchClear}
                />
              </Box>
              {devices.length !== 0 ? (
                <>
                  {devices.map((item: Device) => (
                    <DeviceBlock
                      key={item.uid}
                      type={item.deviceModel.title}
                      identifier={item.uid}
                      title={item.title}
                      userHasDeviceAccess={item.userHasDeviceAccess}
                      softVersion={item.dsSoftVerInternetModuleVersion}
                      controllerVersion={item.dsSoftVerControllerVersion}
                      isModuleConnected={Boolean(
                        item.moduleStatus !== 'disconnected',
                      )}
                      isControllerConnected={Boolean(
                        item.controllerStatus !== 'disconnected',
                      )}
                    />
                  ))}
                  {pageCount !== null && pageCount > 1 && (
                    <Box display="flex" justifyContent="center" mt={6}>
                      <Pagination
                        count={pageCount}
                        page={page}
                        onChange={handlePageChange}
                      />
                    </Box>
                  )}
                </>
              ) : (
                <Styled.NothingFound>
                  <Typography variant="body1">{t('noDevicesFound')}</Typography>
                </Styled.NothingFound>
              )}
            </Paper>
          </Progress>
        </Styled.Root>
        <DeviceDeletedModal open={isModalOpen} handleClose={handleModalClose} />
      </PageWrapper>
    </Page>
  );
};

export default memo(Devices);
