import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

import Page from 'components/Page';
import FormWrapper from 'components/FormWrapper';
import SignUp from './SignUp';

import { isAuthPending } from '@redux/application/selectors';
import { SignUpActions } from '@redux/application/actions';

import authAPI from 'api/auth';
import { SignUpRequest } from 'api/auth/types';
import { CustomRouteComponentProps } from 'constants/routes';
import submissionError from 'utils/submissionError';
import { serverErrors } from 'constants/errors';
import Header from '../../components/Header';

const SignUpContainer: React.FC<CustomRouteComponentProps> = (
  props: CustomRouteComponentProps,
) => {
  const dispatch = useDispatch();
  const isPending = useSelector(isAuthPending);
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);

  const handleClick = React.useCallback(
    (values: SignUpRequest, actions: FormikHelpers<SignUpRequest>) => {
      authAPI
        .signUp(values)
        .then(({ data }) => {
          dispatch({ type: SignUpActions.FULFILLED, payload: data });
          dispatch(push('/not-confirmed'));
        })
        .catch((error) => {
          dispatch({ type: SignUpActions.REJECTED });
          const { details } = error.response.data.error;
          if (details.email[0].error === 'taken') {
            setError(serverErrors.email.taken);
          }
          actions.setErrors(submissionError(error.response));
        });
    },
    [dispatch],
  );

  return (
    <Page title={props.title}>
      <Header back logo lang fixed />
      <FormWrapper title={t('signUp')} icon="lock" error={error}>
        <SignUp isPending={isPending} onSubmit={handleClick} />
      </FormWrapper>
    </Page>
  );
};

export default SignUpContainer;
