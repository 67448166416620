import styled from 'styled-components/macro';
import theme from 'styles/theme';

export const Root = styled.div`
  max-width: 425px;
  margin: 0 auto 40px;

  ${theme.breakpoints.up('sm')} {
    max-width: 550px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const LabelWrapper = styled.div`
  width: 105px;
  margin-right: 15px;

  ${theme.breakpoints.up('sm')} {
    width: 120px;
    margin-right: 25px;
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 105px);

  ${theme.breakpoints.up('sm')} {
    width: calc(100% - 120px);
  }
`;
