import React from 'react';
import Value from 'components/Commons/Value';
import BaseParameter from '../BaseParameter/BaseParameter';
import { useTranslation } from 'react-i18next';

type Props = {
  unit: string;
  value: string;
  valueKey: string;
  size: 'small' | 'medium' | 'big';
};

const PercentParameter: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <BaseParameter
      modalType="numberValueModal"
      editKey={props.valueKey}
      {...props}
    >
      <Value
        type="number"
        size={props.value !== 'null' ? props.size : 'default'}
        value={props.value}
        defaultValue={t('noData')}
        sup="%"
      />
    </BaseParameter>
  );
};

PercentParameter.defaultProps = {
  size: 'big',
};

export default PercentParameter;
