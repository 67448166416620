import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';

import Typography from 'components/Interfaces/Typography';
import Button from 'components/Interfaces/NewButton';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import useStyles from './styles';

type Props = {
  open: boolean;
  handleClose: () => void;
};

const EmailConfirmedModal: React.FC<Props> = ({ open, handleClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Modal open={open} handleClose={handleClose}>
      <div className={classes.wrapper}>
        <CheckCircleIcon className={classes.successIcon} />
        <Typography variant="h5">{t('success')}</Typography>
        <Typography mt="30px">{t('emailConfirmed')}</Typography>
        <Typography>{t('continueAndAddDevices')}</Typography>
        <Button mt="48px" variant="contained" onClick={handleClose}>
          {t('continue')}
        </Button>
      </div>
    </Modal>
  );
};

export default memo(EmailConfirmedModal);
