import { useEffect, useRef } from 'react';
import apiAlarms from 'api/alarms';
import { keyBy, pickBy, startsWith } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { Alarm } from '@redux/alarms/interfaces';
import { AxiosResponse } from 'axios';
import { UpdateAlarmsActions } from '@redux/alarms/actions';
import { FetchAlarmsResponse } from 'api/alarms/types';

export default (deviceId: string): void => {
  const dispatch = useDispatch();
  const interval = useRef<number | undefined>();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const alarms = useRef<Alarm | []>([]);

  const fetchAlarms = (): Promise<AxiosResponse<FetchAlarmsResponse>> =>
    apiAlarms.fetchAlarms({ id: deviceId, perPage: 40, page: 1 });

  useEffect(() => {
    if (deviceId) {
      fetchAlarms().then(({ data }) => {
        alarms.current = (data as any).alarms;
      });

      interval.current = setInterval(() => {
        fetchAlarms().then(({ data }) => {
          const nextAlarms = (data as any).alarms;
          const alarmsById = keyBy(alarms.current, 'from');
          const updatedAlarms = nextAlarms.reduce(
            (result: Alarm[], alarm: Alarm) => {
              const prevAlarm = Object.values(
                pickBy(alarmsById, (_, key) => startsWith(key, alarm.from)),
              ).pop();

              if (!prevAlarm) {
                enqueueSnackbar(t(`alarmItems.${alarm.key}`), {
                  variant: 'error',
                });
                result.push(alarm);
              } else if (!(prevAlarm as any).to && alarm.to) {
                enqueueSnackbar(t(`alarmItems.${alarm.key}`), {
                  variant: 'success',
                });
                result.push(alarm);
              }
              return result;
            },
            [],
          );

          if (updatedAlarms.length) {
            dispatch({
              type: UpdateAlarmsActions.UPDATE,
              payload: { alarms: updatedAlarms },
            });
          }

          alarms.current = (data as any).alarms;
        });
      }, 15 * 1000);
    } else {
      clearInterval(interval.current);
    }

    return (): void => {
      clearInterval(interval.current);
    };
  }, [deviceId]);
};
