import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@redux/store';
import { DevicesType } from './interfaces';

const rootSelector = (state: RootState): DevicesType => state.devices;

export const devicesSelector = createSelector(
  rootSelector,
  (devices) => devices.items,
);

export const numberOfDevicesSelector = createSelector(
  rootSelector,
  (devices) => devices.itemCount,
);

export const numberOfPagesSelector = createSelector(
  rootSelector,
  (devices) => devices.pageCount,
);

export const areDevicesPendingSelector = createSelector(
  rootSelector,
  (devices) => devices.isPending,
);
