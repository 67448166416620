import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import Page from 'components/Page';
import Progress from 'components/Interfaces/Progress';
import UserSettings from './UserSettings';

import { FetchParametersAction } from '@redux/parameters/actions';
import { isPendingParametersSelector } from '@redux/parameters/selectors';
import { CustomRouteComponentProps } from 'constants/routes';

const UserSettingsContainer: React.FC<CustomRouteComponentProps> = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const isPendingParameters = useSelector(isPendingParametersSelector);

  React.useEffect(() => {
    dispatch({ type: FetchParametersAction.REQUESTED, payload: { id } });
  }, [dispatch, id]);

  return (
    <Page title={props.title}>
      <Progress visible={isPendingParameters}>
        <UserSettings />
      </Progress>
    </Page>
  );
};

export default UserSettingsContainer;
