import React, { useCallback, useContext, useState } from 'react';
import { Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';

import ModalContext from 'context/ModalContext';
import useMemoSelector from 'hooks/useMemoSelector';
import BaseModal from '../BaseModal';
import * as Styled from './SelectValueModal.styled';
import { parameterByNameSelector } from '../../../@redux/parameters/selectors';

import Button from '../../Interfaces/NewButton';
import { UpdateDeviceParameterActions } from '../../../@redux/devices/actions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import getSelectOptions from '../../../utils/getSelectOptions';

type Props = {
  data: any;
};

const useStyles = makeStyles(() => ({
  root: {
    width: 500,
    marginTop: 20,
  },
  input: {
    width: '100%',
  },
}));

const SelectValueModal: React.FC<Props> = ({ data }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const { closeModal } = useContext(ModalContext);
  const classes = useStyles();

  const { key, value, ...params } = useMemoSelector(
    parameterByNameSelector,
    data.key,
  );

  const [changedValue, setValue] = useState(value);

  const handleSave = useCallback(() => {
    closeModal();
    dispatch({
      type: UpdateDeviceParameterActions.REQUESTED,
      payload: { uid: id, key, value: changedValue },
    });
  }, [id, key, changedValue, dispatch]);

  const handleChange = useCallback(
    (event) => {
      setValue(event.target.value);
    },
    [setValue],
  );

  const selectOptions = getSelectOptions(params.enum);

  return (
    <BaseModal onClose={closeModal}>
      <Styled.Root>
        <Styled.Title>{t(`parameters.${camelCase(data.key)}`)}</Styled.Title>
        <Styled.Body>
          <InputLabel id="1" />
          <Select
            className={classes.input}
            label="label1"
            labelId="1"
            variant="outlined"
            value={changedValue}
            onChange={handleChange}
          >
            {selectOptions.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.title}
              </MenuItem>
            ))}
          </Select>
        </Styled.Body>
        <Styled.Buttons>
          <Button gray onClick={closeModal}>
            {t('cancel')}
          </Button>
          <Button onClick={handleSave}>{t('save')}</Button>
        </Styled.Buttons>
      </Styled.Root>
    </BaseModal>
  );
};

export default SelectValueModal;
