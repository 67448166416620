import React, { useCallback, useState } from 'react';

import Item from './Item';
import * as Styled from './List.styled';
import { useTranslation } from 'react-i18next';
import Header from './Header';

type Props = {
  title: string;
  items: any[];
  inner?: boolean;
  onOpenModal: (item: any) => void;
  onOpenWarningModal: (text: string) => void;
};

const List: React.FC<Props> = ({
  title,
  items,
  inner,
  onOpenModal,
  onOpenWarningModal,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    setOpen((state) => !state);
  }, []);

  return (
    <div>
      <Header open={open} inner={inner} onClick={handleClick}>
        {t(title)}
      </Header>
      {open && (
        <Styled.Content inner={inner}>
          {Object.entries(items).map(([name, item]) => {
            if (!item.key)
              return (
                <Styled.InnerList key={name}>
                  <List
                    title={name}
                    items={item}
                    inner
                    onOpenModal={onOpenModal}
                    onOpenWarningModal={onOpenWarningModal}
                  />
                </Styled.InnerList>
              );

            return (
              <Item
                key={name}
                item={item}
                onOpenModal={onOpenModal}
                onOpenWarningModal={onOpenWarningModal}
              />
            );
          })}
        </Styled.Content>
      )}
    </div>
  );
};

export default List;
