import React, { useState, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { userSelector } from '@redux/user/selectors';
import { CustomRouteComponentProps } from 'constants/routes';

import Page from 'components/Page';
import PageWrapper from 'components/PageWrapper';
import Paper from 'components/Interfaces/Paper';
import Typography from 'components/Interfaces/Typography';

import { Box, Button } from '@material-ui/core';

import * as Styled from './NotConfirmed.styled';

const NotConfirmed: React.FC<CustomRouteComponentProps> = (
  props: CustomRouteComponentProps,
) => {
  const [email, setEmail] = useState('');
  const user = useSelector(userSelector);
  const { t } = useTranslation();

  useEffect(() => {
    if (user && user.email) {
      setEmail(user.email);
    }
  }, [user]);

  return (
    <Page title={props.title}>
      <PageWrapper>
        <Styled.Root>
          <Paper px={6} py={8} textAlign="center">
            <Typography variant="h5" mb={4}>
              {t('verifyEmail')}
            </Typography>
            <Typography variant="body1" mb={3}>
              {t('almostThere')}
              <Box component="span" display="block" fontWeight="600">
                {email}
              </Box>
            </Typography>
            <Typography variant="body1" mb={6}>
              {t('clickToComplete')}
            </Typography>
            <Button color="primary" variant="contained">
              {t('resendEmail')}
            </Button>
          </Paper>
        </Styled.Root>
      </PageWrapper>
    </Page>
  );
};

export default memo(NotConfirmed);
