import React from 'react';
import BaseParameter from '../../BaseParameter/BaseParameter';
import Value from '../../../../Commons/Value/Value';
import useMemoSelector from '../../../../../hooks/useMemoSelector';
import { parameterByNameSelector } from '../../../../../@redux/parameters/selectors';
import { Grid } from '@material-ui/core';
import Typography from '../../../../Interfaces/Typography/Typography';
import { useTranslation } from 'react-i18next';

type Props = {
  value: string;
  unit: string;
  valueKey: string;
  editable: boolean;
  parameters: any;
};

const CustomFuelLevel: React.FC<Props> = () => {
  const { t } = useTranslation();
  const parameter = useMemoSelector(parameterByNameSelector, 'CD_Fuel_level');

  if (Number(parameter.value) > 100) {
    return (
      <BaseParameter
        {...parameter}
        icon="fullLevel"
        valueKey={parameter.key}
        editable={false}
      >
        <Grid container justify="center">
          <Typography fontSize="28px" lineHeight="70px">
            {t(`stateParameters.calibration`)}
          </Typography>
        </Grid>
      </BaseParameter>
    );
  }

  return (
    <BaseParameter
      {...parameter}
      icon="fullLevel"
      modalType="numberValueModal"
      editKey="cdFuelLevel"
      editable={false}
      valueKey="cdFuelLevel"
    >
      <Value type="number" size="big" value={parameter.value} sup="%" />
    </BaseParameter>
  );
};

export default CustomFuelLevel;
