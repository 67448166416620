import React from 'react';
import { FormikHelpers } from 'formik';
import queryString from 'query-string';

import userAPI from 'api/user';
import { UserUpdateRequest } from 'api/user/types';
import ChangeEmail from './ChangeEmail';
import submissionError from 'utils/submissionError';
import Page from 'components/Page';
import { CustomRouteComponentProps } from 'constants/routes';
import Progress from 'components/Interfaces/Progress';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import Header from '../../components/Header';

const ChangeEmailContainer: React.FC<CustomRouteComponentProps> = (props) => {
  const dispatch = useDispatch();
  const [isSent, setSending] = React.useState(false);

  const { email, confirmation_token } = queryString.parse(
    props.location.search,
  );

  const handleSubmit = React.useCallback(
    (values: UserUpdateRequest, actions: FormikHelpers<UserUpdateRequest>) => {
      return userAPI
        .userUpdate(values)
        .then(() => {
          setSending(true);
        })
        .catch((error) => {
          actions.setErrors(submissionError(error.response));
        });
    },
    [],
  );

  React.useEffect(() => {
    if (email && confirmation_token) {
      userAPI
        .changeEmail({
          email: email as string,
          confirmationToken: confirmation_token as string,
        })
        .finally(() => {
          dispatch(push('/devices'));
        });
    }
  }, [dispatch, email, confirmation_token]);

  const handleGoToDevices = React.useCallback(() => {
    dispatch(push('/devices'));
  }, [dispatch]);

  if (email && confirmation_token) {
    return <Progress visible={true} />;
  }

  return (
    <Page title={props.title}>
      <Header logo lang back fixed />
      <ChangeEmail
        isSent={isSent}
        onSubmit={handleSubmit}
        onContinue={handleGoToDevices}
      />
    </Page>
  );
};

export default ChangeEmailContainer;
