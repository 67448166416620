export default `
    <div>
      <div id="cooPolicy">
          <h2>General Provisions</h2>
          This personal data processing policy has been drawn up in accordance with the requirements of the Federal Law of July 27, 2006. № 152-ФЗ "On personal data" and determines the procedure for processing personal data and measures to ensure the security of personal data, taken by Individual Entrepreneur Podguyko Nikolay Andreevich (hereinafter - the Operator).<br /><br />
          1. The operator sets as its most important goal and condition for the implementation of its activities the observance of the rights and freedoms of a person and citizen when processing his personal data, including the protection of the rights to privacy, personal and family secrets.<br />
          2. This Operator's policy regarding the processing of personal data (hereinafter referred to as the Policy) applies to all information that the Operator can obtain about visitors to the website https://bmore.com.ru.<br />
          <h2> Basic concepts used in the Policy</h2>
          1. Automated processing of personal data - processing of personal data using computer technology;<br />
          2. Blocking of personal data - temporary termination of the processing of personal data (except for cases where processing is necessary to clarify personal data);<br />
          3. Website - a set of graphic and information materials, as well as computer programs and databases, ensuring their availability on the Internet at the network address https://bmore.com.ru;<br />
          4. Information system of personal data - a set of personal data contained in databases, and providing their processing of information technologies and technical means;<br />
          5. Depersonalization of personal data - actions as a result of which it is impossible to determine, without the use of additional information, the belonging of personal data to a specific User or other subject of personal data;<br />
          6. Processing of personal data - any action (operation) or a set of actions (operations) performed using automation tools or without using such tools with personal data, including collection, recording, systematization, accumulation, storage, clarification (update, change), extraction, use, transfer (distribution, provision, access), depersonalization, blocking, deletion, destruction of personal data;<br />
          7. Operator - a state body, a municipal body, a legal or natural person, independently or jointly with other persons organizing and (or) processing personal data, as well as determining the purposes of processing personal data, the composition of personal data to be processed, actions (operations) performed with personal data;<br />
          8. Personal data - any information relating directly or indirectly to a specific or identifiable User of the website https://bmore.com.ru;<br />
          9. User - any visitor to the website https://bmore.com.ru;<br />
          10. Provision of personal data - actions aimed at disclosing personal data to a certain person or a certain circle of persons;<br />
          11. Dissemination of personal data - any actions aimed at disclosing personal data to an indefinite circle of persons (transfer of personal data) or at acquaintance with the personal data of an unlimited number of persons, including the disclosure of personal data in the media, posting in information and telecommunication networks or providing access to personal data in any other way;<br />
          12. Cross-border transfer of personal data - transfer of personal data to the territory of a foreign state to the authority of a foreign state, to a foreign individual or foreign legal entity;<br />
          13. Destruction of personal data - any actions as a result of which personal data is destroyed irrevocably with the impossibility of further restoring the content of personal data in the personal data information system and (or) material carriers of personal data are destroyed.<br />
          <h2>The operator can process the following personal data of the User</h2>
          1. Email address;<br />
          2. The site also collects and processes anonymized data about visitors (including cookies) using Internet statistics services (Yandex Metrica and Google Analytics and others).<br />
          3. The above data hereinafter in the text of the Policy are united by the general concept of Personal data.<br />
          <h2>Purposes of processing personal data</h2>
          1. The purpose of processing the User's personal data is to inform the User by sending emails; providing the User with access to services, information and / or materials contained on the website.<br />
          2. Also, the Operator has the right to send the User notifications about new products and services, special offers and various events. The user can always refuse to receive informational messages by sending a letter to the Operator at the e-mail address nikolay.podguyko@gmail.com with the note “Refusal to receive notifications about new products and services and special offers”.<br />
          3. Anonymized User data collected using Internet statistics services are used to collect information about the actions of Users on the site, improve the quality of the site and its content.<br />
          <h2>Legal basis for the processing of personal data</h2>
          1. The Operator processes the User's personal data only if it is filled in and / or sent by the User independently through special forms located on the website https://bmore.com.ru. By filling out the appropriate forms and / or sending his personal data to the Operator, the User agrees with this Policy.<br />
          2. The Operator processes anonymized data about the User if it is allowed in the settings of the User's browser (the storage of cookies and the use of JavaScript technology are enabled).<br />
          <h2>The procedure for collecting, storing, transferring and other types of processing of personal data</h2>
          The security of personal data processed by the Operator is ensured through the implementation of legal, organizational and technical measures necessary to fully comply with the requirements of the current legislation in the field of personal data protection.<br />
          1. The operator ensures the safety of personal data and takes all possible measures to exclude access to personal data of unauthorized persons.<br />
          2. The User's personal data will never, under any circumstances, be transferred to third parties, except in cases related to the implementation of the current legislation.<br />
          3. In the event of inaccuracies in personal data, the User can update them independently by sending a notification to the Operator to the Operator's e-mail address nikolay.podguyko@gmail.com marked “Updating personal data”.<br />
          4. The period for processing personal data is unlimited. The user can at any time revoke his consent to the processing of personal data by sending a notification to the Operator via e-mail to the Operator's email address nikolay.podguyko@gmail.com with the note “Revocation of consent to the processing of personal data”.<br />
          <h2>Cross-border transfer of personal data</h2>
          1. Before starting the cross-border transfer of personal data, the operator is obliged to make sure that the foreign state, to whose territory it is supposed to transfer personal data, provides reliable protection of the rights of subjects of personal data.<br />
          2. Cross-border transfer of personal data on the territory of foreign states that do not meet the above requirements can be carried out only if there is a written consent of the subject of personal data for the cross-border transfer of his personal data and / or execution of an agreement to which the subject of personal data is a party.
          <h2>Final provisions</h2>
          1. The user can receive any clarifications on issues of interest regarding the processing of his personal data by contacting the Operator via e-mail nikolay.podguyko@gmail.com.<br />
          2. This document will reflect any changes in the personal data processing policy by the Operator. The policy is valid indefinitely until it is replaced by a new version.<br />
          3. The current version of the Policy is freely available on the Internet at https://bmore.com.ru/privacy-policy.<br />
      </div>
  </div>
`;
