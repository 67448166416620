import React, { useEffect, useState } from 'react';
import LowTierDeviceModal from 'components/Modal/LowTierDeviceModal';

type Props = {
  minWidth: number;
  maxWidth: number;
  children: any;
};

const LowTierDeviceDetector: React.FC<Props> = ({
  minWidth,
  maxWidth,
  children,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = (): void => {
    setModalOpen(false);
    setDisplayed(true);
  };
  const openModal = (): void => {
    setModalOpen(true);
  };
  const [displayed, setDisplayed] = useState(false);

  const handleResize = (): void => {
    if (
      window.innerWidth >= minWidth &&
      window.innerWidth <= maxWidth &&
      openModal
    ) {
      openModal();
    }
  };

  useEffect(() => {
    if (displayed) {
      window.removeEventListener('resize', handleResize);
    } else {
      window.addEventListener('resize', handleResize);
    }

    return (): void => {
      window.removeEventListener('resize', handleResize);
    };
  }, [displayed]);

  useEffect(() => {
    handleResize();
  }, []);

  return (
    <>
      {!displayed && (
        <LowTierDeviceModal open={modalOpen} handleClose={closeModal} />
      )}
      {children}
    </>
  );
};

export default LowTierDeviceDetector;
