import styled from 'styled-components/macro';

type Props = {
  size: 'small' | 'default' | 'medium' | 'big';
};

const sizes = {
  small: 24,
  default: 32,
  medium: 44,
  big: 60,
};

const widths = {
  small: 'auto',
  default: 220,
  medium: 'auto',
  big: 'auto',
};

export const Root = styled.span<Props>`
  width: ${({ size }) => widths[size]}px;
  font-size: ${({ size }) => sizes[size]}px;
`;
