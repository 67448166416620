import { styled } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import {
  compose,
  spacing,
  typography,
  sizing,
  display,
} from '@material-ui/system';

export default styled(Typography)(
  compose(spacing, typography, sizing, display),
);
