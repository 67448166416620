import i18n from 'i18n';

export default (unit: string): string => {
  switch (unit) {
    case 'percents':
      return '%';
    case 'degrees_celcius':
      return '°C';
    case 'days':
      return i18n.t('units.d');
    case 'hours':
      return i18n.t('units.h');
    case 'minutes':
      return i18n.t('units.min');
    case 'seconds':
      return i18n.t('units.sec');
    case 'revolutions_per_minute':
      return 'rpm';
    case null:
      return '';
    default:
      return '%';
  }
};
