import React from 'react';

import Page from 'components/Page';
import { CustomRouteComponentProps } from 'constants/routes';

const Schedule: React.FC<CustomRouteComponentProps> = (props) => (
  <Page title={props.title}>
    <div>{props.title}</div>
  </Page>
);

export default Schedule;
