import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

type Props = {
  value: string | null;
  onChangeFilter: any;
  onClickAll: () => void;
  onClickActive: () => void;
  onClickDisabled: () => void;
};

const FilterSwitch: React.FC<Props> = ({
  value,
  onChangeFilter,
  onClickAll,
  onClickActive,
  onClickDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <ToggleButtonGroup
      size="small"
      exclusive
      value={value}
      onChange={onChangeFilter}
    >
      <ToggleButton disabled={value === 'all'} value="all" onClick={onClickAll}>
        {t('deviceFilter.all')}
      </ToggleButton>
      <ToggleButton
        disabled={value === 'active'}
        value="active"
        onClick={onClickActive}
      >
        {t('deviceFilter.active')}
      </ToggleButton>
      <ToggleButton
        disabled={value === 'disabled'}
        value="disabled"
        onClick={onClickDisabled}
      >
        {t('deviceFilter.disabled')}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default memo(FilterSwitch);
