import React, { useContext, useEffect } from 'react';

import * as Styled from './CombinatedTemperature.styled';

import { useTranslation } from 'react-i18next';
import ParameterContext from '../../../../context/ParameterContext';
import Value from '../../../Commons/Value/Value';
import BaseParameter from '../BaseParameter';
import useMemoSelector from 'hooks/useMemoSelector';
import { parametersByNameSelector } from '@redux/parameters/selectors';
import { camelCase } from 'lodash';

type Props = {
  unit: string;
  valueKey: string;
  icon?: string;
  parameters: any;
  modalType: string;
  editKey: string;
  editable: boolean;
  specifiedTemperatureKeys?: any;
};

const CombinedTemperatureNew: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [first, second] = Object.values(props.parameters);
  const { onSetParameter } = useContext(ParameterContext);

  useEffect(() => {
    onSetParameter(props.editKey);
  }, [first]);

  const params = useMemoSelector(parametersByNameSelector, ['Devices_model']);

  const specifiedTempKey =
    params['devicesModel']?.value === 'bmore_auto'
      ? props.specifiedTemperatureKeys?.bmoreAuto
      : props.specifiedTemperatureKeys?.ecomax;

  const specifiedTempParam = useMemoSelector(parametersByNameSelector, [
    specifiedTempKey,
  ]);

  return (
    <BaseParameter {...props} editable={props.editable} editKey={first.key}>
      <Styled.Root>
        <div>
          <div>
            <Styled.Title>{t('setTemp')}</Styled.Title>
            <Value
              type="number"
              size="small"
              value={
                specifiedTempParam[camelCase(specifiedTempKey)]?.value || '---'
              }
              defaultValue={t('noData')}
              sup="°C"
            />
            <Styled.Separator />
          </div>
          <div>
            <Styled.Title>{t('currTemp')}</Styled.Title>
            <Value
              type="number"
              size="medium"
              value={second.value}
              defaultValue={t('noData')}
              sup="°C"
            />
          </div>
        </div>
      </Styled.Root>
    </BaseParameter>
  );
};

export default CombinedTemperatureNew;
