import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import _last from 'lodash/last';

import Progress from 'components/Interfaces/Progress';
import { isCustomerSelector } from '@redux/user/selectors';
import useDevicesList from 'hooks/commons/useDevicesList';

const DeviceContainer: React.FC = () => {
  const dispatch = useDispatch();
  const [isPending, devices] = useDevicesList();
  const isCustomer = useSelector(isCustomerSelector);

  useEffect(() => {
    if (devices.length > 0) {
      if (isCustomer) {
        const activeDevices = devices.filter(
          ({ controllerStatus, moduleStatus }) =>
            controllerStatus === 'connected' || moduleStatus === 'connected',
        );

        if (activeDevices.length > 0) {
          dispatch(push(`/device/${_last(activeDevices).uid}`));
        } else {
          dispatch(push(`/device/${_last(devices).uid}`));
        }
      } else {
        dispatch(push(`/devices`));
      }
    }
  }, [devices, isCustomer]);

  return <Progress visible={isPending} />;
};

export default DeviceContainer;
