import { createSlice } from '@reduxjs/toolkit';
import _remove from 'lodash/remove';

import { LoadersType } from './types';
import { LoadingActions } from './actions';

const initialState: LoadersType = {
  loaders: [],
  isLoading: false,
};

const loaders = createSlice({
  name: 'loaders',
  initialState,
  reducers: {},
  extraReducers: {
    [LoadingActions.ADD]: (state, { payload }): void => {
      state.loaders.push(payload);
    },
    [LoadingActions.REMOVE]: (state, { payload }): void => {
      // state.loaders = state.loaders.filter((loader) => loader !== payload);
      _remove(state.loaders, { payload });
    },
    [LoadingActions.SET]: (state, { payload }): void => {
      state.isLoading = payload;
    },
  },
});

const { reducer } = loaders;

export default reducer;
