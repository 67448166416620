import styled from 'styled-components/macro';

export const Root = styled.div`
  min-width: 572px;
`;

export const Body = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  padding-bottom: 30px;
`;

export const Title = styled.div`
  width: 100%;
  font-size: 21px;
  font-weight: 500;
  line-height: 1.25;
  text-align: justify;
`;

export const Progress = styled.div`
  padding-bottom: 70px;

  && {
    .MuiCircularProgress-colorPrimary {
      color: #fce55a;
    }
  }
`;

export const Codes = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
`;

export const Code = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CodeTitle = styled.div`
  font-size: 16px;
`;

export const CodeQR = styled.div`
  margin-top: 10px;
`;

export const CodeQRImage = styled.img`
  height: 230px;
  max-width: 100%;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 40px;
  width: 100%;

  button {
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }
`;
