import React from 'react';
import { camelCase } from 'lodash';
import { useTranslation } from 'react-i18next';

import * as Styled from './ParamButton.styled';

type Props = {
	paramName: string;
	selected: boolean | undefined;
	selectedColor: string;
	onChange: (param: string, value: boolean) => void;
};

const ParamButton: React.FC<Props> = ({ paramName, selected, selectedColor, onChange }) => {
	const { t } = useTranslation();

	const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		onChange(camelCase(paramName), e.target.checked);
	};

	return (
		<Styled.Root>
			<Styled.Input
				type="checkbox"
				id={paramName}
				name={paramName}
				checked={selected}
				color={selectedColor}
				onChange={handleCheckBoxChange}
			/>
			<Styled.Label htmlFor={paramName}>{t(`parameters.${camelCase(paramName)}`)}</Styled.Label>
		</Styled.Root>
	);
};

export default ParamButton;
