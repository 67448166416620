import { createSlice } from '@reduxjs/toolkit';
import merge from 'lodash/merge';

import {
  FetchParametersAction,
  FetchParametersWithoutReloadAction,
  UpdateParametersAction,
} from './actions';
import { ParametersState } from './types';
import { UpdateDeviceParameterActions } from '../devices/actions';
import { FetchDeviceSettingsActions } from '@redux/deviceSettings/actions';

const initialState: ParametersState = {
  items: {},
  isPending: false,
};

const factories = createSlice({
  name: 'parameters',
  initialState,
  reducers: {},
  extraReducers: {
    [FetchParametersAction.REQUESTED]: (state): void => {
      state.isPending = true;
    },
    [FetchParametersAction.FULFILLED]: (state, action): void => {
      state.isPending = false;
      Object.assign(state.items, action.payload);
    },
    [FetchParametersWithoutReloadAction.FULFILLED]: (state, action): void => {
      Object.assign(state.items, action.payload);
    },
    [FetchParametersAction.REJECTED]: (state): void => {
      state.isPending = false;
    },
    [UpdateParametersAction.FULFILLED]: (state, action): void => {
      state.items = merge(state.items, action.payload);
    },
    [FetchDeviceSettingsActions.REQUESTED]: (state): void => {
      state.items = {};
    },
    [UpdateDeviceParameterActions.FULFILLED]: (state, action): void => {
      state.items = merge(state.items, {
        [action.payload.key]: {
          value: action.payload.value,
        },
      });
    },
  },
});

const { reducer } = factories;

export default reducer;
