import React from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from 'components/PrivateRoute';
import PublicRoute from 'components/PublicRoute';
import NotFound from 'pages/NotFound';

import { CustomRouteProps } from 'constants/routes';

type Props = {
  routes: CustomRouteProps[];
};

const AppRouter: React.FC<Props> = ({ routes }) => {
  const memoRoutes = React.useMemo(
    () =>
      routes.map((route, i) =>
        route.private ? (
          <PrivateRoute key={i} {...route} />
        ) : (
          <PublicRoute key={i} {...route} />
        ),
      ),
    [routes],
  );

  return (
    <Switch>
      {memoRoutes}
      <NotFound />
    </Switch>
  );
};

export default React.memo(AppRouter);
