export enum SignInActions {
  REQUESTED = '@application/SIGN_IN_REQUESTED',
  FULFILLED = '@application/SIGN_IN_FULFILLED',
  REJECTED = '@application/SIGN_IN_REJECTED',
}

export enum SignUpActions {
  REQUESTED = '@application/SIGN_UP_REQUESTED',
  FULFILLED = '@application/SIGN_UP_FULFILLED',
  REJECTED = '@application/SIGN_UP_REJECTED',
}

export enum SignOutActions {
  REQUESTED = '@application/SIGN_OUT_REQUESTED',
  FULFILLED = '@application/SIGN_OUT_FULFILLED',
  REJECTED = '@application/SIGN_OUT_REJECTED',
}
