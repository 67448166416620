import styled from 'styled-components/macro';

export const Root = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  background: #fff;
`;

export const Body = styled.div`
  padding-top: 18px;
  margin: 62px 84px 50px 84px;
`;

export const Close = styled.div`
  position: absolute;
  top: 30px;
  right: 27px;
  cursor: pointer;
  user-select: none;

  &:hover {
    &::before {
      background: #ffe632;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    transform: translate3d(-50%, -50%, 0);
    background: transparent;
    z-index: -1;
  }
`;
