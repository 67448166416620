import styled from 'styled-components/macro';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Title = styled.span`
  font-size: 12px;
  color: #6b6b6b;
  font-weight: 300;
  padding-right: 24px;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 12px;
  margin-bottom: 20px;
  background: #e5e5e5;
`;
