import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

import DeviceSettings from './DeviceSettings';

import Page from 'components/Page';
import Progress from 'components/Interfaces/Progress';
import SnackBar from 'components/SnackBar';
import ConfirmDeviceDeleteModal from 'components/Modal/ConfirmDeviceDeleteModal';

import deviceSettingsAPI from 'api/deviceSettings';
import { UpdateDeviceSettingsRequest } from 'api/deviceSettings/types';

import submissionError from 'utils/submissionError';

import {
  FetchControllerVersionsActions,
  FetchDeviceSettingsActions,
  FetchModuleVersionsActions,
  UpdateDeviceSettingsActions,
} from '@redux/deviceSettings/actions';
import { ModalActions } from '@redux/modal/actions';

import { DeleteDeviceActions } from '@redux/devices/actions';

import {
  deviceSettingsSelector,
  areSettingsPendingSelector,
} from '@redux/deviceSettings/selectors';
import { isModalOpenSelector } from '@redux/modal/selectors';

import { formErrors } from 'constants/errors';
import { CustomRouteComponentProps } from 'constants/routes';
import useMemoSelector from '../../hooks/useMemoSelector';
import { parametersByNameSelector } from '../../@redux/parameters/selectors';
import { FetchParametersAction } from '../../@redux/parameters/actions';

const DeviceSettingsContainer: React.FC<CustomRouteComponentProps> = (
  props: CustomRouteComponentProps,
) => {
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [isSnackBarOpen, setSnackBarOpen] = useState(false);
  const dispatch = useDispatch();

  const data = useSelector(deviceSettingsSelector);
  const isPending = useSelector(areSettingsPendingSelector);
  const isModalOpen = useSelector(isModalOpenSelector);
  const { id } = useParams();
  const { t } = useTranslation();

  const { dsWifiSignalQuality } = useMemoSelector(parametersByNameSelector, [
    'DS_WIFI_Signal_quality',
  ]);

  useEffect(() => {
    dispatch({ type: FetchParametersAction.REQUESTED, payload: { id } });
    dispatch({ type: FetchDeviceSettingsActions.REQUESTED, payload: { id } });
    dispatch({ type: FetchModuleVersionsActions.REQUESTED, payload: { id } });
    dispatch({
      type: FetchControllerVersionsActions.REQUESTED,
      payload: { id },
    });
  }, [dispatch, id]);

  const handleSubmit = useCallback(
    (
      values: UpdateDeviceSettingsRequest,
      actions: FormikHelpers<UpdateDeviceSettingsRequest>,
    ) => {
      return deviceSettingsAPI
        .updateDeviceSettings(values, id)
        .then(({ data }) => {
          dispatch({
            type: UpdateDeviceSettingsActions.FULFILLED,
            payload: data,
          });

          dispatch({
            type: FetchDeviceSettingsActions.REQUESTED,
            payload: { id },
          });

          setSnackBarMessage(t('deviceSettingsUpdated'));
          setSnackBarOpen(true);
        })
        .catch((error) => {
          dispatch({ type: UpdateDeviceSettingsActions.REJECTED });
          setSnackBarMessage(formErrors.somethingWentWrong);
          setSnackBarOpen(true);
          actions.setErrors(submissionError(error.response));
        });
    },
    [dispatch, id, t],
  );

  const handleDeviceDelete = useCallback(() => {
    dispatch({
      type: DeleteDeviceActions.REQUESTED,
      payload: { id },
    });
  }, [dispatch, id]);

  const handleDeleteClick = useCallback(() => {
    dispatch({ type: ModalActions.OPEN });
  }, [dispatch]);

  const handleModalClose = useCallback(() => {
    dispatch({ type: ModalActions.CLOSE });
  }, [dispatch]);

  const handleSnackBarClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ): void => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarOpen(false);
  };

  if (!data) {
    return <Progress visible={true} />;
  }

  return (
    <Page title={props.title}>
      <Progress visible={isPending}>
        <DeviceSettings
          wifiSignalValue={dsWifiSignalQuality ? dsWifiSignalQuality.value : 0}
          data={data}
          deviceId={id}
          isPending={isPending}
          onSubmit={handleSubmit}
          onDeleteClick={handleDeleteClick}
        />
        <ConfirmDeviceDeleteModal
          open={isModalOpen}
          handleClose={handleModalClose}
          onDelete={handleDeviceDelete}
        />
        <SnackBar
          isOpen={isSnackBarOpen}
          message={snackBarMessage}
          onClose={handleSnackBarClose}
        />
      </Progress>
    </Page>
  );
};

export default DeviceSettingsContainer;
