import React, { useCallback, useState } from 'react';

import Modal from './Modal';
import ModalContext from 'context/ModalContext';

const ModalContainer: React.FC = ({ children }) => {
  const [modalType, setModalType] = useState<string | null>(null);
  const [modalData, setModalData] = useState<any>(null);

  const handleOpenModal = useCallback(
    (type, data) => {
      setModalType(type);
      setModalData(data);
    },
    [setModalType, setModalData],
  );

  const handleCloseModal = useCallback(() => {
    setModalType(null);
    setModalData(null);
  }, [setModalType, setModalData]);

  return (
    <ModalContext.Provider
      value={{
        openModal: handleOpenModal,
        closeModal: handleCloseModal,
      }}
    >
      {children}
      {modalType && <Modal type={modalType} data={modalData} />}
    </ModalContext.Provider>
  );
};

export default ModalContainer;
