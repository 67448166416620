export type UserMenuButtonProps = {
  title: string;
  to: string;
};

export type LanguagesTypes = {
  title: string;
  key: string;
};

export const userMenuButtons: UserMenuButtonProps[] = [
  {
    title: 'myAccount',
    to: '/account',
  },
  {
    title: 'privacyPolicy',
    to: '/privacy-policy',
  },
];

export const languages: LanguagesTypes[] = [
  {
    title: 'english',
    key: 'en',
  },
  {
    title: 'russian',
    key: 'ru',
  },
];
