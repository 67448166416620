import React from 'react';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';

import useMemoSelector from 'hooks/useMemoSelector';
import {
  parametersByNameSelector,
  parametersSelector,
} from '@redux/parameters/selectors';

import * as Styled from './ServiceSettings.styled';
import List from '../../components/Parameters/List';

const ServiceSettings: React.FC = () => {
  const { serviceSettings: settings } = useSelector(parametersSelector);

  const {
    cdDhwTemperature,
    cdMixer1Temperature,
    cdMixer2Temperature,
    devicesModel,
  } = useMemoSelector(parametersByNameSelector, [
    'CD_DHW_temperature',
    'CD_Mixer_1_temperature',
    'CD_Mixer_2_temperature',
    'Devices_model',
  ]);

  const conditionalSettings: any[] = {
    ...settings,
    dhwSettings: cdDhwTemperature.value ? settings.dhwSettings : null,
    mixer1Settings: cdMixer1Temperature.value ? settings.mixer1Settings : null,
    mixer2Settings: cdMixer2Temperature.value ? settings.mixer2Settings : null,
    manualControl:
      devicesModel.value === 'bmore_auto' ? settings.manualControl : null,
  };

  return (
    <Box width="100%" display="flex" justifyContent="center">
      <Styled.Parameters>
        {Object.entries(conditionalSettings).map(
          ([name, items]) =>
            items && (
              <Styled.List key={name}>
                <List title={name} items={items} />
              </Styled.List>
            ),
        )}
      </Styled.Parameters>
    </Box>
  );
};

export default ServiceSettings;
