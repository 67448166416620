import React, { useCallback, useContext } from 'react';
import List from './List';
import ModalContext from '../../../context/ModalContext';

type Props = {
  title: string;
  items: any[];
  inner?: boolean;
};

const ListContainer: React.FC<Props> = ({ title, items, inner }) => {
  const { openModal } = useContext(ModalContext);

  const handleModalOpen = useCallback(
    ({ value, unit, key, valueType }) => {
      if (key === 'Time') {
        return openModal('time', { unit, value, key });
      }
      if (valueType === 'String') {
        return openModal('selectValueModal', { unit, value, key });
      }

      if (valueType === 'Boolean') {
        return openModal('switchValueModal', { unit, value, key });
      }

      if (valueType === 'Float' || valueType === 'Integer') {
        return openModal('numberValueModal', { unit, value, key });
      }

      switch (unit) {
        case 'percents':
        case 'seconds':
        case 'minutes':
        case 'hours':
        case 'days':
        case 'degrees_celcius':
          return openModal('numberValueModal', { unit, value, key });
        case 'command':
          return openModal('commandValueModal', { unit, value, key });
        default:
          return;
      }
    },
    [openModal],
  );

  const handleWarningModalOpen = useCallback(
    (text) => {
      openModal('warningModal', { text });
    },
    [openModal],
  );

  return (
    <List
      title={title}
      items={items}
      inner={inner}
      onOpenModal={handleModalOpen}
      onOpenWarningModal={handleWarningModalOpen}
    />
  );
};

export default ListContainer;
