import styled from 'styled-components/macro';

export const Device = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Status = styled.span<{ active?: boolean }>`
  width: 10px;
  height: 10px;
  margin-top: 2px;
  margin-right: 10px;
  background: ${({ active }): any => (active ? '#4caf50' : '#f44336')};
  border-radius: 50%;
`;
