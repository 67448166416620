import { AxiosResponse } from 'axios';
import request from 'api/request';

import { FetchAlarmsRequest, FetchAlarmsResponse } from './types';

const fetchAlarms = ({
  id,
  page,
  perPage,
}: FetchAlarmsRequest): Promise<AxiosResponse<FetchAlarmsResponse>> =>
  request.GET<FetchAlarmsResponse>({
    url: `/devices/${id}/alarms`,
    params: {
      page,
      perPage: perPage || 10,
    },
  });

export default {
  fetchAlarms,
};
