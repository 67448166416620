import React from 'react';
import { useSelector } from 'react-redux';

import Page from 'components/Page';
import Header from 'components/Header';
import Account from './Account';

import { CustomRouteComponentProps } from 'constants/routes';
import { userSelector } from '@redux/user/selectors';
import { isDemoAccountSelector } from '@redux/user/selectors';

const AccountContainer: React.FC<CustomRouteComponentProps> = (props) => {
  const user = useSelector(userSelector);
  const isDemo = useSelector(isDemoAccountSelector);

  return (
    <Page title={props.title}>
      <Header logo lang profile back fixed />
      <Account email={user.email || ''} isDemo={isDemo} />
    </Page>
  );
};

export default AccountContainer;
