import { AxiosResponse } from 'axios';
import request from 'api/request';
import { UserUpdateRequest, ChangeEmailRequest } from './types';
import { ResetEmailRequest } from 'api/auth/types';

const userUpdate = (data: UserUpdateRequest): Promise<AxiosResponse<void>> =>
  request.PUT<void>({
    url: '/users',
    data,
  });

const changeEmail = (data: ChangeEmailRequest): Promise<AxiosResponse<void>> =>
  request.POST<void>({
    url: '/users/change_email',
    data,
  });

const resetPassword = (data: ResetEmailRequest): Promise<AxiosResponse<void>> =>
  request.POST<void>({
    url: '/users/reset_password',
    data,
  });

export default {
  userUpdate,
  changeEmail,
  resetPassword,
};
