import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/Interfaces/NewButton';
import getMobileUserOS from 'utils/getMobileUserOS';
import Modal from '../Modal';
import * as Styled from './LowTierDeviceModal.styled';

type Props = {
  open: boolean;
  handleClose: () => void;
};

const LowTierDeviceModal: React.FC<Props> = ({ open, handleClose }) => {
  const { t } = useTranslation();

  const handleApply = (): void => {
    const system = getMobileUserOS();
    switch (system) {
      case 'Android': {
        window.open(
          'https://play.google.com/store/apps/details?id=com.bemore.android_dev',
        );
        break;
      }
      case 'iOS': {
        window.open('https://apps.apple.com/ch/app/bmore/id1543234794');
        break;
      }
      default:
        return;
    }
  };

  return (
    <Modal open={open} handleClose={handleClose}>
      <Styled.Root>
        <Styled.Title>{t(`lowTierDeviceDetected`)}</Styled.Title>
        <Styled.Buttons>
          <Button gray onClick={handleClose}>
            {t('cancel')}
          </Button>
          <Button onClick={handleApply}>{t('continue')}</Button>
        </Styled.Buttons>
      </Styled.Root>
    </Modal>
  );
};

export default LowTierDeviceModal;
