import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

export default (func: (state: any, params: any) => void, params = {}): any => {
  const savedFunc = useRef(func);

  useEffect(() => {
    savedFunc.current = func;
  }, [params, func]);

  return useSelector((state) => savedFunc.current(state, params));
};
