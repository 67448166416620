import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { camelCase } from 'lodash';

import ModalContext from 'context/ModalContext';
import * as Styled from './BoilerOperationMode.styled';

type Props = {
  unit: string;
  value: string;
  valueKey: string;
  editable: boolean;
  disabled: boolean;
  parameters: any;
};

const colorsByValue: any = {
  turned_off: { color: '#ffffff', background: '#363636' },
  supervision: { color: '#ffffff', background: '#999999' },
  stopped: { color: '#ffffff', background: '#999999' },
  halted: { color: '#ffffff', background: '#999999' },
  cleaning: { color: '#ffffff', background: '#999999' },
  manual: { color: '#ffffff', background: '#999999' },
  unsealing: { color: '#ffffff', background: '#999999' },
  other: { color: '#ffffff', background: '#999999' },
  purge: { color: '#ffffff', background: '#999999' },
  flame_extinguished: { color: '#ffffff', background: '#999999' },
  prevetion: { color: '#ffffff', background: '#999999' },
  supervision_grate: { color: '#ffffff', background: '#999999' },
  calibraion: { color: '#ffffff', background: '#999999' },
  maintain: { color: '#ffffff', background: '#999999' },
  chimney: { color: '#ffffff', background: '#999999' },
  open_door: { color: '#ffffff', background: '#999999' },
  cooling: { color: '#ffffff', background: '#999999' },
  fire_up: { color: '#212121', background: '#FDF160' },
  burning_off: { color: '#fff', background: '#402884' },
  check_flame: { color: '#212121', background: '#FDF160' },
  work_grate: { color: '#212121', background: '#FDF160' },
  afterburning: { color: '#212121', background: '#FDF160' },
  kindling: { color: '#212121', background: '#FDF160' },
  stabilization: { color: '#212121', background: '#FDF160' },
  work: { color: '#212121', background: '#FDF160' },
  alarm: { color: '#ffffff', background: '#fa2f2f' },
};

const BoilerOperationModeParameter: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { cdBoilerOperationMode } = props.parameters;
  const { openModal } = useContext(ModalContext);
  const value = cdBoilerOperationMode.value;

  const handleClick = useCallback(() => {
    openModal('boilerValueModal', { value });
  }, [value]);

  const { color, background } = colorsByValue[value] || {
    color: '#ffffff',
    background: '#3D2C7F',
  };

  return (
    <Grid item xs={6} md={4} lg={3} xl={2}>
      <Styled.Root
        editable={!props.disabled && props.editable}
        color={color}
        background={background}
        onClick={handleClick}
      >
        <Styled.Wrapper>
          <Styled.Logo />
          <Styled.Value>
            {t(`stateParameters.${camelCase(cdBoilerOperationMode.value)}`)}
          </Styled.Value>
          <Styled.Title>{t('boilerOperationMode')}</Styled.Title>
        </Styled.Wrapper>
      </Styled.Root>
    </Grid>
  );
};

export default React.memo(BoilerOperationModeParameter);
