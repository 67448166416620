import { AxiosResponse } from 'axios';
import request from 'api/request';

import { FetchHistoryRequest, FetchHistoryResponse } from './types';

const fetchHistory = ({
  id,
  dateFrom,
  dateTo,
}: FetchHistoryRequest): Promise<AxiosResponse<FetchHistoryResponse>> =>
  request.GET<FetchHistoryResponse>({
    url: `/devices/${id}/parameter_snapshots`,
    params: {
      from: dateFrom,
      to: dateTo,
      key_points_count: 400,
    },
  });

export default {
  fetchHistory,
};
