import { all } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import loadingSaga from 'utils/loadingSaga';
import { FetchParametersAction } from '../parameters/actions';

function* loadersSaga(): SagaIterator<void> {
  yield all([loadingSaga(FetchParametersAction)]);
}

export default loadersSaga;
