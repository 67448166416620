import React, { useCallback } from 'react';
import { goBack, push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { last } from 'lodash';

import { devicesSelector } from '@redux/devices/selectors';
import { isAuthUserSelector } from '@redux/user/selectors';
import { SignOutActions } from '@redux/application/actions';

import BackButton from 'components/BackButton';

const DeviceBackButton: React.FC = () => {
  const dispatch = useDispatch();

  const isAuth = useSelector(isAuthUserSelector);
  const devices = useSelector(devicesSelector);

  const handleClick = useCallback(() => {
    if (!isAuth) dispatch(goBack());

    if (devices.length >= 1) {
      dispatch(push(`/device/${last<any>(devices).uid}`));
    } else {
      dispatch({ type: SignOutActions.REQUESTED });
    }
  }, [devices, isAuth]);

  return <BackButton onClick={handleClick} />;
};

export default DeviceBackButton;
