import React, { useState, useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import Page from 'components/Page';
import FormWrapper from 'components/FormWrapper';
import SomethingWentWrongModal from 'components/Modal/SomethingWentWrongModal';
import Header from 'components/Header';
import SignIn from './SignIn';

import { isAuthPending } from '@redux/application/selectors';
import { isModalOpenSelector } from '@redux/modal/selectors';
import { SignInActions } from '@redux/application/actions';
import { ModalActions } from '@redux/modal/actions';

import authAPI from 'api/auth';
import { SignInRequest } from 'api/auth/types';
import submissionError from 'utils/submissionError';

import { CustomRouteComponentProps } from 'constants/routes';
import { formErrors } from 'constants/errors';

const links = [
  {
    to: '//',
    nameKey: '',
  },
  {
    to: '/password-reset',
    nameKey: 'forgotPassword',
  },
  {
    to: '/',
    nameKey: '',
  },
];

const SignInContainer: React.FC<CustomRouteComponentProps> = (
  props: CustomRouteComponentProps,
) => {
  const [error, setError] = useState<string | null>(null);

  const dispatch = useDispatch();
  const isPending = useSelector(isAuthPending);
  const isModalOpen = useSelector(isModalOpenSelector);
  const { t } = useTranslation();

  const handleClick = useCallback(
    (values: SignInRequest, actions: FormikHelpers<SignInRequest>) => {
      authAPI
        .signIn(values)
        .then(({ data }) => {
          dispatch({ type: SignInActions.FULFILLED, payload: data });
          const { devicesUids, roles } = data;

          if (!devicesUids.length && roles.includes('customer')) {
            return dispatch(push('/add-device'));
          }

          if (devicesUids.length === 1) {
            return dispatch(push(`/device`));
          }

          if (roles.includes('customer')) {
            return dispatch(push(`/device`));
          } else {
            dispatch(push('/devices'));
          }
        })
        .catch((error) => {
          dispatch({ type: SignInActions.REJECTED });
          const { type } = error.response.data.error;

          if (type === 'Errors::NotConfirmed') {
            setError(formErrors.emailNotConfirmed);
          } else if (
            type === 'Errors::Unauthenticated' ||
            type === 'Errors::ResourceNotFound'
          ) {
            setError(formErrors.invalidCredentials);
          } else {
            actions.setErrors(submissionError(error.response));
          }
        });
    },
    [dispatch],
  );

  const handleModalClose = useCallback(() => {
    dispatch({ type: ModalActions.CLOSE });
  }, [dispatch]);

  const handleDemoClick = useCallback(() => {
    authAPI.demoSignIn().then(({ data }) => {
      dispatch({ type: SignInActions.FULFILLED, payload: data });
      dispatch(push(`/device/${data.devicesUids[0]}`));
    });
  }, [dispatch]);

  return (
    <Page title={props.title}>
      <Header logo lang fixed>
        <Button
          aria-label="change language button"
          aria-controls="language-menu"
          aria-haspopup="true"
          color="inherit"
          disableRipple={true}
          disableFocusRipple={true}
          onClick={handleDemoClick}
        >
          {t('demoAccount')}
        </Button>
        <Button
          to="/signup"
          component={Link}
          aria-label="change language button"
          aria-controls="language-menu"
          aria-haspopup="true"
          color="inherit"
          disableRipple={true}
          disableFocusRipple={true}
        >
          {t('signUp')}
        </Button>
      </Header>
      <FormWrapper title={t('signIn')} icon="lock" links={links} error={error}>
        <SignIn isPending={isPending} onSubmit={handleClick} />
      </FormWrapper>
      <SomethingWentWrongModal
        open={isModalOpen}
        handleClose={handleModalClose}
      />
    </Page>
  );
};

export default memo(SignInContainer);
