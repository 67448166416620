import { serverErrors } from 'constants/errors';
import { camelCase } from 'lodash';

export interface ErrorsType {
  data: {
    error: {
      details: {
        [key: string]: { key: string; error: string }[];
      };
    };
  };
}

export interface ResultErrors {
  [key: string]: string;
}

export default (error: ErrorsType): ResultErrors => {
  const { details } = error.data.error;

  const section = camelCase(Object.keys(details)[0]);
  const values = Object.values(details)[0];

  const result: ResultErrors = values.reduce((prev, value) => {
    const key = value.key;
    const errorValue = camelCase(value.error);

    const errorTitle = serverErrors[section][errorValue];

    return Object.assign(prev, {
      [key]: errorTitle || `{${section}.${errorValue}}`,
    });
  }, {});

  return result;
};
