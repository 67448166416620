import React from 'react';
import { Grid, Typography, Switch as SwitchComponent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  onChange: (value: string) => void;
};

const useStyles = makeStyles(() => ({
  root: {
    width: 500,
    marginTop: -32,
  },
}));

const Switch: React.FC<Props> = ({ onChange }) => {
  const classes = useStyles();

  const handleChange = React.useCallback(
    (e, value) => {
      onChange(value);
    },
    [onChange],
  );

  return (
    <Typography component="div" className={classes.root}>
      <Grid
        component="label"
        container
        alignItems="center"
        justify="flex-end"
        spacing={1}
      >
        <Grid item>Выкл</Grid>
        <Grid item>
          <SwitchComponent onChange={handleChange} />
        </Grid>
        <Grid item>Вкл</Grid>
      </Grid>
    </Typography>
  );
};

export default React.memo(Switch);
