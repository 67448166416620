import styled from 'styled-components/macro';

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
  padding: 100px 0;
  box-sizing: border-box;
`;

export const NothingFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
`;
