import React from 'react';
import styled from 'styled-components/macro';
import { spacing, SpacingProps } from '@material-ui/system';

import MaterialLink from '@material-ui/core/Link';
import { Link, LinkProps } from 'react-router-dom';

type Props = {
  selected?: boolean;
  children?: any;
} & SpacingProps &
  LinkProps;

type CustomLinkProps = {
  className: string;
};

const StyledRouterLink = styled(Link)<Props>`
  && {
    ${spacing}
    ${({ selected }): any => selected && `color: green`}
  }
`;

const CustomLink: React.FC<Props> = (props: Props) => {
  const CustomLinkJSX: React.ForwardRefRenderFunction<
    CustomLinkProps,
    any
  > = React.useCallback(
    ({ className }: CustomLinkProps, ref: any) => (
      <StyledRouterLink
        ref={ref}
        {...props}
        to={props.to}
        className={className}
        selected={props.selected}
      >
        {props.children}
      </StyledRouterLink>
    ),
    [props],
  );

  const CustomLinkWithForward = React.useMemo(
    () => React.forwardRef(CustomLinkJSX),
    [CustomLinkJSX],
  );

  return (
    <MaterialLink component={CustomLinkWithForward}>
      {props.children}
    </MaterialLink>
  );
};

export default CustomLink;
