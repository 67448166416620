import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AppRouter from 'components/AppRouter/AppRouter';
import Progress from 'components/Interfaces/Progress';
import LowTierDeviceDetector from 'components/LowTierDeviceDetector';

import { CurrentUserActions } from '@redux/user/actions';
import { isUserPendingSelector } from '@redux/user/selectors';
import { CustomRouteProps } from 'constants/routes';
import useSession from '../../hooks/commons/useSession';

type Props = {
  routes: CustomRouteProps[];
};

const App: React.FC<Props> = ({ routes }) => {
  const dispatch = useDispatch();
  const isUserPending = useSelector(isUserPendingSelector);

  useEffect(() => {
    dispatch({ type: CurrentUserActions.REQUESTED });
  }, [dispatch]);

  useSession();

  return (
    <Progress visible={isUserPending}>
      <LowTierDeviceDetector minWidth={310} maxWidth={720}>
        <AppRouter routes={routes} />
      </LowTierDeviceDetector>
    </Progress>
  );
};

export default React.memo(App);
