import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';

import Typography from 'components/Interfaces/Typography';
import Button from 'components/Interfaces/Button';

import DeleteIcon from '@material-ui/icons/Delete';

import useStyles from './styles';

type Props = {
  open: boolean;
  handleClose: () => void;
};

const DeviceDeletedModal: React.FC<Props> = ({ open, handleClose }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <Modal open={open} handleClose={handleClose}>
      <div className={classes.wrapper}>
        <DeleteIcon className={classes.deleteIcon} />
        <Typography mt="30px">{t('deviceHasBeenDeleted')}</Typography>
        <Button
          mt="48px"
          color="primary"
          variant="contained"
          onClick={handleClose}
        >
          {t('continue')}
        </Button>
      </div>
    </Modal>
  );
};

export default memo(DeviceDeletedModal);
