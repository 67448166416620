import development from './development';
import production from './production';
import staging from './staging';

const configs = {
  development,
  production,
  staging,
};

export default () => configs[process.env.NODE_ENV];
