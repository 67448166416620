import { all, call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import deviceSettingsApi from 'api/deviceSettings';
import {
  FetchDeviceSettingsActions,
  FetchModuleVersionsActions,
  FetchControllerVersionsActions,
} from './actions';

import { FetchDeviceSettingsActionType } from './interfaces';

function* fetchDeviceSettingsSaga({
  payload,
}: FetchDeviceSettingsActionType): SagaIterator<void> {
  try {
    const { data } = yield call(
      deviceSettingsApi.fetchDeviceSettings,
      payload.id,
    );

    yield put({
      type: FetchDeviceSettingsActions.FULFILLED,
      payload: data,
    });
  } catch (e) {
    yield put({ type: FetchDeviceSettingsActions.REJECTED });
  }
}

function* fetchModuleVersionsSaga({
  payload,
}: FetchDeviceSettingsActionType): SagaIterator<void> {
  try {
    const { data } = yield call(
      deviceSettingsApi.fetchModuleVersions,
      payload.id,
    );

    yield put({
      type: FetchModuleVersionsActions.FULFILLED,
      payload: data.softwares,
    });
  } catch (e) {
    yield put({ type: FetchModuleVersionsActions.REJECTED });
  }
}

function* fetchControllerVersionsSaga({
  payload,
}: FetchDeviceSettingsActionType): SagaIterator<void> {
  try {
    const { data } = yield call(
      deviceSettingsApi.fetchControllerVersions,
      payload.id,
    );

    yield put({
      type: FetchControllerVersionsActions.FULFILLED,
      payload: data.softwares,
    });
  } catch (e) {
    yield put({ type: FetchControllerVersionsActions.REJECTED });
  }
}

function* deviceSettingsSaga(): SagaIterator<void> {
  yield all([
    takeLatest(FetchDeviceSettingsActions.REQUESTED, fetchDeviceSettingsSaga),
    takeLatest(FetchModuleVersionsActions.REQUESTED, fetchModuleVersionsSaga),
    takeLatest(
      FetchControllerVersionsActions.REQUESTED,
      fetchControllerVersionsSaga,
    ),
  ]);
}

export default deviceSettingsSaga;
