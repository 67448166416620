import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { camelCase, get } from 'lodash';
import { IconButton } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';

import useCondition from 'hooks/commons/useCondition';
import useEnabledCondition from 'hooks/commons/useEnabledCondition';

import getValueFromParameter from 'utils/getValueFromParameter';
import * as Styled from './Item.styled';
import { useSelector } from 'react-redux';
import {
  isConnectedControllerSelector,
  isConnectedModuleSelector,
  serviceLevelSelector,
} from '../../../../@redux/deviceSettings/selectors';
import { isCustomerSelector } from '../../../../@redux/user/selectors';
import { format } from 'date-fns';
import { convertDateTime } from 'components/NewModal/TimeModal/formatTime';

type Props = {
  item: any;
  onOpenModal: (item: any) => void;
  onOpenWarningModal: (text: string) => void;
};

const Item: React.FC<Props> = ({ item, onOpenModal, onOpenWarningModal }) => {
  const { t } = useTranslation();
  const conditionHidden = useCondition(get(item, 'conditions.hidden'));
  const conditionDisabled = useCondition(get(item, 'conditions.disabled'));
  const conditionEnabled = useEnabledCondition(
    get(item, 'conditions.enabled'),
    true,
  );

  const serviceLevel = useSelector(serviceLevelSelector);
  const isCustomer = useSelector(isCustomerSelector);
  const isConnectedController = useSelector(isConnectedControllerSelector);
  const isConnectedModule = useSelector(isConnectedModuleSelector);

  const title = t(`parameters.${camelCase(item.key)}`);
  const disabled = conditionDisabled || !item.editable || !conditionEnabled;
  const fullDisabled =
    (!isCustomer && serviceLevel !== 'edit') ||
    !isConnectedController ||
    !isConnectedModule;

  const value =
    item.key === 'Time'
      ? convertDateTime(item.value)
      : getValueFromParameter(item.value, item.unit, item.valueType);

  const handleClick = useCallback(() => {
    onOpenModal(item);
  }, [item, onOpenModal]);

  const handleHiddenButtonClick = useCallback(() => {
    onOpenWarningModal(item.modal.disabled.text);
  }, [item, onOpenModal]);

  if (conditionHidden) {
    return null;
  }

  return (
    <Styled.Root>
      <Styled.Title>{title}</Styled.Title>
      <Styled.Value>{value}</Styled.Value>
      <div>
        {!fullDisabled && disabled && item.modal && item.modal.disabled && (
          <Styled.HiddenButton onClick={handleHiddenButtonClick} />
        )}
        <IconButton
          disabled={fullDisabled || disabled}
          aria-label="delete"
          onClick={handleClick}
        >
          <CreateIcon />
        </IconButton>
      </div>
    </Styled.Root>
  );
};

export default Item;
