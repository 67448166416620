import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { Box, Button, Divider, Menu, MenuItem } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import * as Styled from './DeviceMenuButton.styled';
import { useTranslation } from 'react-i18next';
import UserRole from '../../UserRole';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import { isDemoAccountSelector } from '@redux/user/selectors';

type Props = {
  current?: any;
  devices: any[];
};

const DevicesMenuButton: React.FC<Props> = ({ current, devices }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isDemo = useSelector(isDemoAccountSelector);

  const handleMenuClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback((uid) => {
    dispatch(push(`/device/${uid}`));
    setAnchorEl(null);
  }, []);

  const handleAddDevice = useCallback(() => {
    dispatch(push(`/add-device`));
    setAnchorEl(null);
  }, []);

  return (
    <Box mr={2}>
      <UserRole role="customer">
        {current && (
          <Button
            aria-label="change language button"
            aria-controls="language-menu"
            aria-haspopup="true"
            color="inherit"
            disableRipple={true}
            disableFocusRipple={true}
            onClick={handleMenuClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {current.title}
          </Button>
        )}
        <Menu
          id="language-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {devices.map((device) => (
            <MenuItem
              key={device.uid}
              onClick={(): void => handleClick(device.uid)}
            >
              <Styled.Device>
                <Styled.Status
                  active={device.controllerStatus === 'connected'}
                />
                <span>{device.title}</span>
              </Styled.Device>
            </MenuItem>
          ))}
          <Divider />

          {!isDemo && (
            <MenuItem key="addDevice" onClick={handleAddDevice}>
              {t('addDevice')}
            </MenuItem>
          )}
        </Menu>
      </UserRole>
      <UserRole role="service_engineer">
        <Button
          component={Link}
          to="/devices"
          startIcon={<ArrowBackIcon />}
          color="inherit"
          disableRipple={true}
          disableFocusRipple={true}
        >
          {t('devices')}
        </Button>
      </UserRole>
    </Box>
  );
};

export default React.memo(DevicesMenuButton);
