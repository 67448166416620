import { camelCase } from 'lodash';
import i18n from 'i18n';

const getSelectOptions = (data: string[]): any[] => {
  return data.map((item: string) => ({
    id: item,
    title: item.includes('%')
      ? item
      : i18n.t(`stateParameters.${camelCase(item)}`),
  }));
};

export default getSelectOptions;
