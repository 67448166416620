import React, { useState } from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { FormikHelpers } from 'formik';

import userAPI from 'api/user';

import { UserUpdateRequest } from 'api/user/types';
import ChangePassword from './ChangePassword';
import submissionError from 'utils/submissionError';
import Page from 'components/Page';
import { CustomRouteComponentProps } from 'constants/routes';
import { formErrors } from 'constants/errors';
import Header from '../../components/Header';

const ChangePasswordContainer: React.FC<CustomRouteComponentProps> = (
  props,
) => {
  const [isSent, setSending] = React.useState(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();

  const handleSubmit = React.useCallback(
    (values: UserUpdateRequest, actions: FormikHelpers<UserUpdateRequest>) => {
      return userAPI
        .userUpdate(values)
        .then(() => {
          setSending(true);
        })
        .catch((error) => {
          const { type } = error.response.data.error;
          if (type === 'Errors::InvalidPassword') {
            setError(formErrors.invalidCurrentPassword);
          }
          actions.setErrors(submissionError(error.response));
        });
    },
    [],
  );

  const handleGoToDevices = React.useCallback(() => {
    dispatch(push('/devices'));
  }, [dispatch]);

  return (
    <Page title={props.title}>
      <Header logo lang back fixed />
      <ChangePassword
        isSent={isSent}
        error={error}
        onSubmit={handleSubmit}
        onContinue={handleGoToDevices}
      />
    </Page>
  );
};

export default ChangePasswordContainer;
