import React, { useCallback, useContext, useMemo } from 'react';
import { camelCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import icons from './icons';

import ModalContext from 'context/ModalContext';
import * as Styled from './BaseParameter.styled';
import { Grid } from '@material-ui/core';

type Props = {
  unit: string;
  icon?: string;
  editKey: string;
  valueKey: string;
  children: any;
  editable?: boolean;
  disabled?: boolean;
  modalType?: string;
  modal?: any;
};

const BaseParameter: React.FC<Props> = ({
  modalType,
  unit,
  icon,
  modal,
  editable,
  disabled,
  editKey,
  valueKey,
  children,
}) => {
  const { openModal } = useContext(ModalContext);
  const { t } = useTranslation();

  const Icon = useMemo(() => (icon ? icons[icon] : icons['temp']), [
    unit,
    icon,
  ]);

  const handleClick = useCallback(() => {
    if (!disabled && !editable) {
      if (modal && modal.disabled) {
        return openModal('warningModal', { text: modal.disabled.text });
      }
    }

    modalType && openModal(modalType, { unit, key: editKey });
  }, [modal, editable, disabled, modalType, editKey]);

  return (
    <Grid item xs={6} md={4} lg={3} xl={2}>
      <Styled.Root editable={!disabled && editable} onClick={handleClick}>
        {!disabled && !editable && modal && modal.disabled && (
          <Styled.HiddenButton />
        )}
        {Icon && (
          <Styled.Icon>
            <Icon />
          </Styled.Icon>
        )}
        <Styled.Body>{children}</Styled.Body>
        <Styled.Title>{t(`parameters.${camelCase(valueKey)}`)}</Styled.Title>
      </Styled.Root>
    </Grid>
  );
};

export default React.memo(BaseParameter);
