import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    wrapper: {
      textAlign: 'center',
    },
    errorIcon: {
      width: '60px',
      height: '60px',
      color: theme.palette.error.main,
    },
  }),
);
