import React from 'react';

import Parameter from 'components/Parameters/Cards';

import { Box, Grid, Typography } from '@material-ui/core';

import * as Styled from './CurrentData.styled';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { deviceSettingsSelector } from '@redux/deviceSettings/selectors';

type Props = {
  items: any[];
  isActiveDevice: boolean;
};

const CurrentData: React.FC<Props> = ({ items, isActiveDevice }) => {
  const { t } = useTranslation();
  const deviceData = useSelector(deviceSettingsSelector);

  const isAdvancedUser = deviceData?.deviceSettings.customerRoles.includes(
    'customer_service',
  );

  if (!isActiveDevice) {
    return (
      <Styled.EmptyContent>
        <Typography variant="h4">{t('controllerDisabled')}</Typography>
        <Styled.EmptyDesctiption>
          {t('checkConnection')}
        </Styled.EmptyDesctiption>
      </Styled.EmptyContent>
    );
  }

  return (
    <Box width="100%">
      <Styled.Grid>
        <Grid container spacing={3}>
          {items.map((item) => (
            <Parameter
              isAdvancedUser={isAdvancedUser}
              key={item.key}
              valueKey={item.key}
              {...item}
            />
          ))}
        </Grid>
      </Styled.Grid>
    </Box>
  );
};

export default CurrentData;
