export enum FetchHistoryActions {
  REQUESTED = '@history/FETCH_HISTORY_REQUESTED',
  FULFILLED = '@history/FETCH_HISTORY_FULFILLED',
  REJECTED = '@history/FETCH_HISTORY_REJECTED',
}

export enum UpdateHistoryParamsActions {
  UPDATE_FROM_DATE = '@history/UPDATE_FROM_DATE',
  UPDATE_TO_DATE = '@history/UPDATE_TO_DATE',
  UPDATE_SELECTED_PARAMS = '@history/UPDATE_SELECTED_PARAMS',
}

export enum ExportActions {
  EXPORT_CSV = '@history/EXPORT_CSV',
}
