import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { push } from 'connected-react-router';
import { last } from 'lodash';

import devicesApi from 'api/devices';
import {
  FetchDevicesActions,
  DeleteDeviceActions,
  UpdateDeviceParameterActions,
} from './actions';

import {
  FetchDevicesActionType,
  DeleteDeviceActionType,
  UpdateDeviceParameterActionType,
} from './interfaces';
import { ModalActions } from '@redux/modal/actions';
import { devicesSelector } from './selectors';

function* fetchDevicesSaga({
  payload,
}: FetchDevicesActionType): SagaIterator<void> {
  try {
    const { data } = yield call(devicesApi.fetchDevices, payload);

    yield put({
      type: FetchDevicesActions.FULFILLED,
      payload: data,
    });
  } catch (e) {
    yield put({ type: FetchDevicesActions.REJECTED });
  }
}

function* deleteDeviceSaga({
  payload,
}: DeleteDeviceActionType): SagaIterator<void> {
  try {
    yield call(devicesApi.deleteDevice, payload.id);

    yield put({
      type: DeleteDeviceActions.FULFILLED,
      payload: { uid: payload.id },
    });

    yield put({
      type: ModalActions.CLOSE,
    });

    const devices = yield select(devicesSelector);

    if (devices.length >= 1) {
      yield put(push(`/device/${last<any>(devices).uid}`));
    } else {
      yield put(push('/add-device'));
    }
  } catch (e) {
    yield put({ type: DeleteDeviceActions.REJECTED });
  }
}

function* updateDeviceParameterSaga({
  payload,
}: UpdateDeviceParameterActionType): SagaIterator<void> {
  try {
    yield call(devicesApi.updateDeviceParameter, payload);

    yield put({ type: UpdateDeviceParameterActions.FULFILLED, payload });
  } catch (e) {
    yield put({ type: UpdateDeviceParameterActions.REJECTED });
  }
}

function* devicesSaga(): SagaIterator<void> {
  yield all([
    takeLatest(FetchDevicesActions.REQUESTED, fetchDevicesSaga),
    takeLatest(DeleteDeviceActions.REQUESTED, deleteDeviceSaga),
    takeLatest(
      UpdateDeviceParameterActions.REQUESTED,
      updateDeviceParameterSaga,
    ),
  ]);
}

export default devicesSaga;
