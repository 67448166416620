import { createSlice } from '@reduxjs/toolkit';
import {
  SignInActions,
  SignOutActions,
  SignUpActions,
} from '@redux/application/actions';
import { AddDeviceActions, DeleteDeviceActions } from '@redux/devices/actions';
import {
  CurrentUserActions,
  ConfirmUserActions,
  ResetPasswordActions,
} from '@redux/user/actions';
import { UserType } from './interfaces';

const initialState: UserType = {
  id: null,
  email: null,
  devicesUids: [],
  roles: [],
  authToken: null,
  isPending: true,
  isPasswordResetPending: false,
  demo: false,
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: {
    [SignOutActions.REQUESTED]: (state): void => {
      state.id = null;
      state.email = null;
      state.devicesUids = [];
      state.roles = [];
      state.authToken = null;
      state.isPending = false;
      state.isPasswordResetPending = false;
      state.demo = false;
    },
    [SignInActions.FULFILLED]: (state, { payload }): void => {
      Object.assign(state, payload);
    },
    [SignUpActions.FULFILLED]: (state, { payload }): void => {
      Object.assign(state, payload);
    },
    [CurrentUserActions.REQUESTED]: (state): void => {
      state.isPending = true; // TODO: just return state?
    },
    [CurrentUserActions.FULFILLED]: (state, { payload }): void => {
      state.isPending = false;
      Object.assign(state, payload);
    },
    [CurrentUserActions.REJECTED]: (state): void => {
      state.isPending = false;
    },
    [ConfirmUserActions.REQUESTED]: (state): void => {
      state.isPending = true;
    },
    [ConfirmUserActions.FULFILLED]: (state, { payload }): void => {
      state.isPending = false;
      Object.assign(state, payload);
    },
    [ConfirmUserActions.REJECTED]: (state): void => {
      state.isPending = false;
    },
    [ResetPasswordActions.REQUESTED]: (state): void => {
      state.isPasswordResetPending = true;
    },
    [ResetPasswordActions.FULFILLED]: (state): void => {
      state.isPasswordResetPending = false;
    },
    [ResetPasswordActions.REJECTED]: (state): void => {
      state.isPasswordResetPending = false;
    },
    [AddDeviceActions.FULFILLED]: (state, { payload }): void => {
      state.devicesUids.push(payload.uid);
    },
    [DeleteDeviceActions.FULFILLED]: (state, { payload }): void => {
      state.devicesUids = state.devicesUids.filter(
        (uid) => uid !== payload.uid,
      );
    },
  },
});

const { reducer } = user;

export default reducer;
