import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import DeviceBackButton from 'components/DeviceBackButton';
import LanguageButton from 'components/LanguageButton';
import ProfileButton from 'components/ProfileButton';
import Box from '@material-ui/core/Box';
import DevicesMenuButton from '../TopBar/DevicesMenuButton';
import * as Styled from './Header.styled';
import useStyles from './styles';

type Props = {
  className?: string;
  children?: any;
  title?: string;
  devices?: boolean;
  back?: boolean;
  lang?: boolean;
  profile?: boolean;
  logo?: boolean;
  logoCenter?: boolean;
  fixed?: boolean;
};

const Header: React.FC<Props> = ({
  className,
  children,
  title,
  back,
  lang,
  profile,
  devices,
  logo,
  logoCenter,
  fixed,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Styled.Root className={className} fixed={fixed}>
      <Styled.Wrapper>
        {logo && <Styled.Logo />}
        {title && (
          <Typography variant="h6" noWrap className={classes.title}>
            {t(title)}
          </Typography>
        )}
        {logoCenter && <Styled.LogoCenter />}
        <Box marginLeft="auto" display="flex" alignItems="center">
          {devices && <DevicesMenuButton />}
          {back && (
            <Box marginRight="16px">
              <DeviceBackButton />
            </Box>
          )}
          {lang && (
            <Box>
              <LanguageButton />
            </Box>
          )}
          {profile && (
            <Box>
              <ProfileButton />
            </Box>
          )}
          {children && <Styled.Items>{children}</Styled.Items>}
        </Box>
      </Styled.Wrapper>
    </Styled.Root>
  );
};

export default Header;
