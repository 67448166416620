import React from 'react';
import { Typography, Select as SelectComponent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import getSelectOptions from '../../../../../utils/getSelectOptions';

type Props = {
  value: any;
  enum: string[];
  onChange: (value: string) => void;
};

const useStyles = makeStyles(() => ({
  root: {
    width: 500,
    marginTop: 20,
  },
  input: {
    width: '100%',
  },
}));

const Select: React.FC<Props> = ({ value, enum: options, onChange }) => {
  const classes = useStyles();
  const selectOptions = getSelectOptions(options);

  const handleChange = React.useCallback(
    (e) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  return (
    <Typography component="div" className={classes.root}>
      <InputLabel id="1" />
      <SelectComponent
        className={classes.input}
        label="label1"
        labelId="1"
        variant="outlined"
        value={value}
        onChange={handleChange}
      >
        {selectOptions.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.title}
          </MenuItem>
        ))}
      </SelectComponent>
    </Typography>
  );
};

export default React.memo(Select);
