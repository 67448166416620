import styled, { css } from 'styled-components/macro';

import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';
import { DRAWER_WIDTH } from 'constants/styles';

type Props = {
  isSmall: boolean;
};

export const Root = styled.div<Props>`
  width: ${DRAWER_WIDTH}px;
  height: 100%;
  z-index: 101;
  flex-shrink: 0;
  overflow: hidden;

  ${(props) =>
    props.isSmall &&
    css`
      width: 65px;
    `}
`;

export const Body = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
`;

export const Header = styled.div`
  position: relative;
  width: 100%;
  height: 78px;
  background: #fff;
`;
export const ArrowButton = styled.div<Props>`
  position: absolute;
  top: 34px;
  right: 25px;
  cursor: pointer;

  ${(props) =>
    props.isSmall &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50px;
    height: 60px;
    transform: translate3d(-50%, -50%, 0);
    background: transparent;
  }
`;

export const Logo = styled(LogoIcon)`
  position: absolute;
  left: 85px;
  top: -5px;
  width: 90px;
  height: 90px;
`;
