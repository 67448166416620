import { all, call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { push } from 'connected-react-router';

import authAPI from 'api/auth';
import { SignOutActions } from './actions';

function* SignOutSaga(): SagaIterator<void> {
  try {
    yield call(authAPI.signOut);

    yield put({ type: SignOutActions.FULFILLED });
    yield put(push('/signin'));
  } catch (e) {
    yield put({ type: SignOutActions.REJECTED });
  }
}

function* applicationSaga(): SagaIterator<void> {
  yield all([takeLatest(SignOutActions.REQUESTED, SignOutSaga)]);
}

export default applicationSaga;
