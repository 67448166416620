import React from 'react';
import * as Styled from './Value.styled';

type ValueType = 'float' | 'number';

type Props = {
  value: string | number | null;
  size: 'small' | 'default' | 'medium' | 'big';
  defaultValue?: string;
  type?: ValueType;
  sup?: string;
};

const convertValue = (
  type: ValueType,
  value: string | number | null,
): string | number | null => {
  switch (type) {
    case 'float':
    case 'number':
      return Number(value).toFixed(1);
    default:
      return value;
  }
};

const Value: React.FC<Props> = ({ value, size, defaultValue, type, sup }) => {
  const resultValue = type ? convertValue(type, value) : value;

  return (
    <Styled.Root size={size}>
      <span>{value !== null ? resultValue : defaultValue}</span>
      {value !== null && <span>{sup}</span>}
    </Styled.Root>
  );
};

export default React.memo(Value);
