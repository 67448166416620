import i18n from 'i18n';
import { ConnectionType } from 'constants/addDeviceInstructionTypes';

export default {
  [ConnectionType.WIFI]: [
    i18n.t('instructionWifiSteps.connectToWifi'),
    i18n.t('instructionWifiSteps.goToIp'),
    i18n.t('instructionWifiSteps.copyUID'),
  ],
  [ConnectionType.CABLE]: [
    i18n.t('instructionCableSteps.connectCable'),
    i18n.t('instructionCableSteps.connectToWifi'),
    i18n.t('instructionCableSteps.goToIp'),
    i18n.t('instructionCableSteps.copyUID'),
  ],
};
