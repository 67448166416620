import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import Page from 'components/Page';
import Progress from 'components/Interfaces/Progress';
import CurrentData from './CurrentData';

import { FetchParametersAction } from '@redux/parameters/actions';
import { parametersSelector } from '@redux/parameters/selectors';
import { isPendingParametersSelector } from '@redux/parameters/selectors';
import { isConnectedControllerSelector } from '@redux/deviceSettings/selectors';
import { CustomRouteComponentProps } from 'constants/routes';

const CurrentDataContainer: React.FC<CustomRouteComponentProps> = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { currentData } = useSelector(parametersSelector);
  const isPendingParameters = useSelector(isPendingParametersSelector);
  const isConnectedController = useSelector(isConnectedControllerSelector);
  React.useEffect(() => {
    dispatch({ type: FetchParametersAction.REQUESTED, payload: { id } });
  }, [dispatch, id]);

  return (
    <Page title={props.title}>
      <Progress visible={isPendingParameters}>
        <CurrentData
          items={Object.values(currentData || {})}
          isActiveDevice={isConnectedController}
        />
      </Progress>
    </Page>
  );
};

export default CurrentDataContainer;
