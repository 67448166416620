import React from 'react';
import CombinedTemperature from 'components/Parameters/Cards/CombinedTemperature';
import BaseParameter from '../../BaseParameter/BaseParameter';
import { Grid } from '@material-ui/core';
import Typography from '../../../../Interfaces/Typography/Typography';
import { useTranslation } from 'react-i18next';

type Props = {
  value: string;
  unit: string;
  valueKey: string;
  editable: boolean;
  parameters: any;
};

const RoomOneTemperatureParameter: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const {
    cdRoom1Temperature,
    cdEcoSter1OperationMode,
    cdNightRoom1Temperature,
    cdDayRoom1Temperature,
    ecoSter1AntifreezeTemperatureSetPoint,
    ecoSter1TemperatureSetpointForOperatingModeParty,
    ecoSter1TemperatureSetpointForOperatingModeHoliday,
  } = props.parameters;

  const firstParameter = React.useMemo(() => {
    switch (cdEcoSter1OperationMode.value) {
      case 'economy':
      case 'outside':
      case 'schedule':
        return cdNightRoom1Temperature;
      case 'comfort':
        return cdDayRoom1Temperature;
      case 'frost_protection':
        return ecoSter1AntifreezeTemperatureSetPoint;
      case 'party':
        return ecoSter1TemperatureSetpointForOperatingModeParty;
      case 'holiday':
        return ecoSter1TemperatureSetpointForOperatingModeHoliday;
      default:
        return null;
    }
  }, [
    cdEcoSter1OperationMode,
    cdNightRoom1Temperature,
    cdDayRoom1Temperature,
    ecoSter1AntifreezeTemperatureSetPoint,
    ecoSter1TemperatureSetpointForOperatingModeParty,
    ecoSter1TemperatureSetpointForOperatingModeHoliday,
  ]);

  if (cdEcoSter1OperationMode.value === 'ventilation') {
    return (
      <BaseParameter {...props} editable={false} editKey={props.valueKey}>
        <Grid container justify="center">
          <Typography fontSize="28px" lineHeight="70px">
            {t(`stateParameters.airing`)}
          </Typography>
        </Grid>
      </BaseParameter>
    );
  }

  if (firstParameter === null) {
    return null;
  }

  return (
    <CombinedTemperature
      {...props}
      editable={true}
      modalType="numberValueModal"
      parameters={{ firstParameter, cdRoom1Temperature }}
    />
  );
};

export default RoomOneTemperatureParameter;
