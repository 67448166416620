import * as Yup from 'yup';
import { formErrors } from 'constants/errors';

const { passwordTooShort, passwordCharacters, passwordsNotMatch, requiredField } = formErrors;

export default Yup.object().shape({
	password: Yup.string().required(requiredField).min(6, passwordTooShort).matches(/[A-Za-z0-9_]/, passwordCharacters),
	passwordConfirmation: Yup.string().required(requiredField).oneOf([ Yup.ref('password'), null ], passwordsNotMatch),
	currentPassword: Yup.string().required(requiredField)
});
