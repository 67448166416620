import styled from 'styled-components/macro';

export const Root = styled.div`
  display: flex;
  align-items: center;
  height: 65px;
  padding: 0 30px 0 40px;
  border-bottom: 1px solid #e5e5e5;

  &:last-child {
    border: none;
  }
`;

export const Title = styled.div`
  font-size: 16px;
`;

export const Value = styled.div`
  margin-left: auto;
  font-size: 16px;
  padding-right: 38px;
`;

export const HiddenButton = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  cursor: pointer;
`;
