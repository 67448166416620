import React from 'react';
import styled from 'styled-components';
import { Formik, FormikHelpers } from 'formik';

import { useTranslation } from 'react-i18next';

import { Box } from '@material-ui/core';
import FormWrapper from 'components/FormWrapper';
import Typography from 'components/Interfaces/Typography';
import Paper from 'components/Interfaces/Paper';
import Button from 'components/Interfaces/NewButton';
import InputField from 'components/InputField';

import { UserUpdateRequest } from 'api/user/types';
import validationSchema from './validationSchema';

type Props = {
  isSent: boolean;
  onSubmit: (
    values: UserUpdateRequest,
    actions: FormikHelpers<UserUpdateRequest>,
  ) => void;
  onContinue: () => void;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
`;

const ChangeEmail: React.FC<Props> = ({ isSent, onSubmit, onContinue }) => {
  const { t } = useTranslation();

  if (isSent) {
    return (
      <Wrapper>
        <Paper px={6} py={8} textAlign="center">
          <Typography variant="body1">
            {t('emailChanged.messageSent')}
          </Typography>
          <Typography variant="body1" mb={3}>
            {t('emailChanged.followLink')}
          </Typography>
          <Box display="flex" justifyContent="center">
            <Button color="primary" onClick={onContinue}>
              {t('continue')}
            </Button>
          </Box>
        </Paper>
      </Wrapper>
    );
  }

  return (
    <FormWrapper title={t('changingEmail')} icon="email">
      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(props): React.ReactElement => (
          <form onSubmit={props.handleSubmit}>
            <InputField
              label={t('email')}
              name="email"
              type="email"
              mt="34px"
              mb="12px"
            />
            <Button
              width="400px"
              type="submit"
              color="primary"
              mt="20px"
              disabled={props.isSubmitting}
            >
              {t('changeEmail')}
            </Button>
          </form>
        )}
      </Formik>
    </FormWrapper>
  );
};

export default ChangeEmail;
