import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';

import Typography from 'components/Interfaces/Typography';
import Button from 'components/Interfaces/Button';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import useStyles from './styles';

type Props = {
  open: boolean;
  handleClose: () => void;
};

const SomethingWentWrongModal: React.FC<Props> = ({ open, handleClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Modal open={open} handleClose={handleClose}>
      <div className={classes.wrapper}>
        <ErrorOutlineIcon className={classes.errorIcon} />
        <Typography variant="h5">Oops!</Typography>
        <Typography variant="body2" mt="4px">
          {t('somethingWentWrong')}
        </Typography>
        <Typography mt="44px">
          {t('linkIsNowValid')}
          <p>{t('userDoesntExist')}</p>
        </Typography>
        <Button
          mt="48px"
          color="primary"
          variant="contained"
          onClick={handleClose}
        >
          {t('contactSupport')}
        </Button>
      </div>
    </Modal>
  );
};

export default memo(SomethingWentWrongModal);
