import styled from 'styled-components/macro';

export const Root = styled.div`
  min-width: 572px;
`;

export const Body = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  padding-bottom: 30px;
`;

export const Title = styled.div`
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
`;

export const Progress = styled.div`
  padding-bottom: 70px;

  && {
    .MuiCircularProgress-colorPrimary {
      color: #fce55a;
    }
  }
`;
