import { RouteComponentProps, RouteProps } from 'react-router-dom';

import SignIn from 'pages/SignIn';
import SignUp from 'pages/SignUp';
import CurrentData from 'pages/CurrentData';
import Device from 'pages/Device';
import Devices from 'pages/Devices';
import Dashboard from 'pages/Dashboard';
import Diagrams from 'pages/Diagrams';
import UserSettings from 'pages/UserSettings';
import ServiceSettings from 'pages/ServiceSettings';
import History from 'pages/History';
import Alarms from 'pages/Alarms';
import Schedule from 'pages/Schedule';
import DeviceSettings from 'pages/DeviceSettings';
import ConfirmEmail from 'pages/ConfirmEmail';
import NotConfirmed from 'pages/NotConfirmed';
import AddDevice from 'pages/AddDevice';
import PasswordReset from 'pages/PasswordReset';
import Account from 'pages/Account';
import ChangeEmail from 'pages/ChangeEmail';
import ChangePassword from 'pages/ChangePassword';
import PrivacyPolicy from 'pages/PrivacyPolicy';

export type CustomRouteComponentProps = {
  title: string;
  routes?: CustomRouteProps[];
} & RouteComponentProps;

export type CustomRouteProps = {
  title: string;
  component?: React.ComponentType<CustomRouteComponentProps>;
  routes?: CustomRouteProps[];
  private?: boolean;
  role?: string;
} & RouteProps;

const routes: CustomRouteProps[] = [
  {
    title: 'signIn',
    path: '/signin',
    component: SignIn,
  },
  {
    title: 'signUp',
    path: '/signup',
    component: SignUp,
  },
  {
    title: 'notConfirmed',
    path: '/not-confirmed',
    component: NotConfirmed,
  },
  {
    title: 'confirmEmail',
    path: '/confirm-email',
    component: ConfirmEmail,
  },
  {
    title: 'resetPassword',
    path: '/password-reset',
    component: PasswordReset,
  },
  {
    title: 'privacyPolicy',
    path: '/privacy-policy',
    component: PrivacyPolicy,
  },
  {
    title: 'Add a device',
    path: '/add-device',
    component: AddDevice,
    private: true,
  },
  {
    title: 'devices',
    path: '/devices',
    component: Devices,
    private: true,
    role: 'service_engineer',
  },
  {
    title: 'device',
    path: '/device',
    component: Device,
    private: true,
    exact: true,
  },
  {
    title: 'Account',
    path: '/account',
    component: Account,
    private: true,
  },
  {
    title: 'Change email',
    path: '/change-email',
    component: ChangeEmail,
    private: true,
  },
  {
    title: 'Change password',
    path: '/change-password',
    component: ChangePassword,
    private: true,
  },
  {
    title: 'Device',
    path: ['/device/:id'],
    component: Dashboard,
    private: true,
    strict: true,
    routes: [
      {
        title: 'currentData',
        path: '/',
        component: CurrentData,
        exact: true,
      },
      {
        title: 'diagrams',
        path: '/diagrams',
        component: Diagrams,
      },
      {
        title: 'userSettings',
        path: '/settings/user',
        component: UserSettings,
      },
      {
        title: 'serviceSettings',
        path: '/settings/service',
        component: ServiceSettings,
      },
      {
        title: 'history',
        path: '/history',
        component: History,
      },
      {
        title: 'alarms',
        path: '/alarms',
        component: Alarms,
      },
      {
        title: 'schedule',
        path: '/schedule',
        component: Schedule,
      },
      {
        title: 'deviceSettings',
        path: '/settings/device',
        component: DeviceSettings,
      },
    ],
  },
];

export default routes;
