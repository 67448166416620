import styled from 'styled-components/macro';

export const Root = styled.div`
  min-width: 572px;
`;

export const Body = styled.div`
  display: flex;
  align-items: center;

  .MuiSwitch-colorSecondary.Mui-checked {
    color: #ffe632;
  }

  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #ffe632;
  }
`;
export const Title = styled.div`
  margin-right: auto;
  font-size: 20px;
  font-weight: 600;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 40px;
  width: 100%;

  button {
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }
`;
