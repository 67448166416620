export const getSliderMarks = (
  step: number,
  min: number,
  max: number,
  unit: string,
): { value: number; label: string }[] => {
  return [
    {
      value: min,
      label: `${min} ${unit}`,
    },
    {
      value: max,
      label: `${max} ${unit}`,
    },
  ];
};
