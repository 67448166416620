import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

import InputField from 'components/InputField';
import Button from 'components/Interfaces/NewButton';
import SelectField from 'components/Fields/SelectField';

import { Factory } from '@redux/factories/types';

import { AddDeviceRequest } from 'api/devices/types';
import validationSchema from './validationSchema';

import * as Styled from './AddDevice.styled';

type Props = {
  factories: Factory[];
  isPending: boolean;
  onSubmit: (
    values: AddDeviceRequest,
    actions: FormikHelpers<AddDeviceRequest>,
  ) => void;
};

const AddDevice: React.FC<Props> = ({ factories, isPending, onSubmit }) => {
  const factoryItems = factories.map((item) => ({
    id: item.identifier,
    title: item.title,
  }));

  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{ uid: '', factoryIdentifier: '' }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(props): React.ReactElement => (
        <Styled.Root onSubmit={props.handleSubmit}>
          <InputField
            label={t('deviceUID')}
            name="uid"
            type="text"
            mt="16px"
            mb="12px"
          />
          <SelectField
            name="factoryIdentifier"
            label={t('factoryUID')}
            mt="16px"
            items={factoryItems}
            disabled={isPending}
          />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            mt="20px"
            disabled={props.isSubmitting || isPending}
          >
            {t('addDevice')}
          </Button>
        </Styled.Root>
      )}
    </Formik>
  );
};

export default AddDevice;
