import React from 'react';
import EventIcon from '@material-ui/icons/Event';
import DateTimePicker from 'react-datetime-picker';
import { useTranslation } from 'react-i18next';

import * as Styled from './DatePicker.styled';

type Props = {
  label: string;
  value: Date | string;
  minDate?: Date;
  maxDate?: Date;
  onChange: (value: any) => void;
};

const DatePicker: React.FC<Props> = ({
  label,
  value,
  minDate,
  maxDate,
  onChange,
}) => {
  const locale = useTranslation().i18n.language;

  return (
    <Styled.Root>
      <Styled.Label>{label}</Styled.Label>
      <DateTimePicker
        onChange={onChange}
        value={new Date(value)}
        clearIcon={null}
        disableClock={true}
        calendarIcon={<EventIcon />}
        locale={locale}
        minDate={minDate}
        maxDate={maxDate}
      />
    </Styled.Root>
  );
};

export default DatePicker;
