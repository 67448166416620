import useMemoSelector from '../useMemoSelector';
import { parametersByNameSelector } from '../../@redux/parameters/selectors';

export default (conditions: any[], defaultValue?: boolean): boolean => {
  if (conditions === undefined) {
    return defaultValue !== undefined ? defaultValue : false;
  }

  const keys = conditions.map(({ key }) => key);
  const parameters = Object.values<any>(
    useMemoSelector(parametersByNameSelector, keys),
  );

  return parameters.every((key, index) => {
    const parameter = parameters[index];
    const condition = conditions[index];
    return condition.values.includes(parameter.value);
  });
};
