import React, { useCallback } from 'react';
import { Slider as SliderComponent } from '@material-ui/core';
import styled from 'styled-components/macro';
import getParamUnitPostfix from 'utils/getParamUnitPostfix';
import { makeStyles } from '@material-ui/core/styles';
import { Unit } from 'constants/paramTypes';
import { getSliderMarks } from './utils';

type Props = {
  value: any;
  step: number;
  minValue: any;
  maxValue: any;
  unit: Unit;
  onChange: (value: string) => void;
};

const StyledSlider = styled(SliderComponent)`
  && {
    .MuiSlider-valueLabel {
      top: -55px;
      left: initial;

      & > span {
        width: 50px;
        height: 50px;

        span {
          text-align: center;
        }
      }
    }
  }
`;

const useStyles = makeStyles(() => ({
  root: {
    width: 650,
    marginTop: 80,
  },
}));

const Slider: React.FC<Props> = ({
  unit,
  step,
  value,
  minValue,
  maxValue,
  onChange,
}) => {
  const classes = useStyles();
  const unitPostfix = getParamUnitPostfix(unit);
  const fixMinValue = Number(Number(minValue).toFixed(1));
  const fixMaxValue = Number(Number(maxValue).toFixed(1));

  const marks = getSliderMarks(step, fixMinValue, fixMaxValue, unitPostfix);

  const getValueLabelFormat = useCallback(
    (value: number): string => {
      return `${Number(Number(value).toFixed(1))} ${unitPostfix}`;
    },
    [unitPostfix],
  );

  const handleChange = React.useCallback(
    (e, value) => {
      onChange(value);
    },
    [onChange],
  );

  return (
    <div className={classes.root}>
      <StyledSlider
        value={value}
        valueLabelFormat={getValueLabelFormat}
        step={step}
        marks={marks}
        min={fixMinValue}
        max={fixMaxValue}
        valueLabelDisplay="on"
        onChange={handleChange}
      />
    </div>
  );
};

export default React.memo(Slider);
