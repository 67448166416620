import React, { memo } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

import InputField from 'components/InputField';
import Button from 'components/Interfaces/NewButton';

import { ResetEmailRequest } from 'api/auth/types';

import validationSchema from './validationSchema';
import * as Styled from './PasswordResetForm.styled';

type Props = {
  isPending: boolean;
  onSubmit: (
    values: ResetEmailRequest,
    actions: FormikHelpers<ResetEmailRequest>,
  ) => void;
};

const PasswordResetForm: React.FC<Props> = ({ isPending, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(props): React.ReactElement => (
        <Styled.Root onSubmit={props.handleSubmit}>
          <InputField
            label="Email"
            name="email"
            type="email"
            mt="16px"
            mb="12px"
          />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            mt="43px"
            disabled={isPending}
          >
            {t('continue')}
          </Button>
        </Styled.Root>
      )}
    </Formik>
  );
};

export default memo(PasswordResetForm);
