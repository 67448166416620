import React from 'react';

type Props = {
  onSetParameter: (key: string) => void;
};

const ParameterContext = React.createContext<Props>({
  onSetParameter: () => null,
});

export default ParameterContext;
