import { getSubstringAfterString } from 'utils/strings';
import i18n from 'i18n';

export const getSectionName = (pathname: string, id: string): string => {
  const slug =
    pathname.indexOf('device') === -1
      ? pathname
      : getSubstringAfterString(pathname, `/device/${id}/`);

  switch (slug) {
    case '':
      return i18n.t('currentData');
    case 'diagrams':
      return i18n.t('diagrams');
    case 'settings/user':
      return i18n.t('userSettings');
    case 'settings/service':
      return i18n.t('serviceSettings');
    case 'history':
      return i18n.t('history');
    case 'alarms':
      return i18n.t('alarms');
    case 'schedule':
      return i18n.t('schedule');
    case 'settings/device':
      return i18n.t('deviceSettings');
    case 'account':
      return i18n.t('account');
    default:
      return 'Unknown section';
  }
};
