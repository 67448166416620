import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

import InputField from 'components/InputField';
import Button from 'components/Interfaces/NewButton';

import { SignInRequest } from 'api/auth/types';

import validationSchema from './validationSchema';
import * as Styled from './SignIn.styled';

type Props = {
  isPending: boolean;
  onSubmit: (
    values: SignInRequest,
    actions: FormikHelpers<SignInRequest>,
  ) => void;
};

const SignIn: React.FC<Props> = ({ isPending, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(props): React.ReactElement => (
        <Styled.Root onSubmit={props.handleSubmit}>
          <InputField
            label="Email"
            name="email"
            type="email"
            mt="16px"
            mb="12px"
          />
          <InputField
            label={t('password')}
            name="password"
            type="password"
            mt="16px"
            mb="12px"
          />
          <Button type="submit" color="primary" mt="20px" disabled={isPending}>
            {t('signIn')}
          </Button>
        </Styled.Root>
      )}
    </Formik>
  );
};

export default SignIn;
