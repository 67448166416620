import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@redux/store';
import { AlarmsType } from './interfaces';

const rootSelector = (state: RootState): AlarmsType => state.alarms;

export const alarmsSelector = createSelector(
  rootSelector,
  (alarms) => alarms.items,
);

export const numberOfPagesSelector = createSelector(
  rootSelector,
  (alarms) => alarms.pageCount,
);

export const areAlarmsPendingSelector = createSelector(
  rootSelector,
  (alarms) => alarms.isPending,
);
