import { ReactComponent as CoolerIcon } from 'assets/icons/coolerParamIcon.svg';
import { ReactComponent as TempIcon } from 'assets/icons/tempParamIcon.svg';
import { ReactComponent as OilIcon } from 'assets/icons/waterParamIcon.svg';
import { ReactComponent as ElectricIcon } from 'assets/icons/ElectricParamIcon.svg';
import { ReactComponent as FullLevelIcon } from 'assets/icons/bemoreFuelLevel.svg';
import { ReactComponent as PowerIcon } from 'assets/icons/bemoreIconPower.svg';
import { ReactComponent as Thermostat } from 'assets/icons/thermostat.svg';
import { ReactComponent as outH } from 'assets/icons/outH.svg';
import { ReactComponent as Door } from 'assets/icons/door.svg';
import { ReactComponent as Зажигалка } from 'assets/icons/Lighter.svg';
import { ReactComponent as ЗакрытиеСмесителя1 } from 'assets/icons/mixer1Close.svg';
import { ReactComponent as ЗакрытиеСмесителя2 } from 'assets/icons/mixer2Close.svg';
import { ReactComponent as Наддув } from 'assets/icons/boost.svg';
import { ReactComponent as НасосГВС } from 'assets/icons/dhwPump.svg';
import { ReactComponent as НасосКотла } from 'assets/icons/mcBoilerPump.svg';
import { ReactComponent as НасосРециркуляцииГВС } from 'assets/icons/dhwRecycle.svg';
import { ReactComponent as НасосСмесителя1 } from 'assets/icons/mixerPump1.svg';
import { ReactComponent as НасосСмесителя2 } from 'assets/icons/mixerPump2.svg';
import { ReactComponent as ОткрытиеСмесителя1 } from 'assets/icons/mixerOpen1.svg';
import { ReactComponent as ОткрытиеСмесителя2 } from 'assets/icons/mixerOpen2.svg';
import { ReactComponent as ТемператураБуфераВерхняя } from 'assets/icons/upper.svg';
import { ReactComponent as ТемператураБуфераНижняя } from 'assets/icons/lower.svg';
import { ReactComponent as ТемператураВозврата } from 'assets/icons/returnTemp.svg';
import { ReactComponent as ТемператураГВС } from 'assets/icons/dhwTemp.svg';
import { ReactComponent as ТемператураКотла } from 'assets/icons/boilerTemp.svg';
import { ReactComponent as ТемператураПодачи } from 'assets/icons/feederTemp.svg';
import { ReactComponent as ТемператураПродуктовСгорания } from 'assets/icons/flueGasTemp.svg';
import { ReactComponent as ТемператураСмесителя1 } from 'assets/icons/mixer1Temp.svg';
import { ReactComponent as ТемператураСмесителя2 } from 'assets/icons/mixer2Temp.svg';
import { ReactComponent as ТемператураУлицы } from 'assets/icons/streetTemp.svg';
import { ReactComponent as Шнек } from 'assets/icons/shnek.svg';

import { ReactComponent as ПоложениеСмесителя1 } from 'assets/icons/mixer1Level.svg';
import { ReactComponent as ПоложениеСмесителя2 } from 'assets/icons/mixer2Level.svg';
import { ReactComponent as НомерПопыткиРозжига } from 'assets/icons/igniteAttemptNumber.svg';

export default {
  cooler: CoolerIcon,
  temp: TempIcon,
  oil: OilIcon,
  electric: ElectricIcon,
  fullLevel: FullLevelIcon,
  power: PowerIcon,
  термостат: Thermostat,
  ТемператураБуфераНижняя,
  ПоложениеСмесителя1,
  ПоложениеСмесителя2,
  НомерПопыткиРозжига,
  ТемператураБуфераВерхняя,
  ОткрытиеСмесителя1,
  ОткрытиеСмесителя2,
  ТемператураГВС,
  ТемператураВозврата,
  НасосСмесителя2,
  НасосСмесителя1,
  outH,
  Дверь: Door,
  ТемператураСмесителя2,
  Зажигалка,
  ТемператураСмесителя1,
  Шнек,
  ТемператураПодачи,
  ТемператураПродуктовСгорания,
  Наддув,
  ТемператураУлицы,
  НасосГВС,
  ЗакрытиеСмесителя2,
  НасосРециркуляцииГВС,
  ТемператураКотла,
  ЗакрытиеСмесителя1,
  НасосКотла,
} as any;
