import { AxiosResponse } from 'axios';
import request from 'api/request';

import {
  FetchDeviceSettingsResponse,
  UpdateDeviceSettingsRequest,
  UpdateDeviceSettingsResponse,
} from './types';

const fetchDeviceSettings = (
  id: string,
): Promise<AxiosResponse<FetchDeviceSettingsResponse>> =>
  request.GET<FetchDeviceSettingsResponse>({
    url: `/devices/${id}/settings`,
  });

const updateDeviceSettings = (
  {
    alarmNotificationPush,
    alarmNotificationEmail,
    ...rest
  }: UpdateDeviceSettingsRequest,
  id: string,
): Promise<AxiosResponse<UpdateDeviceSettingsResponse>> =>
  request.PUT<UpdateDeviceSettingsResponse>({
    url: `/devices/${id}`,
    data: {
      ...rest,
      alarmNotification: {
        email: alarmNotificationEmail,
        push: alarmNotificationPush,
      },
    },
  });

const fetchModuleVersions = (
  id: string,
): Promise<AxiosResponse<FetchDeviceSettingsResponse>> =>
  request.GET<FetchDeviceSettingsResponse>({
    url: `/devices/${id}/softwares`,
    params: {
      targetType: 'module',
    },
  });

const fetchControllerVersions = (
  id: string,
): Promise<AxiosResponse<FetchDeviceSettingsResponse>> =>
  request.GET<FetchDeviceSettingsResponse>({
    url: `/devices/${id}/softwares`,
    params: {
      targetType: 'controller',
    },
  });

const updateVersion = (
  id: string,
  softwareId: string,
): Promise<AxiosResponse<FetchDeviceSettingsResponse>> =>
  request.PATCH<FetchDeviceSettingsResponse>({
    url: `/devices/${id}/softwares/${softwareId}`,
  });

export default {
  fetchDeviceSettings,
  updateDeviceSettings,
  fetchModuleVersions,
  fetchControllerVersions,
  updateVersion,
};
