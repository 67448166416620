import React from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import * as Styled from './Header.styled';

type Props = {
  inner?: boolean;
  open?: boolean;
  onClick: () => void;
};

const Header: React.FC<Props> = ({ inner, open, children, onClick }) => {
  return (
    <Styled.Root inner={inner} onClick={onClick}>
      <span>{children}</span>
      <Styled.Arrow>
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Styled.Arrow>
    </Styled.Root>
  );
};

export default Header;
