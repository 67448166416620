import React, { memo } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';
import SelectField from 'components/Fields/SelectField';
import Typography from 'components/Interfaces/Typography';
import Button from 'components/Interfaces/NewButton';
import Progress from 'components/Interfaces/Progress';

import { UpdateVersionRequest } from 'api/deviceSettings/types';
import { Version } from '@redux/deviceSettings/interfaces';

import { Box } from '@material-ui/core';

import validationSchema from './validationSchema';

import * as Styled from './DownloadVersionModal.styled';

type Props = {
  open: boolean;
  pending: boolean;
  versions: Version[];
  deviceId: string;
  warning: string;
  onSubmit: (
    values: UpdateVersionRequest,
    actions: FormikHelpers<UpdateVersionRequest>,
  ) => void;
  onClose: () => void;
};

const DownloadVersionModal: React.FC<Props> = ({
  open,
  pending,
  versions,
  warning,
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation();

  const versionsItems = versions.map((item) => ({
    id: item.id,
    title: item.version,
  }));

  return (
    <Modal open={open} handleClose={onClose}>
      <Styled.Wrapper>
        <Progress visible={pending}>
          <>
            <Box alignSelf="flex-start">
              <Typography variant="h5">{t('selectVersion')}</Typography>
            </Box>
            <Formik
              initialValues={{ softwareId: '' }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(props): React.ReactElement => (
                <Styled.Form onSubmit={props.handleSubmit}>
                  <SelectField
                    name="softwareId"
                    label={t('versionNoColon')}
                    mt="16px"
                    items={versionsItems}
                  />

                  <Styled.Caption>{warning}</Styled.Caption>

                  <Button
                    type="submit"
                    mt="20px"
                    width="100%"
                    disabled={pending}
                  >
                    {t('changeVersion')}
                  </Button>
                </Styled.Form>
              )}
            </Formik>
          </>
        </Progress>
      </Styled.Wrapper>
    </Modal>
  );
};

export default memo(DownloadVersionModal);
