import React, {
  memo,
  useRef,
  createContext,
  useEffect,
  useCallback,
  useLayoutEffect,
} from 'react';

import { useDispatch } from 'react-redux';
import ActionCable from 'actioncable';

export const SocketContext = createContext<{
  addSubscribe: any;
  removeSubscribe: any;
}>({
  addSubscribe: null,
  removeSubscribe: null,
});

type Props = {
  url: string;
  children: any;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const createConnection = (url: string) => ActionCable.createConsumer(url);

const SocketProvider: React.FC<Props> = ({ url, children }) => {
  const connectionUrl = useRef<string>(url);
  const cable = useRef<ActionCable.Cable>(createConnection(url));
  const isConnected = useRef(false);
  const isFirstConnection = useRef(true);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (url && connectionUrl.current !== url) {
      if (isConnected.current) {
        cable.current.disconnect();
      }

      cable.current = createConnection(url);
      connectionUrl.current = url;
    }

    window.addEventListener('focus', () => {
      if (!isConnected.current) {
        cable.current.connect();
      }
    });
  }, [url]);

  const addSubscribe = useCallback(
    (data, callback) =>
      cable.current.subscriptions.create(data, {
        received: callback,
        connected: () => {
          if (!isConnected.current && !isFirstConnection.current) {
            dispatch({ type: 'RECONNECT' });
          }
          isFirstConnection.current = false;
          isConnected.current = true;
        },
        disconnected: () => {
          if (isConnected.current) {
            setTimeout(() => {
              if (!isConnected.current) {
                dispatch({ type: 'DISCONNECT' });
              }
            }, 3000);
          }
          isConnected.current = false;
        },
      }),
    [cable, dispatch],
  );

  const removeSubscribe = useCallback(
    (subscriber) => (cable.current.subscriptions as any).remove(subscriber),
    [cable],
  );

  return (
    <SocketContext.Provider value={{ addSubscribe, removeSubscribe }}>
      {children}
    </SocketContext.Provider>
  );
};

export default memo(SocketProvider);
