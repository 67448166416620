import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import commandsApi from 'api/commands';
import { SendCommandActions } from './actions';
import { SendCommandActionType } from './types';

function* sendCommandSaga({
  payload,
}: SendCommandActionType): SagaIterator<void> {
  try {
    yield call(commandsApi.sendCommand, payload);

    yield put({ type: SendCommandActions.FULFILLED });
  } catch (e) {
    yield put({ type: SendCommandActions.REJECTED });
  }
}

function* commandsSaga(): SagaIterator<void> {
  yield all([takeLatest(SendCommandActions.REQUESTED, sendCommandSaga)]);
}

export default commandsSaga;
