import { createSlice } from '@reduxjs/toolkit';

import { FetchHistoryActions, UpdateHistoryParamsActions } from '@redux/history/actions';
import { HistoryType } from './interfaces';

import { getYesterdaysDate } from 'utils/getDates';

const initialState: HistoryType = {
	items: {},
	dateFrom: JSON.parse(JSON.stringify(getYesterdaysDate())),
	dateTo: JSON.parse(JSON.stringify(new Date())),
	selectedParams: {
		cdBoilerTemperature: true
	},
	isPending: true
};

const dataHistory = createSlice({
	name: 'dataHistory',
	initialState,
	reducers: {},
	extraReducers: {
		[FetchHistoryActions.REQUESTED]: (state): void => {
			state.isPending = true;
		},
		[FetchHistoryActions.FULFILLED]: (state, { payload }): void => {
			state.items = payload;
			state.isPending = false;
		},
		[FetchHistoryActions.REJECTED]: (state): void => {
			state.isPending = false;
		},
		[UpdateHistoryParamsActions.UPDATE_FROM_DATE]: (state, { payload }): void => {
			state.dateFrom = payload.stringifiedDate;
		},
		[UpdateHistoryParamsActions.UPDATE_TO_DATE]: (state, { payload }): void => {
			state.dateTo = payload.stringifiedDate;
		},
		[UpdateHistoryParamsActions.UPDATE_SELECTED_PARAMS]: (state, { payload }): void => {
			state.selectedParams[payload.param] = payload.value;
		}
	}
});

const { reducer } = dataHistory;

export default reducer;
