import i18next from 'i18next';
import { camelCase } from 'lodash';
import exportToCsv from 'utils/exportToCsv';
import format from 'date-fns/format';

export default (data: [string, []][] ) => {
  const obj: { [key: string]: any } = {};

  const headers: string[] = [];

  data.forEach(([key, param]) => {
    headers.push(key);

    param.forEach(({value, time}) => {
      const resValue = (key === 'cdBoilerOperationMode' || key === 'controllerActivity') ? i18next.t(`stateParameters.${camelCase(value)}`) : value;

      if (!obj[time]) {
        obj[time] = { [key]: resValue }
      } else {
        obj[time][key] = resValue;
      }
    })
  });

  const result = Object.entries(obj).map(([key, value]) => {
    const row = headers.map(header => value[header] || "");

    return [ format(new Date(key), 'dd/MM/yyyy HH:mm:ss'), ...row ];
  });

  const locHeaders = headers.map(header => i18next.t(`parameters.${header}`));

  result.unshift([i18next.t(`time`)  , ...locHeaders]);

  exportToCsv('chart.csv', result);
}