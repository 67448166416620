import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    disable: {
      pointerEvents: 'none',
    },
    wrapperLink: {
      display: 'block',
      padding: '10px',
      [theme.breakpoints.up('sm')]: {
        padding: '16px 8px',
      },
      textDecoration: 'none',
      color: theme.palette.text.primary,
      transition: theme.transitions.create(['background-color'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      }),
      '&:hover': {
        backgroundColor: '#FCE55A',
      },
    },
    badge: {
      padding: '0 5px',
      borderRadius: '2px',
      color: theme.palette.common.white,
    },
    connected: {
      backgroundColor: theme.palette.success.main,
    },
    disconnected: {
      backgroundColor: theme.palette.error.main,
    },
  }),
);
