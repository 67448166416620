import * as Yup from 'yup';
import { formErrors } from 'constants/errors';

const {
  invalidEmail,
  passwordTooShort,
  passwordCharacters,
  passwordsNotMatch,
  requiredField,
} = formErrors;

export default Yup.object().shape({
  email: Yup.string().email(invalidEmail).required(requiredField),
  password: Yup.string()
    .required(requiredField)
    .min(6, passwordTooShort)
    .matches(/[A-Za-z0-9_]/, passwordCharacters),
  confirmPassword: Yup.string()
    .required(requiredField)
    .oneOf([Yup.ref('password'), null], passwordsNotMatch),
  privacyPolicy: Yup.boolean().oneOf([true], requiredField),
});
