import React from 'react';

import Page from 'components/Page';
import { CustomRouteComponentProps } from 'constants/routes';

const Diagrams: React.FC<CustomRouteComponentProps> = (
  props: CustomRouteComponentProps,
) => (
  <Page title={props.title}>
    <div>{props.title}</div>
  </Page>
);

export default Diagrams;
