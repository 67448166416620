import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@redux/store';
import { ApplicationType } from './interfaces';

const rootSelector = (state: RootState): ApplicationType => state.applications;

export const isAuthPending = createSelector(
  rootSelector,
  ({ isPending }) => isPending,
);
