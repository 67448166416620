import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';

import Typography from 'components/Interfaces/Typography';
import Button from 'components/Interfaces/NewButton';

import { Box } from '@material-ui/core';

type Props = {
  open: boolean;
  onDelete: () => void;
  handleClose: () => void;
};

const ConfirmDeviceDeleteModal: React.FC<Props> = ({
  open,
  onDelete,
  handleClose,
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} handleClose={handleClose}>
      <Typography mt={2} mb={4}>
        {t('confirmDeleteDevice')}
      </Typography>
      <Box display="flex" justifyContent="center">
        <Button gray mr={2} onClick={handleClose}>
          {t('cancel')}
        </Button>
        <Button red onClick={onDelete}>
          {t('delete')}
        </Button>
      </Box>
    </Modal>
  );
};

export default memo(ConfirmDeviceDeleteModal);
