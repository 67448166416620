import { takeEvery, put } from 'redux-saga/effects';
import { LoadingActions } from '../@redux/loaders/actions';

type LoadingActionType = {
  REQUESTED: string;
  FULFILLED: string;
  REJECTED: string;
};

function addLoading(type: string) {
  return function* func(): any {
    yield put({ type: LoadingActions.ADD, payload: type });
  };
}

function removeLoading(type: string) {
  return function* func(): any {
    yield put({ type: LoadingActions.REMOVE, payload: type });
  };
}

export default function* loadingSaga(action: LoadingActionType): any {
  yield takeEvery(action.REQUESTED, addLoading(action.REQUESTED));
  yield takeEvery(action.FULFILLED, removeLoading(action.REQUESTED));
  yield takeEvery(action.REJECTED, removeLoading(action.REQUESTED));
}
