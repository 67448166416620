import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@redux/store';
import { DeviceSettingsType } from './interfaces';

const rootSelector = (state: RootState): DeviceSettingsType =>
  state.deviceSettings;

export const deviceSettingsSelector = createSelector(
  rootSelector,
  (deviceSettings) => deviceSettings.data,
);

export const areSettingsPendingSelector = createSelector(
  rootSelector,
  (deviceSettings) => deviceSettings.isPending,
);

export const isModuleVersionModalOpenSelector = createSelector(
  rootSelector,
  (deviceSettings) => deviceSettings.isModuleVersionModalOpen,
);

export const isControllerVersionModalOpenSelector = createSelector(
  rootSelector,
  (deviceSettings) => deviceSettings.isControllerVersionModalOpen,
);

export const moduleVersionsSelector = createSelector(
  rootSelector,
  (deviceSettings) => deviceSettings.moduleVersions,
);

export const controllerVersionsSelector = createSelector(
  rootSelector,
  (deviceSettings) => deviceSettings.controllerVerisons,
);

export const isModuleVersionUpdatePendingSelector = createSelector(
  rootSelector,
  (deviceSettings) => deviceSettings.isModuleVersionUpdatePending,
);

export const isControllerVersionUpdatePendingSelector = createSelector(
  rootSelector,
  (deviceSettings) => deviceSettings.isControllerVersionUpdatePending,
);

export const serviceLevelSelector = createSelector(
  deviceSettingsSelector,
  (data) => (data ? data.deviceSettings.serviceLevel.value : null),
);

export const isNoAccessSelector = createSelector(
  deviceSettingsSelector,
  (data) =>
    data ? data.deviceSettings.serviceLevel.value === 'no_access' : false,
);

export const isConnectedModuleSelector = createSelector(
  deviceSettingsSelector,
  (data) => (data ? data.deviceSettings.moduleStatus === 'connected' : false),
);

export const isConnectedControllerSelector = createSelector(
  deviceSettingsSelector,
  (data) =>
    data ? data.deviceSettings.controllerStatus === 'connected' : false,
);

export const isPushActiveSelector = createSelector(
  rootSelector,
  (deviceSettings) =>
    deviceSettings.data?.deviceSettings.alarmNotification.push || false,
);

export const serviceIntervalSelector = createSelector(
  rootSelector,
  (deviceSettings) =>
    deviceSettings.data?.deviceSettings.serviceInterval || null,
);
