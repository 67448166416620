import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@redux/store';
import { UserType } from './interfaces';

const rootSelector = (state: RootState): UserType => state.user;

export const userSelector = createSelector(rootSelector, (user) => user);

export const isAuthUserSelector = createSelector(
  userSelector,
  (user) => user.id !== null,
);

export const rolesSelector = createSelector(userSelector, (user) => user.roles);

export const isCustomerSelector = createSelector(userSelector, (user) =>
  user.roles.includes('customer'),
);

export const isEngineerSelector = createSelector(userSelector, (user) =>
  user.roles.includes('service_engineer'),
);

export const isUserPendingSelector = createSelector(
  userSelector,
  (user) => user.isPending,
);

export const isPasswordResetPendingSelector = createSelector(
  userSelector,
  (user) => user.isPasswordResetPending,
);

export const isDemoAccountSelector = createSelector(
  userSelector,
  (user) => user.demo,
);
