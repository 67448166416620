import styled from 'styled-components/macro';
import {
  display,
  DisplayProps,
  spacing,
  SpacingProps,
} from '@material-ui/system';

export const Root = styled.div`
  display: flex;
  align-items: center;
`;

export const Image = styled.img<DisplayProps & SpacingProps>`
  ${display}
  ${spacing}

  width: 100px;
`;

export const Params = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const ParamBlock = styled.div<SpacingProps>`
  ${spacing}

  display: flex;
  align-items: flex-end;
  flex-direction: column;

  &:first-child {
    min-width: 261px;
    align-items: flex-start;
  }

  &:nth-child(2) {
    min-width: 168px;
  }
`;

export const Param = styled.div`
  display: flex;
  margin-bottom: 2px;
`;
