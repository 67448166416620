import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import TextField from "@material-ui/core/TextField";

export default styled(TextField)`
  && {
    ${spacing}

    .MuiFormHelperText-root  {
      position: absolute;
      bottom: -20px;
    }
  }
`;