import React, { memo, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';

import PasswordResetForm from './PasswordResetForm';

import PageWrapper from 'components/PageWrapper';
import Page from 'components/Page';
import ResultMessages from 'components/ResultMessages';
import Paper from 'components/Interfaces/Paper';
import Typography from 'components/Interfaces/Typography';
import Progress from 'components/Interfaces/Progress';

import { ResetPasswordActions } from '@redux/user/actions';
import { isPasswordResetPendingSelector } from '@redux/user/selectors';

import userAPI from 'api/user';

import { ResetEmailRequest } from 'api/auth/types';
import { CustomRouteComponentProps } from 'constants/routes';

import * as Styled from './PasswordReset.styled';
import Header from '../../components/Header';

const PasswordReset: React.FC<CustomRouteComponentProps> = (
  props: CustomRouteComponentProps,
) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [resultView, setResultView] = useState('');
  const isPasswordResetPending = useSelector(isPasswordResetPendingSelector);

  const handleSubmit = useCallback(
    (values: ResetEmailRequest) => {
      userAPI
        .resetPassword(values)
        .then(() => {
          dispatch({
            type: ResetPasswordActions.FULFILLED,
          });
          setResultView('success');
        })
        .catch(() => {
          setResultView('failure');
        });
    },
    [dispatch],
  );

  const handleContinueClick = useCallback(() => {
    dispatch(push('/signIn'));
  }, [dispatch]);

  return (
    <Page title={props.title}>
      <Header logo back lang fixed />
      <PageWrapper>
        <Styled.Root>
          <Paper px={6} py={8} textAlign="center">
            <Progress visible={isPasswordResetPending}>
              {resultView === '' ? (
                <>
                  <Typography variant="h5" mb={4}>
                    {t('passwordReset')}
                  </Typography>
                  <Typography variant="body1" mb={1 / 2}>
                    {t('getResetLink')}
                  </Typography>
                  <PasswordResetForm
                    isPending={false}
                    onSubmit={handleSubmit}
                  />
                </>
              ) : (
                <ResultMessages
                  resultView={resultView}
                  successMessage="newPasswordWasSent"
                  onButtonClick={handleContinueClick}
                />
              )}
            </Progress>
          </Paper>
        </Styled.Root>
      </PageWrapper>
    </Page>
  );
};

export default memo(PasswordReset);
