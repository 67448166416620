import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { Slider as SliderComponent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';

import ModalContext from 'context/ModalContext';
import useMemoSelector from 'hooks/useMemoSelector';
import BaseModal from '../BaseModal';
import * as Styled from './NumberValueModal.styled';
import { parameterByNameSelector } from '../../../@redux/parameters/selectors';
import getParamUnitPostfix from '../../../utils/getParamUnitPostfix';
import { getSliderMarks } from '../../Modal/ParamSettingModal/Parameters/Slider/utils';
import Button from '../../Interfaces/NewButton';
import { UpdateDeviceParameterActions } from '../../../@redux/devices/actions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

type Props = {
  data: any;
};

const StyledSlider = styled(SliderComponent)`
  && {
    margin-top: 80px;
    color: #ffe632;

    .MuiSlider-valueLabel {
      top: -55px;
      left: initial;

      & > span {
        width: 50px;
        height: 50px;

        span {
          color: #000;
          text-align: center;
        }
      }
    }
  }
`;

const NumberValueModal: React.FC<Props> = ({ data }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const { closeModal } = useContext(ModalContext);

  const { key, value, unit, minValue, maxValue, valueStep } = useMemoSelector(
    parameterByNameSelector,
    data.key,
  );

  const unitPostfix = getParamUnitPostfix(unit);
  const fixMinValue = Number(Number(minValue).toFixed(1));
  const fixMaxValue = Number(Number(maxValue).toFixed(1));
  const [changedValue, setValue] = useState(value);

  const marks = getSliderMarks(
    valueStep,
    fixMinValue,
    fixMaxValue,
    unitPostfix,
  );

  const handleSave = useCallback(() => {
    closeModal();
    dispatch({
      type: UpdateDeviceParameterActions.REQUESTED,
      payload: { uid: id, key, value: changedValue },
    });
  }, [id, key, changedValue, dispatch]);

  const handleChange = useCallback(
    (event, newValue) => {
      setValue(newValue);
    },
    [setValue],
  );

  const getValueLabelFormat = useCallback(
    (value: number) => {
      return `${Number(Number(value).toFixed(1))} ${unitPostfix}`;
    },
    [unitPostfix],
  );

  return (
    <BaseModal onClose={closeModal}>
      <Styled.Root>
        <Styled.Title>{t(`parameters.${camelCase(data.key)}`)}</Styled.Title>
        <StyledSlider
          value={changedValue}
          marks={marks}
          step={valueStep}
          min={fixMinValue}
          max={fixMaxValue}
          valueLabelDisplay="auto"
          valueLabelFormat={getValueLabelFormat}
          onChange={handleChange}
        />
        <Styled.Buttons>
          <Button gray onClick={closeModal}>
            {t('cancel')}
          </Button>
          <Button onClick={handleSave}>{t('save')}</Button>
        </Styled.Buttons>
      </Styled.Root>
    </BaseModal>
  );
};

export default NumberValueModal;
