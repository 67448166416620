import styled, { css } from 'styled-components/macro';
import { space, layout } from 'styled-system';

type Props = {
  gray?: boolean;
  red?: boolean;
  disabled?: boolean;
};

export const Root = styled.button<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 22px;
  font-size: 14px;
  color: #000;
  background: #ffe632;
  border: none;
  outline: none;
  text-decoration: none;

  ${(props) =>
    props.gray &&
    css`
      background: #ededed;
    `}

  ${(props) =>
    props.red &&
    css`
      color: #fff;
      background: #e2325a;
    `}

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
      cursor: default;
    `}
  
  ${space}
  ${layout}
`;
