import theme from 'styles/theme';

export interface ColorsType {
	[key: string]: string;
}

const { palette } = theme;

const colors: ColorsType = {
	cdFanPower: palette.primary.main,
	cdBoilerPower: palette.error.main,
	cdBoilerTemperature: palette.success.main,
	cdFlueGasTemperature: '#00FF00',
	cdReturnTemperature: '#0000FF',
	cdFeederTemperature: '#FF0000',
	cdDhwTemperature: '#01FFFE',
	cdMixer1Temperature: '#006401',
	cdMixer2Temperature: '#007DB5',
	cdUpperBufferTemperature: '#FF00F6',
	cdLowerBufferTemperature: '#D5FF00',
	cdOutsideTemperature: '#FF937E',
	cdFuelLevel: '#6A826C',
	cdRoom1Temperature: '#FE8900',
	cdRoom2Temperature: '#7A4782',
	dStateBoilerPump: '#A0AC8F',
	dStateCirculatingPump: '#2627C3',
	dStateDhwPump: '#6909E6',
	dStateFan: '#D48B55',
	dStateFeeder: '#0A62B2',
	dStateIgniter: '#50F46E',
	dStateMixer1CloseValve: '#C22B3A',
	dStateMixer1OpenValve: '#869C49',
	dStateMixer1Pump: '#F21958',
	dStateMixer2CloseValve: '#0D31A9',
	dStateMixer2OpenValve: '#D0A755',
	dStateMixer2Pump: '#C32902',
	dStateOutH: '#484CD3',
	cdBoilerOperationMode: palette.primary.main,
	controllerActivity: '#20bd54'
};

export default colors;
