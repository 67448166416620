import _set from 'lodash/set';
import _isObject from 'lodash/isObject';
import camelize from 'utils/camelize';

const manualParamsConditions = {
  enabled: [
    {
      key: 'CD_Boiler_operation_mode',
      values: ['turned_off', 'manual'],
    },
  ],
};

const structure = {
  currentData: {
    Custom_Boiler_operation_mode: {
      unit: 'cdBoilerOperationMode',
      key: 'cdBoilerOperationMode',
      parameters: {
        CD_Boiler_operation_mode: {},
        CD_Boiler_turn_on: {},
        CD_Boiler_turn_off: {},
      },
    },
    CD_Boiler_power: {
      icon: 'power',
    },
    CD_Fan_power: {
      icon: 'cooler',
    },
    Custom_Fuel_Level: {
      unit: 'cdFuelLevel',
      key: 'cdFuelLevel',
    },
    Custom_Boiler_temperature: {
      icon: 'ТемператураКотла',
      unit: 'boilerTemperature',
      key: 'cdBoilerTemperature',
      modalType: 'numberValueModal',
      modal: {
        disabled: { text: 'waterModeEnabled' },
      },
      conditions: {
        disabled: [{ key: 'BoilerS_Weather_control', value: 'true' }],
      },
      parameters: {
        BoilerS_Heat_source: {},
        BoilerS_Present_boiler_temperature: {},
        BoilerS_Boiler_preset_temperature_in_G_mode: {},
        CD_Boiler_temperature: {},
      },
    },
    Custom_Room_1_temperature: {
      icon: 'temp',
      unit: 'roomOneTemperatureParameter',
      key: 'cdRoom1Temperature',
      modalType: 'numberValueModal',
      conditions: {
        disabled: [{ key: 'BoilerS_Weather_control', value: 'true' }],
        // hidden: [{ key: 'CD_Room_1_temperature', value: 'null' }],
      },
      parameters: {
        CD_Room_1_temperature: {},
        CD_ecoSTER_1_operation_mode: {},
        CD_Night_room_1_temperature: {},
        CD_Day_room_1_temperature: {},
        ecoSTER1_Antifreeze_temperature_set_point: {},
        ecoSTER1_Temperature_setpoint_for_operating_mode_Party: {},
        ecoSTER1_Temperature_setpoint_for_operating_mode_Holiday: {},
      },
    },
    Custom_CD_Mixer_1_temperature: {
      icon: 'ТемператураСмесителя1',
      unit: 'combinedTemperature',
      key: 'cdMixer1Temperature',
      modalType: 'numberValueModal',
      modal: {
        disabled: { text: 'waterModeEnabled' },
      },
      conditions: {
        disabled: [{ key: 'Mix1S_Weather_control', value: 'true' }],
        hidden: [{ key: 'CD_Mixer_1_temperature', value: 'null' }],
      },
      parameters: {
        Mix1S_Present_mixer_temperature: {},
        CD_Mixer_1_temperature: {},
      },

      specifiedTemperatureKeys: {
        bmoreAuto: 'CD_Present_mixer_1_temperature',
        ecomax: 'Mix1S_Present_mixer_temperature',
      },
      editKey: 'Mix1S_Present_mixer_temperature',
    },
    Custom_CD_Mixer_2_temperature: {
      icon: 'ТемператураСмесителя2',
      unit: 'combinedTemperature',
      key: 'cdMixer2Temperature',
      modalType: 'numberValueModal',
      modal: {
        disabled: { text: 'waterModeEnabled' },
      },
      conditions: {
        disabled: [
          { key: 'Mix2S_Weather_control', value: 'true' },
          { key: 'CD_Mixer_2_temperature', value: 'null' },
        ],
        hidden: [{ key: 'CD_Mixer_2_temperature', value: 'null' }],
      },
      parameters: {
        Mix2S_Present_mixer_temperature: {},
        CD_Mixer_2_temperature: {},
      },
      specifiedTemperatureKeys: {
        bmoreAuto: 'CD_Present_mixer_2_temperature',
        ecomax: 'Mix2S_Present_mixer_temperature',
      },
      editKey: 'Mix2S_Present_mixer_temperature',
    },
    Custom_DHW_temperature: {
      icon: 'ТемператураГВС',
      unit: 'combinedTemperature',
      key: 'cdDhwTemperature',
      modalType: 'numberValueModal',
      conditions: {
        hidden: [{ key: 'CD_DHW_temperature', value: 'null' }],
      },
      parameters: {
        DHW_S_Present_DHW_temperature: {},
        CD_DHW_temperature: {},
      },
      specifiedTemperatureKeys: {
        bmoreAuto: 'CD_Present_DHW_temperature',
        ecomax: 'DHW_S_Present_DHW_temperature',
      },
      editKey: 'DHW_S_Present_DHW_temperature',
    },
    CD_Upper_buffer_temperature: {
      icon: 'ТемператураБуфераВерхняя',
      conditions: {
        hidden: [
          {
            key: 'CD_Upper_buffer_temperature',
            value: 'null',
          },
        ],
      },
    },
    CD_Lower_buffer_temperature: {
      icon: 'ТемператураБуфераНижняя',
      conditions: {
        hidden: [
          {
            key: 'CD_Lower_buffer_temperature',
            value: 'null',
          },
        ],
      },
    },
    CD_Return_temperature: {
      icon: 'ТемператураВозврата',
    },
    CD_Feeder_temperature: { icon: 'ТемператураПодачи' },
    CD_Flue_gas_temperature: {
      icon: 'ТемператураПродуктовСгорания',
      conditions: {
        hidden: [
          {
            key: 'CD_Flue_gas_temperature',
            value: 'null',
          },
        ],
      },
    },
    CD_Outside_temperature: {
      icon: 'ТемператураУлицы',
      conditions: {
        hidden: [
          {
            key: 'CD_Outside_temperature',
            value: 'null',
          },
        ],
      },
    },

    D_State_OutH: {
      icon: 'outH',
      advancedUserOnly: true,
    },
    D_State_igniter: {
      icon: 'Зажигалка',
      advancedUserOnly: true,
    },
    D_State_circulating_pump: {
      advancedUserOnly: true,
      icon: 'НасосРециркуляцииГВС',
      conditions: {
        hidden: [{ key: 'CD_DHW_temperature', value: 'null' }],
      },
    },
    D_State_DHW_pump: {
      icon: 'НасосГВС',
      advancedUserOnly: true,
      conditions: {
        hidden: [{ key: 'CD_DHW_temperature', value: 'null' }],
      },
    },
    D_State_boiler_pump: {
      icon: 'НасосКотла',
      advancedUserOnly: true,
    },
    D_State_feeder: {
      icon: 'Шнек',
      advancedUserOnly: true,
    },
    D_State_fan: {
      icon: 'Наддув',
      advancedUserOnly: true,
    },
    D_State_mixer1_pump: {
      icon: 'НасосСмесителя1',
      advancedUserOnly: true,
      conditions: {
        hidden: [{ key: 'CD_Mixer_1_temperature', value: 'null' }],
      },
    },
    D_State_mixer2_pump: {
      icon: 'НасосСмесителя2',
      advancedUserOnly: true,
      conditions: {
        hidden: [{ key: 'CD_Mixer_2_temperature', value: 'null' }],
      },
    },
    D_State_mixer1_open_valve: {
      icon: 'ОткрытиеСмесителя1',
      advancedUserOnly: true,
      conditions: {
        hidden: [{ key: 'CD_Mixer_1_temperature', value: 'null' }],
      },
    },
    D_State_mixer2_open_valve: {
      icon: 'ОткрытиеСмесителя2',
      advancedUserOnly: true,
      conditions: {
        hidden: [{ key: 'CD_Mixer_2_temperature', value: 'null' }],
      },
    },
    D_State_mixer1_close_valve: {
      advancedUserOnly: true,
      icon: 'ЗакрытиеСмесителя1',
      conditions: {
        hidden: [{ key: 'CD_Mixer_1_temperature', value: 'null' }],
      },
    },
    D_State_mixer2_close_valve: {
      advancedUserOnly: true,
      icon: 'ЗакрытиеСмесителя2',
      conditions: {
        hidden: [{ key: 'CD_Mixer_2_temperature', value: 'null' }],
      },
    },
    CD_Mixer_1_Level: {
      advancedUserOnly: true,
      icon: 'ПоложениеСмесителя1',
      conditions: {
        hidden: [
          { key: 'CD_Mixer_1_temperature', value: 'null' },
          { key: 'Devices_model', value: 'plum_ecomax_920' },
        ],
      },
    },
    CD_Mixer_2_Level: {
      icon: 'ПоложениеСмесителя2',

      advancedUserOnly: true,
      hidden: [
        { key: 'CD_Mixer_2_temperature', value: 'null' },
        { key: 'Devices_model', value: 'plum_ecomax_920' },
      ],
    },
    CD_Ignition_attempt_number: {
      icon: 'НомерПопыткиРозжига',
      advancedUserOnly: true,
      hidden: [{ key: 'Devices_model', value: 'plum_ecomax_920' }],
    },

    D_State_signal_door: {
      icon: 'Дверь',
      advancedUserOnly: true,
      hidden: [{ key: 'Devices_model', value: 'plum_ecomax_920' }],
    },
    D_State_signal_thermostat: {
      icon: 'термостат',
      advancedUserOnly: true,
      hidden: [{ key: 'Devices_model', value: 'plum_ecomax_920' }],
    },
  },
  userSettings: {
    boilerSettings: {
      outputModulation: {
        BoilerS_OutMod_Feeding_time_operation: {
          conditions: {
            hidden: [
              {
                key: 'BoilerS_Regulation_mode',
                value: 'standart',
                notEqual: true,
              },
            ],
          },
        },
        BoilerS_OutMod_Pause_feeding_operation: {
          conditions: {
            hidden: [
              {
                key: 'BoilerS_Regulation_mode',
                value: 'standart',
                notEqual: true,
              },
            ],
          },
        },
        BoilerS_OutMod_Fan_output_operation: {
          conditions: {
            hidden: [
              {
                key: 'BoilerS_Regulation_mode',
                value: 'standart',
                notEqual: true,
              },
            ],
          },
        },
        BoilerS_OutMod_Hysteresis_boiler: {
          conditions: {
            hidden: [
              {
                key: 'BoilerS_Heat_source',
                value: 'grate',
                notEqual: true,
              },
            ],
          },
        },
        BoilerS_OutMod_Fan_output_at_100_boiler_power: {
          conditions: {
            hidden: [
              {
                key: 'BoilerS_Regulation_mode',
                value: 'standart',
              },
            ],
          },
        },
        BoilerS_OutMod_Fan_output_at_80_boiler_power: {
          conditions: {
            hidden: [
              {
                key: 'BoilerS_Regulation_mode',
                value: 'standart',
              },
            ],
          },
        },
        BoilerS_OutMod_Fan_output_at_60_boiler_power: {
          conditions: {
            hidden: [
              {
                key: 'BoilerS_Regulation_mode',
                value: 'standart',
              },
            ],
          },
        },
        BoilerS_OutMod_Fan_output_at_40_boiler_power: {
          conditions: {
            hidden: [
              {
                key: 'BoilerS_Regulation_mode',
                value: 'standart',
              },
            ],
          },
        },
        BoilerS_OutMod_Fan_output_at_30_boiler_power: {
          conditions: {
            hidden: [
              {
                key: 'BoilerS_Regulation_mode',
                value: 'standart',
              },
            ],
          },
        },
        BoilerS_OutMod_Complete_of_the_cycle_100: {
          conditions: {
            hidden: [
              {
                key: 'BoilerS_Regulation_mode',
                value: 'standart',
              },
            ],
          },
        },
        BoilerS_OutMod_Minimum_boiler_output_at_Fuzzy_Logic_mode: {
          conditions: {
            hidden: [
              {
                key: 'BoilerS_Regulation_mode',
                value: 'standart',
              },
            ],
          },
        },
        BoilerS_OutMod_Maximum_boiler_output_at_Fuzzy_Logic_mode: {
          conditions: {
            hidden: [
              {
                key: 'BoilerS_Regulation_mode',
                value: 'standart',
              },
            ],
          },
        },
        BoilerS_OutMod_Fan_output_at_100_boiler_power_G: {
          conditions: {
            hidden: [
              {
                key: 'BoilerS_Heat_source',
                value: 'grate',
                notEqual: true,
              },
            ],
          },
        },
        BoilerS_OutMod_Hysteresis_H2: {
          conditions: {
            hidden: [
              {
                key: 'BoilerS_Heat_source',
                value: 'grate',
                notEqual: true,
              },
            ],
          },
        },
        BoilerS_OutMod_Fan_output_at_50_boiler_power_G: {
          conditions: {
            hidden: [
              {
                key: 'BoilerS_Heat_source',
                value: 'grate',
                notEqual: true,
              },
            ],
          },
        },
        BoilerS_OutMod_Hysteresis_H1: {
          conditions: {
            hidden: [
              {
                key: 'BoilerS_Heat_source',
                value: 'grate',
                notEqual: true,
              },
            ],
          },
        },
        BoilerS_OutMod_Fan_output_at_30_boiler_power_G: {
          conditions: {
            hidden: [
              {
                key: 'BoilerS_Heat_source',
                value: 'grate',
                notEqual: true,
              },
            ],
          },
        },
        BoilerS_OutMod_Fan_output_fire_up: {
          conditions: {
            hidden: [
              {
                key: 'BoilerS_Heat_source',
                value: 'grate',
                notEqual: true,
              },
            ],
          },
        },
        BoilerS_OutMod_Hysteresis_boiler_G: {
          conditions: {
            hidden: [
              {
                key: 'BoilerS_Heat_source',
                value: 'grate',
                notEqual: true,
              },
            ],
          },
        },
        // BoilerS_OutMod_Oxyglevel_at_100_boiler_power: {},
        // BoilerS_OutMod_Oxyglevel_at_50_boiler_power: {},
        // BoilerS_OutMod_Oxyglevel_at_30_boiler_power: {},
      },
      fuelLevel: {
        BoilerS_Fuel_level_Alarm_level: {},
        BoilerS_Fuel_level_Calibration: {},
        fuel_level_update: {
          unit: 'command',
          editable: true,
          key: 'fuelLevelUpdate ',
          valueType: 'Command',
          value: 'fuel_level_update ',
          conditions: {
            hidden: [{ key: 'Devices_model', value: 'plum_ecomax_920' }],
          },
        },
      },
      BoilerS_Present_boiler_temperature: {
        modal: {
          disabled: { text: 'waterModeEnabled' },
        },
        conditions: {
          disabled: [{ key: 'BoilerS_Weather_control', value: 'true' }],
        },
      },
      BoilerS_Weather_control: {},
      BoilerS_Heating_curve: {},
      BoilerS_Heating_curve_parallel_shift: {},
      BoilerS_Room_temperature_factor: {
        conditions: {
          hidden: [
            { key: 'CD_Room_1_temperature', value: 'null' },
            { key: 'CD_Room_2_temperature', value: 'null' },
          ],
        },
      },
      BoilerS_Heat_source: {},
      BoilerS_Regulation_mode: {},
      BoilerS_Fuel_type: {},
      BoilerS_Fuel_auto_switch: {},
      BoilerS_Boiler_preset_temperature_in_G_mode: {},
      BoilerS_Reduce_present_temperature_by_thermostat: {
        conditions: {
          hidden: [{ key: 'Devices_model', value: 'plum_ecomax_920' }],
        },
      },
    },
    dhwSettings: {
      DHW_S_Present_DHW_temperature: {
        conditions: {
          hidden: [{ key: 'CD_DHW_temperature', value: 'null' }],
        },
      },
      DHW_S_DHW_pump_mode: {
        conditions: {
          hidden: [{ key: 'CD_DHW_temperature', value: 'null' }],
        },
      },
      DHW_S_DHW_hysteresis: {},
      DHW_S_DHW_disinfection: {},
    },
    summerWinter: {
      SW_Summer_mode: {},
      SW_Summer_mode_activation_temperature: {},
      SW_Summer_mode_deactivation_temperature: {},
    },
    mixer1Settings: {
      Mix1S_Present_mixer_temperature: {
        modal: {
          disabled: { text: 'waterModeEnabled' },
        },
        conditions: {
          hidden: [{ key: 'CD_Mixer_1_temperature', value: 'null' }],
          disabled: [{ key: 'Mix1S_Weather_control', value: 'true' }],
        },
      },
      Mix1S_Reduce_present_mixer_temperature_by_thermostat: {
        conditions: {
          hidden: [{ key: 'CD_Mixer_1_temperature', value: 'null' }],
        },
      },
      Mix1S_Weather_control: {
        conditions: {
          hidden: [{ key: 'CD_Mixer_1_temperature', value: 'null' }],
        },
      },
      Mix1S_Heating_curve: {
        conditions: {
          hidden: [{ key: 'CD_Mixer_1_temperature', value: 'null' }],
        },
      },
      Mix1S_Heating_curve_parallel_shift: {
        conditions: {
          hidden: [{ key: 'CD_Mixer_1_temperature', value: 'null' }],
        },
      },
      Mix1S_Room_temperature_factor: {
        conditions: {
          hidden: [{ key: 'CD_Mixer_1_temperature', value: 'null' }],
        },
      },
    },
    mixer2Settings: {
      Mix2S_Present_mixer_temperature: {
        modal: {
          disabled: { text: 'waterModeEnabled' },
        },
        conditions: {
          hidden: [{ key: 'CD_Mixer_2_temperature', value: 'null' }],
          disabled: [{ key: 'Mix2S_Weather_control', value: 'true' }],
        },
      },
      Mix2S_Reduce_present_mixer_temperature_by_thermostat: {
        conditions: {
          hidden: [{ key: 'CD_Mixer_2_temperature', value: 'null' }],
        },
      },
      Mix2S_Weather_control: {
        conditions: {
          hidden: [{ key: 'CD_Mixer_2_temperature', value: 'null' }],
        },
      },
      Mix2S_Heating_curve: {
        conditions: {
          hidden: [{ key: 'CD_Mixer_2_temperature', value: 'null' }],
        },
      },
      Mix2S_Heating_curve_parallel_shift: {
        conditions: {
          hidden: [{ key: 'CD_Mixer_2_temperature', value: 'null' }],
        },
      },
      Mix2S_Room_temperature_factor: {
        conditions: {
          hidden: [{ key: 'CD_Mixer_2_temperature', value: 'null' }],
        },
      },
    },
    ecoSTER1: {
      CD_ecoSTER_1_operation_mode: {
        conditions: {
          hidden: [{ key: 'CD_Room_1_temperature', value: 'null' }],
        },
      },
      ecoSTER1_Antifreeze_temperature_set_point: {
        conditions: {
          hidden: [{ key: 'CD_Room_1_temperature', value: 'null' }],
        },
      },
      ecoSTER1_Temperature_setpoint_for_operating_mode_Party: {
        conditions: {
          hidden: [{ key: 'CD_Room_1_temperature', value: 'null' }],
        },
      },
      ecoSTER1_Temperature_setpoint_for_operating_mode_Holiday: {
        conditions: {
          hidden: [{ key: 'CD_Room_1_temperature', value: 'null' }],
        },
      },
      ecoSTER1_Set_point_temperature_hysteresis: {
        conditions: {
          hidden: [{ key: 'CD_Room_1_temperature', value: 'null' }],
        },
      },
      ecoSTER1_Adjustment_of_temperature_indication: {
        conditions: {
          hidden: [{ key: 'CD_Room_1_temperature', value: 'null' }],
        },
      },
      ecoSTER1_Duration_mode_Out_of_the_house: {
        conditions: {
          hidden: [{ key: 'CD_Room_1_temperature', value: 'null' }],
        },
      },
      ecoSTER1_Duration_mode_Ventilation: {
        conditions: {
          hidden: [{ key: 'CD_Room_1_temperature', value: 'null' }],
        },
      },
      ecoSTER1_Duration_mode_Party: {
        conditions: {
          hidden: [{ key: 'CD_Room_1_temperature', value: 'null' }],
        },
      },
      ecoSTER1_Duration_mode_Holiday: {
        conditions: {
          hidden: [{ key: 'CD_Room_1_temperature', value: 'null' }],
        },
      },
    },
    commonSettings: {
      Time: {
        conditions: {
          hidden: [{ key: 'Devices_model', value: 'plum_ecomax_920' }],
        },
      },
      Outside_temperature_correction: {
        conditions: {
          hidden: [{ key: 'Devices_model', value: 'plum_ecomax_920' }],
        },
      },
    },
  },
  serviceSettings: {
    burnerSettings: {
      firingUp: {
        SV_BurnS_FU_Ignition_test_time: {},
        SV_BurnS_FU_FU_initial_test: {},
        SV_BurnS_FU_FU_final_test: {},
        SV_BurnS_FU_Time_of_feeding_in_the_fire_test: {},
        SV_BurnS_FU_Feeding_time: {},
        SV_BurnS_FU_Fumes_temperature_at_the_end: {},
        SV_BurnS_FU_Emission_delta: {},
        SV_BurnS_FU_FU_airflow: {},
        SV_BurnS_FU_FU_time: {},
        SV_BurnS_FU_Blowing_time_after: {},
        SV_BurnS_FU_Heating_up_time: {},
        SV_BurnS_FU_Stabilization_time: {},
        SV_BurnS_FU_Feeding_time_stabilization: {},
        SV_BurnS_FU_Feeding_pause_stabilization: {},
        SV_BurnS_FU_Fan_output_stabilization: {},
      },
      operation: {
        SV_BurnS_OperationCycle_time: {},
      },
      puttingOut: {
        SV_BurnS_Putting_out_Putting_out_time: {},
        SV_BurnS_Putting_out_Fan_output_in_burning_off: {},
      },
      supervision: {
        SV_BurnS_SuV_SuV_time: {},
        SV_BurnS_SuV_Feeding_time: {},
        SV_BurnS_SuV_Fan_output: {},
        SV_BurnS_SuV_Feeder_interval_time_in_SuV_mode: {},
        SV_BurnS_SuV_Blowing_time_extension_in_SuV_mode: {},
      },
      SV_BurnS_Minimum_airflow_output: {},
      SV_BurnS_Fuel_shortage_detection_time: {},
      SV_BurnS_Fumes_temperature_at_no_fuel: {},
      SV_BurnS_Maximum_feeder_temperature: {},
      SV_BurnS_Fumes_temperature_increase_time: {},
    },
    boilerSettings: {
      grate: {
        SV_BoilerS_G_Maximum_FU_time: {},
        SV_BoilerS_G_Blowing_time_in_SuV_in_G_mode: {},
        SV_BoilerS_G_Blowing_pause_in_SuV_in_G_mode: {},
        SV_BoilerS_G_Fuel_shortage_detection_time: {},
        SV_BoilerS_G_Fuel_shortage_method: {},
        SV_BoilerS_G_Exh_temp_blow_reduct: {},
      },
      returnProtection: {
        SV_BoilerS_RP_Operation_mode: {},
        SV_BoilerS_RP_Minimum_return_temperature: {},
        SV_BoilerS_RP_Return_temperature_hysteresis: {},
        SV_BoilerS_RP_Close_valve_for_RP: {},
        SV_BoilerS_RP_Shunt_pump_start_temperature: {},
      },
      SV_BoilerS_RP_Minimum_present_boiler_temperature: {},
      SV_BoilerS_RP_Maximum_present_boiler_temperature: {},
      SV_BoilerS_RP_Boiler_cooling_preventive_temperature: {},
      SV_BoilerS_RP_Thermostat_selection: {},
      SV_BoilerS_RP_Pump_off_by_thermostat: {},
    },
    chDHW: {
      SV_CH_DHW_S_CH_pump_activation_temperature: {},
      SV_CH_DHW_S_CH_pump_standstill_when_loading_DHW: {},
      SV_CH_DHW_S_CH_pump_pause_time_from_thermostat: {},
      SV_CH_DHW_S_CH_pump_work_time_from_thermostat: {},
      SV_CH_DHW_S_Minimum_DHW_temperature: {},
      SV_CH_DHW_S_Maximum_DHW_temperature: {},
      SV_CH_DHW_S_Increasing_present_boiler_temperature_by_DHW_and_Mixer: {},
      SV_CH_DHW_S_Expending_DHW_pump_work_time: {},
      SV_CH_DHW_S_Circulating_pump_standstill_time: {},
      SV_CH_DHW_S_Circulating_pump_work_time: {},
      SV_CH_DHW_S_Circulating_pump_start_temperature: {},
      SV_CH_DHW_S_Operation_mode: {},
      SV_CH_DHW_DHW_heat_source: {
        conditions: {
          hidden: [
            { key: 'SV_BufS_Buffer_support', value: 'yes', notEqual: true },
          ],
        },
      },
    },
    bufferSettings: {
      SV_BufS_Buffer_support: {
        conditions: {
          hidden: [{ key: 'CD_Upper_buffer_temperature', value: 'null' }],
        },
      },
      SV_BufS_Buffer_loading_start_temperature: {
        conditions: {
          hidden: [{ key: 'CD_Upper_buffer_temperature', value: 'null' }],
        },
      },
      SV_BufS_Buffer_loading_end_temperature: {
        conditions: {
          hidden: [{ key: 'CD_Upper_buffer_temperature', value: 'null' }],
        },
      },
      SV_BufS_Start_heat_installation_temperature: {
        conditions: {
          hidden: [{ key: 'CD_Upper_buffer_temperature', value: 'null' }],
        },
      },
    },
    mixer1Settings: {
      SV_Mix1S_Room_thermostat: {
        conditions: {
          hidden: [{ key: 'CD_Mixer_1_temperature', value: 'null' }],
        },
      },
      SV_Mix1S_Mixer_support: {},
      SV_Mix1S_Operation_in_summer: {
        conditions: {
          hidden: [{ key: 'CD_Mixer_1_temperature', value: 'null' }],
        },
      },
      SV_Mix1S_Mixer_minimum_present_temperature: {
        conditions: {
          hidden: [{ key: 'CD_Mixer_1_temperature', value: 'null' }],
        },
      },
      SV_Mix1S_Mixer_maximum_present_temperature: {
        conditions: {
          hidden: [{ key: 'CD_Mixer_1_temperature', value: 'null' }],
        },
      },
      SV_Mix1S_Valve_full_opening_time: {
        conditions: {
          hidden: [{ key: 'CD_Mixer_1_temperature', value: 'null' }],
        },
      },
      SV_Mix1S_Pump_off_by_thermostat: {
        conditions: {
          hidden: [{ key: 'CD_Mixer_1_temperature', value: 'null' }],
        },
      },
      SV_Mix1S_Mixer_input_dead_zone: {},
      SV_Mix1S_Proportional_coefficient: {},
      SV_Mix1S_Integration_time_constant: {},
    },
    mixer2Settings: {
      SV_Mix2S_Room_thermostat: {
        conditions: {
          hidden: [{ key: 'CD_Mixer_2_temperature', value: 'null' }],
        },
      },
      SV_Mix2S_Mixer_support: {
        conditions: {
          hidden: [{ key: 'CD_Mixer_2_temperature', value: 'null' }],
        },
      },
      SV_Mix2S_Operation_in_summer: {
        conditions: {
          hidden: [{ key: 'CD_Mixer_2_temperature', value: 'null' }],
        },
      },
      SV_Mix2S_Mixer_minimum_present_temperature: {
        conditions: {
          hidden: [{ key: 'CD_Mixer_2_temperature', value: 'null' }],
        },
      },
      SV_Mix2S_Mixer_maximum_present_temperature: {
        conditions: {
          hidden: [{ key: 'CD_Mixer_2_temperature', value: 'null' }],
        },
      },
      SV_Mix2S_Valve_full_opening_time: {
        conditions: {
          hidden: [{ key: 'CD_Mixer_2_temperature', value: 'null' }],
        },
      },
      SV_Mix2S_Pump_off_by_thermostat: {
        conditions: {
          hidden: [{ key: 'CD_Mixer_2_temperature', value: 'null' }],
        },
      },
      SV_Mix2S_Mixer_input_dead_zone: {},
      SV_Mix2S_Proportional_coefficient: {},
      SV_Mix2S_Integration_time_constant: {},
    },
    outputH: {
      SV_OutH_OutH: {},
      SV_OutH_Reserve_boiler_deactivation_temperature: {},
    },
    systemSettings: {
      SV_Restore_factory_settings: {
        unit: 'command',
        editable: true,
        key: 'SV_Restore_factory_settings',
        valueType: 'Command',
        value: 'restore_factory_settings',
      },
      SS_Clear_service_counters: {
        unit: 'command',
        editable: true,
        key: 'ssClearServiceCounters',
        valueType: 'Command',
        value: 'clear_service_counters',
      },
      SS_Grate_blockade: {},
      SS_Flame_retract_feeding_time: {},
      SS_Clear_alarms: {
        unit: 'command',
        editable: true,
        key: 'SS_Clear_alarms',
        valueType: 'Command',
        value: 'clear_alarms',
      },
      SS_Alarm_exhaust_temperature: {},
      SS_Flame_retract_timer: {},
      SS_Alarm_flame_retract: {},
      SS_Fan_type: {},
      SS_Hall_sensor: {},
      SS_Minimum_PRM: {
        conditions: {
          hidden: [{ key: 'SS_Hall_sensor', value: 'yes', notEqual: true }],
        },
      },
      SS_Maximum_PRM: {
        conditions: {
          hidden: [{ key: 'SS_Hall_sensor', value: 'yes', notEqual: true }],
        },
      },
      reset_alarm: {
        unit: 'command',
        editable: true,
        key: 'resetAlarm',
        valueType: 'Command',
        value: 'reset_alarm',
        conditions: {
          hidden: [{ key: 'Devices_model', value: 'plum_ecomax_920' }],
        },
      },
    },
    emergencyMode: {
      EM_EM: {},
      EM_Exhaust_sensor: {},
      EM_Reset_security: {
        unit: 'command',
        editable: true,
        key: 'emResetSecurity',
        valueType: 'Command',
        value: 'reset_security',
        parameters: {},
      },
    },
    manualControl: {
      MC_OutH: {
        conditions: manualParamsConditions,
      },
      MC_igniter: {
        conditions: manualParamsConditions,
      },
      MC_circulating_pump: { conditions: manualParamsConditions },
      MC_DHW_pump: { conditions: manualParamsConditions },
      MC_boiler_pump: { conditions: manualParamsConditions },
      MC_feeder: { conditions: manualParamsConditions },
      MC_fan: { conditions: manualParamsConditions },
      MC_mixer1_pump: { conditions: manualParamsConditions },
      MC_mixer2_pump: { conditions: manualParamsConditions },
      MC_mixer1_open_valve: { conditions: manualParamsConditions },
      MC_mixer2_open_valve: { conditions: manualParamsConditions },
      MC_mixer1_close_valve: { conditions: manualParamsConditions },
      MC_mixer2_close_valve: { conditions: manualParamsConditions },
      MC_fan_power: { conditions: manualParamsConditions },
    },
    // schema: {
    //   D_State_OutH: {
    //     // conditions: {
    //     // disabled: [{ key: 'CD_Boiler_operation_mode', value: 'turned_on' }],
    //     // },
    //   },
    // D_State_igniter: {},
    //   D_State_circulating_pump: {},
    //   D_State_DHW_pump: {},
    //   D_State_boiler_pump: {},
    //   D_State_feeder: {},
    //   D_State_fan: {},
    //   D_State_mixer1_pump: {},
    //   D_State_mixer2_pump: {},
    //   D_State_mixer1_open_valve: {},
    //   D_State_mixer2_open_valve: {},
    //   D_State_mixer1_close_valve: {},
    //   D_State_mixer2_close_valve: {},
    // },
    // lambdaProbe: {
    //   SV_Lambda_probe_Airflow_adjustment_in_FL: {},
    //   SV_Lambda_probe_Operating_LAMBDA_probe: {},
    // },
  },
};

export const formateParameters = (parameters: any[]): any => {
  const result: any = {};
  const parametersByKey: { [key: string]: any } = {};

  parameters.forEach((item: any) => {
    parametersByKey[item.key] = { ...item };
  });

  const formate = (item: any, path: string): void => {
    const list = Object.entries(item);

    list.forEach(([name, childs]) => {
      const _path = `${path}.${name}`;
      const _parameter = parametersByKey[name];
      const _value = _parameter || null;

      if (_isObject(childs)) {
        if (_value) {
          _set(result, _path, _value);
        }

        formate(childs, _path);
      } else {
        _set(result, _path, childs);
      }
    });
  };

  formate(structure, 'data');

  return camelize.camel(result.data);
};
