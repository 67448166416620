import { AxiosResponse } from 'axios';
import request from 'api/request';
import { SendCommandRequest, SendCommandResponse } from './types';

const sendCommand = ({
  uid,
  command,
}: SendCommandRequest): Promise<AxiosResponse<SendCommandResponse>> =>
  request.POST<SendCommandResponse>({
    url: `/devices/${uid}/commands`,
    params: {
      commandId: command,
    },
  });

export default {
  sendCommand,
};
