import React from 'react';
import styled from 'styled-components';
import { Formik, FormikHelpers } from 'formik';

import { useTranslation } from 'react-i18next';

import FormWrapper from 'components/FormWrapper';
import Paper from 'components/Interfaces/Paper';
import Typography from 'components/Interfaces/Typography';
import Button from 'components/Interfaces/NewButton';
import InputField from 'components/InputField';

import { UserUpdateRequest } from 'api/user/types';
import validationSchema from './validationSchema';

type Props = {
  isSent: boolean;
  error: string | null;
  onSubmit: (
    values: UserUpdateRequest,
    actions: FormikHelpers<UserUpdateRequest>,
  ) => void;
  onContinue: () => void;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
`;

const ChangePassword: React.FC<Props> = ({
  isSent,
  error,
  onSubmit,
  onContinue,
}) => {
  const { t } = useTranslation();

  if (isSent) {
    return (
      <Wrapper>
        <Paper px={6} py={8} textAlign="center">
          <Typography variant="h5" mb={4}>
            {t('passwordIsChanged')}
          </Typography>
          <Button color="primary" onClick={onContinue}>
            {t('continue')}
          </Button>
        </Paper>
      </Wrapper>
    );
  }

  return (
    <FormWrapper title={t('changingPassword')} icon="lockOpen" error={error}>
      <Formik
        initialValues={{
          password: '',
          passwordConfirmation: '',
          currentPassword: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(props): React.ReactElement => (
          <form onSubmit={props.handleSubmit}>
            <InputField
              label={t('newPassword')}
              name="password"
              type="password"
              mt="34px"
              mb="12px"
            />
            <InputField
              label={t('confirmNewPassword')}
              name="passwordConfirmation"
              type="password"
              mt="16px"
              mb="12px"
            />
            <InputField
              label={t('currentPassword')}
              name="currentPassword"
              type="password"
              mt="16px"
              mb="12px"
            />
            <Button
              width="100%"
              type="submit"
              color="primary"
              mt="20px"
              disabled={props.isSubmitting}
            >
              {t('changePassword')}
            </Button>
          </form>
        )}
      </Formik>
    </FormWrapper>
  );
};

export default ChangePassword;
