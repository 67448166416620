import React, { ReactNode, memo } from 'react';

import * as Styled from './PageWrapper.styled';

type Props = {
  children: ReactNode;
};

const PageWrapper: React.FC<Props> = ({ children }) => (
  <Styled.Root>{children}</Styled.Root>
);

export default memo(PageWrapper);
