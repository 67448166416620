import React from 'react';
import { useSelector } from 'react-redux';
import { rolesSelector } from '@redux/user/selectors';

type Props = {
  children: any;
  role: 'customer' | 'service_engineer';
};

const UserRole: React.FC<Props> = ({ role, children }) => {
  const roles = useSelector(rolesSelector);

  return roles.includes(role) && children;
};

export default UserRole;
