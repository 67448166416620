import styled from 'styled-components/macro';
import theme from 'styles/theme';

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 450px;
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IconSuccess = styled.div`
  svg {
    width: 60px;
    height: 60px;
    color: ${theme.palette.success.main};
  }
`;

export const IconFailure = styled.div`
  svg {
    width: 60px;
    height: 60px;
    color: ${theme.palette.error.main};
  }
`;
