import React, { useCallback } from 'react';
import ProfileButton from './ProfileButton';
import { SignOutActions } from '../../@redux/application/actions';
import { useDispatch } from 'react-redux';

const ProfileButtonContainer: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();

  const handleSignOut = useCallback(() => {
    dispatch({ type: SignOutActions.REQUESTED });
  }, []);

  const handleMenuClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <ProfileButton
      anchorEl={anchorEl}
      signOut={handleSignOut}
      onClose={handleClose}
      onMenuClick={handleMenuClick}
    />
  );
};

export default ProfileButtonContainer;
