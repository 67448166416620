import React from 'react';
import { Helmet } from 'react-helmet';
import Modals from '../NewModal/ModalContainer';

interface Props {
  title: string;
  children: React.ReactNode;
}

const Page: React.FC<Props> = (props: Props) => {
  return (
    <>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      <Modals>{props.children}</Modals>
    </>
  );
};

export default React.memo(Page);
