import React, { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';

import Button from 'components/Interfaces/NewButton';
import { SendCommandActions } from '@redux/commands/actions';
import ModalContext from 'context/ModalContext';
import BaseModal from '../BaseModal';
import * as Styled from './CommandValueModal.styled';

type Props = {
  data: any;
};

const CommandValueModal: React.FC<Props> = ({ data }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const { closeModal } = useContext(ModalContext);

  const handleApply = useCallback(() => {
    closeModal();
    dispatch({
      type: SendCommandActions.REQUESTED,
      payload: {
        uid: id,
        command: data.value,
      },
    });
  }, [data, id, dispatch]);

  return (
    <BaseModal onClose={closeModal}>
      <Styled.Root>
        <Styled.Body>
          <Styled.Title>{t(`parameters.${camelCase(data.key)}`)}</Styled.Title>
        </Styled.Body>
        <Styled.Buttons>
          <Button gray onClick={closeModal}>
            {t('cancel')}
          </Button>
          <Button onClick={handleApply}>{t('apply')}</Button>
        </Styled.Buttons>
      </Styled.Root>
    </BaseModal>
  );
};

export default CommandValueModal;
