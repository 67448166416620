import styled from 'styled-components/macro';

import Header from 'components/Header';

export const Root = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const Body = styled.div`
  position: relative;
  flex: 1 auto;
  background: #f2f2f2;
  overflow-y: scroll;
`;

export const DashboardHeader = styled(Header)<{ open?: boolean }>`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
`;
