import React, { memo } from 'react';

import { Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

type Props = {
  isOpen: boolean;
  message: string;
  onClose: (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string | undefined,
  ) => void;
};

const SnackBar: React.FC<Props> = ({ isOpen, message, onClose }) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={isOpen}
      autoHideDuration={6000}
      onClose={onClose}
      message={message}
      action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );
};

export default memo(SnackBar);
