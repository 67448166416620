import React, { useCallback, useState } from 'react';
import moment from 'moment';
import HelpIcon from '@material-ui/icons/Help';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
} from '@material-ui/core';

import { Pagination } from '@material-ui/lab';

import { Alarm } from '@redux/alarms/interfaces';
import Popper from '@material-ui/core/Popper';

type Props = {
  items: Alarm[];
  page: number;
  pageCount: number | null;
  onPageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
};

const getItemNumber = (page: number, i: number, perPage = 10): number =>
  (page - 1) * perPage + i + 1;

const Alarms: React.FC<Props> = ({ items, page, pageCount, onPageChange }) => {
  const classes = useStyles();
  const [[selected, anchorEl], setSelected] = useState<any>([null, null]);
  const { t } = useTranslation();

  const handleEnter = useCallback((item, event) => {
    setSelected([item, event.currentTarget]);
  }, []);

  const handleLeave = useCallback(() => {
    setSelected([null, null]);
  }, [anchorEl]);

  return (
    <Box width="100%" pt="40px">
      {selected && (
        <Popper
          placement="left-start"
          className={classes.popper}
          id={selected.uid}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
        >
          <div className={classes.paper}>{t(`alarmHints.${selected.key}`)}</div>
        </Popper>
      )}
      <TableContainer
        component={Paper}
        elevation={0}
        className={classes.tableContainer}
        square
      >
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell align="right">{t('number')}</TableCell>
              <TableCell align="right">{t('alarm')}</TableCell>
              <TableCell align="right">{t('accidentStart')}</TableCell>
              <TableCell align="right">{t('accidentEnd')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, i) => (
              <TableRow key={item.uid}>
                <TableCell align="right">
                  <span>{getItemNumber(page, i)}</span>
                </TableCell>
                <TableCell align="right">
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Box marginRight="10px">{t(`alarmItems.${item.key}`)}</Box>
                    <HelpIcon
                      fontSize="small"
                      color="disabled"
                      onMouseEnter={(event: any): void =>
                        handleEnter(item, event)
                      }
                      onMouseLeave={handleLeave}
                    />
                  </Box>
                </TableCell>
                <TableCell align="right">
                  {item.from
                    ? moment(item.from).utc().format('DD.MM.YYYY HH:mm')
                    : t('alarmContinue')}
                </TableCell>
                <TableCell align="right">
                  {item.to
                    ? moment(item.to).utc().format('DD.MM.YYYY HH:mm')
                    : t('alarmContinue')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={4}>
                {pageCount !== null && pageCount > 1 && (
                  <Box display="flex" justifyContent="center" my="10px">
                    <Pagination
                      count={pageCount}
                      page={page}
                      onChange={onPageChange}
                    />
                  </Box>
                )}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Alarms;
