import React, { useContext, useEffect } from 'react';

import * as Styled from './CombinatedTemperature.styled';

import { useTranslation } from 'react-i18next';
import ParameterContext from '../../../../context/ParameterContext';
import Value from '../../../Commons/Value/Value';
import BaseParameter from '../BaseParameter';

type Props = {
  unit: string;
  valueKey: string;
  icon?: string;
  parameters: any;
  modalType: string;
  editable: boolean;
};

const CombinedTemperature: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [first, second] = Object.values(props.parameters);
  const { onSetParameter } = useContext(ParameterContext);

  useEffect(() => {
    onSetParameter(first.key);
  }, [first]);

  return (
    <BaseParameter {...props} editable={props.editable} editKey={first.key}>
      <Styled.Root>
        <div>
          <div>
            <Styled.Title>{t('setTemp')}</Styled.Title>
            <Value
              type="number"
              size="small"
              value={first.value}
              defaultValue={t('noData')}
              sup="°C"
            />
            <Styled.Separator />
          </div>
          <div>
            <Styled.Title>{t('currTemp')}</Styled.Title>
            <Value
              type="number"
              size="medium"
              value={second.value}
              defaultValue={t('noData')}
              sup="°C"
            />
          </div>
        </div>
      </Styled.Root>
    </BaseParameter>
  );
};

export default CombinedTemperature;
