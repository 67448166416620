import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { goBack } from 'connected-react-router';

import { Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

type Props = {
  onClick?: () => void;
};

const BackButton: React.FC<Props> = ({ onClick }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleBack = useCallback(() => {
    dispatch(goBack());
  }, [dispatch]);

  return (
    <Button
      startIcon={<ArrowBackIcon />}
      color="inherit"
      disableRipple={true}
      disableFocusRipple={true}
      onClick={onClick || handleBack}
    >
      {t('back')}
    </Button>
  );
};

export default React.memo(BackButton);
