import React, { memo, useContext, useEffect } from 'react';
import { Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { useParams } from 'react-router';

import PrivateRoute from 'components/PrivateRoute';

import useDeviceChannel from 'hooks/commons/useDeviceChannel';
import useAlarmsRequester from 'hooks/commons/useAlarmsRequester';
import { CustomRouteProps } from 'constants/routes';

import * as Styled from './Dashboard.styled';
import Sidebar from '../../components/NewSidebar';
import { getSectionName } from '../../components/TopBar/utils';
import { useSelector } from 'react-redux';
import { deviceSettingsSelector } from '../../@redux/deviceSettings/selectors';
import ModalContext from '../../context/ModalContext';

type Props = {
  routes: CustomRouteProps[];
  isDrawerOpen: boolean;
};

const Dashboard: React.FC<Props> = ({ routes, isDrawerOpen }) => {
  const { path } = useRouteMatch();
  let { id } = useParams();
  const { pathname } = useLocation();
  const title = getSectionName(pathname, id);
  const deviceSetting = useSelector(deviceSettingsSelector);
  const { openModal } = useContext(ModalContext);

  useDeviceChannel(id);
  useAlarmsRequester(id);

  useEffect(() => {
    if (deviceSetting) {
      if (deviceSetting.deviceSettings.softwareUpdatingOn) {
        openModal('hardWareUpdateModal', {});
      }
    }
  }, [deviceSetting]);

  return (
    <Styled.Root>
      <Sidebar />
      <Styled.Body>
        <Styled.DashboardHeader
          open={isDrawerOpen}
          title={title}
          devices
          lang
          profile
        />
        <Switch>
          {routes.map((route: any, i: number) => (
            <PrivateRoute key={i} {...route} path={`${path}${route.path}`} />
          ))}
        </Switch>
      </Styled.Body>
    </Styled.Root>
  );
};

export default memo(Dashboard);
