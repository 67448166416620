import { AxiosResponse } from 'axios';
import request from 'api/request';

import {
  SignInResponse,
  SignUpResponse,
  SignInRequest,
  SignUpRequest,
  ConfirmRequest,
} from './types';

const signIn = (data: SignInRequest): Promise<AxiosResponse<SignInResponse>> =>
  request.POST<SignInResponse>({
    url: '/users/signin',
    data,
  });

const signUp = (data: SignUpRequest): Promise<AxiosResponse<SignUpResponse>> =>
  request.POST<SignUpResponse>({
    url: '/users/signup',
    data: {
      email: data.email,
      password: data.password,
      password_confirmation: data.confirmPassword,
    },
  });

const getCurrentUser = (): Promise<AxiosResponse<SignUpResponse>> =>
  request.GET<SignUpResponse>({
    url: '/users/current',
  });

const confirmUser = (
  token: ConfirmRequest,
): Promise<AxiosResponse<SignInResponse>> =>
  request.POST<SignInResponse>({
    url: '/users/confirm',
    data: { confirmation_token: token },
  });

const signOut = (): Promise<AxiosResponse<SignUpResponse>> =>
  request.DELETE<SignUpResponse>({
    url: '/users/signout',
  });

const demoSignIn = (): Promise<AxiosResponse<SignInResponse>> =>
  request.GET<SignInResponse>({
    url: '/users/demo',
  });

export default {
  signIn,
  signUp,
  getCurrentUser,
  confirmUser,
  signOut,
  demoSignIn,
};
