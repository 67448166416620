import * as Yup from 'yup';
import { formErrors } from 'constants/errors';

const { requiredField } = formErrors;

export default Yup.object().shape({
  title: Yup.string().required(requiredField),
  serviceLevel: Yup.string().required(requiredField),
  serviceInterval: Yup.string().required(requiredField),
});
