import React, { memo, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  IconButton,
  FormControl,
  InputLabel,
  TextField,
  InputAdornment,
  Fade,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

import useStyles from './styles';
import styled from 'styled-components';

type Props = {
  search: string;
  isCloseBtnVisible: boolean;
  onChange: (event: any) => void;
  onSubmit: (event: any) => void;
  onClear: () => void;
};

const StyledInput = styled(TextField)`
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #ffe632;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #000;
  }
`;

const Search: React.FC<Props> = ({
  search,
  isCloseBtnVisible,
  onChange,
  onSubmit,
  onClear,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const clearAndFocus = useCallback(() => {
    onClear();
    inputRef.current.focus();
  }, [inputRef, onClear]);

  return (
    <FormControl className={classes.root} size="small" variant="outlined">
      <StyledInput
        type="text"
        value={search}
        variant="outlined"
        label={t('searchByUidOrTitle')}
        inputRef={inputRef}
        onChange={onChange}
        onKeyPress={(event): void => {
          if (event.key === 'Enter') {
            onSubmit(event);
            event.preventDefault();
          }
        }}
        InputProps={{
          labelWidth: 220,
          endAdornment: (
            <>
              <InputAdornment position="end">
                <Fade in={isCloseBtnVisible}>
                  <IconButton
                    className={classes.inputButton}
                    onClick={clearAndFocus}
                    edge="end"
                  >
                    <ClearIcon />
                  </IconButton>
                </Fade>
              </InputAdornment>
              <InputAdornment position="end">
                <IconButton
                  className={classes.inputButton}
                  disabled={search === ''}
                  onClick={onSubmit}
                  edge="end"
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            </>
          ),
        }}
      />
    </FormControl>
  );
};

export default memo(Search);
