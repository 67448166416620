import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ParamButton from './ParamButton';

import { Box } from '@material-ui/core';

import { UpdateHistoryParamsActions } from '@redux/history/actions';
import { selectedParamsSelector } from '@redux/history/selectors';

import colors from 'constants/chartColors';

type Props = {
  params: string[];
};

const Parameters: React.FC<Props> = ({ params }) => {
  const dispatch = useDispatch();
  const selectedParams = useSelector(selectedParamsSelector);

  const handleCheckBox = useCallback(
    (param: string, value: boolean) => {
      dispatch({
        type: UpdateHistoryParamsActions.UPDATE_SELECTED_PARAMS,
        payload: { param, value },
      });
    },
    [dispatch],
  );

  return (
    <Box display="flex" flexWrap="wrap" width="100%" marginTop="35px">
      {params.map((param, index) => (
        <ParamButton
          key={index}
          paramName={param}
          selected={selectedParams[param] === true}
          selectedColor={colors[param]}
          onChange={handleCheckBox}
        />
      ))}
    </Box>
  );
};

export default Parameters;
