import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import PageWrapper from 'components/PageWrapper';
import Typography from 'components/Interfaces/Typography';
import Button from 'components/Interfaces/NewButton';
import Paper from 'components/Interfaces/Paper';

import { Box, Link } from '@material-ui/core';

import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import * as Styled from './Step.styled';

type Props = {
  step: number;
  text?: string;
  maxStep: number;
  isCurrent: boolean;
  children?: any;
  onStepChange: (action: string, step: number) => void;
};

const Step: React.FC<Props> = ({
  step,
  text,
  isCurrent,
  children,
  onStepChange,
  maxStep,
}) => {
  const { t } = useTranslation();

  const handleGoToPrevStep = useCallback(() => {
    onStepChange('prev', step);
  }, [onStepChange, step]);

  const handleGoToNextStep = useCallback(() => {
    onStepChange('next', step);
  }, [onStepChange, step]);

  const handleSkip = useCallback(() => {
    onStepChange('exact', maxStep);
  }, [onStepChange, maxStep]);

  if (!isCurrent) {
    return null;
  }

  if (children) {
    return (
      <Styled.LastStep>
        {children}
        <Box mt="20px" width="386px" minWidth="270px">
          <Link
            component="button"
            color="textSecondary"
            onClick={handleGoToPrevStep}
          >
            <Typography color="textPrimary" variant="body1">
              {t('backToInstruction')}
            </Typography>
          </Link>
        </Box>
      </Styled.LastStep>
    );
  }

  return (
    <PageWrapper>
      <Styled.Root>
        <Paper
          p={5}
          position="relative"
          width="550px"
          height="200px"
          style={{ boxShadow: 'none' }}
        >
          <Typography variant="h5" mb="30px">
            {t('step')} {step}
          </Typography>
          <Typography
            mb={5}
            variant="body1"
            dangerouslySetInnerHTML={{ __html: text || '' }}
          />

          <Styled.ButtonWrapper>
            <Box display="flex" alignItems="center" mr={3}>
              <Link
                component="button"
                color="textSecondary"
                onClick={handleSkip}
              >
                <Typography variant="body1">{t('skip')}</Typography>
              </Link>
            </Box>
            <Box display="flex">
              {step !== 1 && (
                <Button
                  color="text"
                  variant="contained"
                  startIcon={<ArrowLeftIcon />}
                  mr={2}
                  onClick={handleGoToPrevStep}
                >
                  {t('previous')}
                </Button>
              )}
              {step !== maxStep && (
                <Button
                  color="primary"
                  variant="contained"
                  endIcon={<ArrowRightIcon />}
                  onClick={handleGoToNextStep}
                >
                  {t('next')}
                </Button>
              )}
            </Box>
          </Styled.ButtonWrapper>
        </Paper>
      </Styled.Root>
    </PageWrapper>
  );
};

export default memo(Step);
