import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Page from 'components/Page';
import Progress from 'components/Interfaces/Progress';
import History from './History';

import { FetchHistoryActions } from '@redux/history/actions';

import { CustomRouteComponentProps } from 'constants/routes';
import { useDispatch, useSelector } from 'react-redux';

import {
  formattedHistoryDataSelector,
  formattedHistoryDataModeSelector,
  isHistoryPendingSelector,
  graphParamsSelector,
} from '@redux/history/selectors';

const HistoryContainer: React.FC<CustomRouteComponentProps> = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const graphData = useSelector(formattedHistoryDataSelector);
  const graphModeData = useSelector(formattedHistoryDataModeSelector);

  const graphParams = useSelector(graphParamsSelector);
  const isPending = useSelector(isHistoryPendingSelector);

  const handleFetchHistory = useCallback(() => {
    dispatch({
      type: FetchHistoryActions.REQUESTED,
      payload: { id },
    });
  }, [dispatch, id]);

  useEffect(() => {
    handleFetchHistory();
  }, [handleFetchHistory]);

  return (
    <Page title={props.title}>
      <Progress visible={isPending}>
        <History
          fetchData={handleFetchHistory}
          graphData={graphData}
          graphModeData={graphModeData}
          params={graphParams}
        />
      </Progress>
    </Page>
  );
};

export default HistoryContainer;
