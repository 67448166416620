import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    button: {
      backgroundColor: '#ebebeb',
    },
    isActive: {
      backgroundColor: 'rgba(63, 81, 181, 0.04)',
    },
  }),
);
