import React from 'react';
import { useField } from 'formik';
import styled from 'styled-components/macro';
import { spacing } from '@material-ui/system';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { SpacingProps } from '@material-ui/system';
import FormHelperText from '@material-ui/core/FormHelperText';

type Props = {
  items: { id: string; title: string }[];
  label: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
};

const StyledFormControl = styled(FormControl)`
  && {
    ${spacing}

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #ffe632;
    }

    .MuiFormLabel-root.Mui-focused {
      color: #000;
    }

    .MuiFormLabel-root {
      position: absolute;
      left: 15px;
      top: -5px;
    }
  }
`;

const SelectField: React.FC<Props & SpacingProps> = ({
  name,
  label,
  items,
  disabled,
  required,
  ...props
}) => {
  const [field, meta] = useField(name);

  const labelId = name + '-label';
  const error = meta.touched && meta.error ? meta.error : null;

  return (
    <StyledFormControl error={error !== null} fullWidth {...props}>
      <InputLabel id={labelId} required={required}>
        {label}
      </InputLabel>
      <Select
        {...field}
        name={name}
        label={label}
        labelId={labelId}
        variant="outlined"
        disabled={disabled}
        error={meta.touched && Boolean(error)}
      >
        {items.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </StyledFormControl>
  );
};

export default React.memo(SelectField);
