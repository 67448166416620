import React, { memo } from 'react';
import { useRouteMatch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Page from 'components/Page';
import { isAuthUserSelector } from '@redux/user/selectors';

const NotFound: React.FC = () => {
  const { url } = useRouteMatch();
  const isAuth = useSelector(isAuthUserSelector);

  if (url === '/') {
    if (isAuth) {
      return <Redirect to="/device" />;
    } else {
      return <Redirect to="/signin" />;
    }
  }

  return (
    <Page title="Page not found">
      <div>404: Page not found</div>
    </Page>
  );
};

export default memo(NotFound);
