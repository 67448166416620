import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import dataHistoryApi from 'api/dataHistory';
import { FetchHistoryActions, ExportActions } from './actions';

import { FetchHistoryActionType } from './interfaces';

import { dateFromSelector, dateToSelector, filtredItemsSelector } from '@redux/history/selectors';
import exportToCsv from './utils/exportToCsv';

function* fetchDataHistorySaga({
  payload,
}: FetchHistoryActionType): SagaIterator<void> {
  try {
    const dateFrom = yield select(dateFromSelector);
    const dateTo = yield select(dateToSelector);
    const { data } = yield call(dataHistoryApi.fetchHistory, {
      ...payload,
      dateFrom,
      dateTo,
    });

    yield put({
      type: FetchHistoryActions.FULFILLED,
      payload: data,
    });
  } catch (e) {
    yield put({ type: FetchHistoryActions.REJECTED });
  }
}

function* exportCsvSaga() {
  const data = yield select(filtredItemsSelector);

  exportToCsv(data);
}

function* dataHistorySaga(): SagaIterator<void> {
  yield all([
    takeLatest(FetchHistoryActions.REQUESTED, fetchDataHistorySaga),
    takeLatest(ExportActions.EXPORT_CSV, exportCsvSaga)
  ]);
}

export default dataHistorySaga;
