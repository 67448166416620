import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

export const Root = styled(NavLink)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  cursor: pointer;
  text-decoration: none;
  color: black;

  &:hover {
    background: #ffe632;
  }

  &.activeLink {
    outline: none;
    background: #ffe632;
    pointer-events: none;
  }
`;

export const Body = styled.span`
  display: flex;
  align-items: center;
  padding-left: 24px;
  flex-shrink: 0;
`;

export const Title = styled.span`
  padding-left: 22px;
`;
