import styled from 'styled-components/macro';

type ContentProps = {
  inner?: boolean;
};

export const Content = styled.div<ContentProps>`
  background: ${({ inner }): any => (inner ? '#f4f4f4' : '')};
`;

export const InnerList = styled.div`
  margin-bottom: 10px;
`;
