import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 500px;
  min-height: 240px;
`;

export const Form = styled.form`
  width: 100%;
  margin-top: 30px;
`;

export const Caption = styled.p`
  padding: 20px 0 10px 0;
  font-size: 14px;
`;
