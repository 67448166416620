import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Controls from './Controls';
import HistoryChart from './HistoryChart';
import Parameters from './Parameters';

import { Box, Typography } from '@material-ui/core';

type Props = {
  params: any;
  graphData: { name: string; type: string; data: [] }[];
  graphModeData: { name: string; type: string; data: [] };
  fetchData: () => void;
};

const isEmptyObject = (obj: Object): boolean =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

const History: React.FC<Props> = ({
  graphData,
  graphModeData,
  fetchData,
  params,
}) => {
  const { t } = useTranslation();

  const chartRef = useRef<any>();
  const chartModeRef = useRef<any>();

  const handleExportPngChart = () => {
    if (chartRef.current) {
      const { chart } = chartRef.current;
      chart.exportChart({ sourceWidth: chart.chartWidth });
    }
  };

  const handleExportPngChartMode = () => {
    if (chartModeRef.current) {
      const { chart } = chartModeRef.current;
      chart.exportChart({ sourceWidth: chart.chartWidth });
    }
  };

  return (
    <Box width="100%" pt="50px">
      <Box pl="80px" pr="80px">
        <Controls
          createChart={fetchData}
          onExportPngChart={handleExportPngChart}
          onExportPngChartMode={handleExportPngChartMode}
        />
        {isEmptyObject(graphData) ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="320px"
          >
            <Typography variant="body1">{t('noAvailableData')}</Typography>
          </Box>
        ) : (
          <>
            <HistoryChart
              chartRef={chartRef}
              chartModeRef={chartModeRef}
              data={graphData}
              modeData={graphModeData}
            />
            <Parameters params={params} />
          </>
        )}
      </Box>
    </Box>
  );
};

export default History;
