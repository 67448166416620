import React, { useCallback, useEffect, memo } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';

import { CustomRouteComponentProps } from 'constants/routes';
import { ConfirmUserActions } from '@redux/user/actions';

import Page from 'components/Page';

const ConfirmEmail: React.FC<CustomRouteComponentProps> = (
  props: CustomRouteComponentProps,
) => {
  const dispatch = useDispatch();

  const getToken = useCallback(() => {
    const parsed = queryString.parse(props.location.search);
    return parsed.confirmation_token;
  }, [props.location.search]);

  const getUserByToken = useCallback(() => {
    dispatch({
      type: ConfirmUserActions.REQUESTED,
      payload: getToken(),
    });
  }, [dispatch, getToken]);

  useEffect(() => {
    getUserByToken();
  }, [getUserByToken]);

  return <Page title={props.title}>temp</Page>;
};

export default memo(ConfirmEmail);
