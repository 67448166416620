import React from 'react';
import { useField } from 'formik';

import { TextFieldProps } from '@material-ui/core/TextField';
import { spacing, SpacingProps } from '@material-ui/system';

import Input from 'components/Interfaces/Input';
import styled from 'styled-components/macro';

type Props = {
  name: string;
};

const StyledInput = styled(Input)`
  && {
    ${spacing}
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #ffe632;
    }

    .MuiFormLabel-root.Mui-focused {
      color: #000;
    }
  }
`;

const InputField: React.FC<Props & TextFieldProps & SpacingProps> = (props) => {
  const [field, meta] = useField(props.name);
  const { touched, error } = meta;

  return (
    <StyledInput
      {...props}
      {...field}
      fullWidth
      variant="outlined"
      helperText={touched && error ? error : ''}
      error={touched && Boolean(error)}
    />
  );
};

export default React.memo(InputField);
