import React from 'react';
import { Route } from 'react-router-dom';
import { CustomRouteProps } from 'constants/routes';
import { useTranslation } from 'react-i18next';

const PublicRoute: React.FC<CustomRouteProps> = (route: CustomRouteProps) => {
  const { t } = useTranslation();

  const RouteComponent = React.useCallback(
    (props) => {
      return (
        route.component && (
          <route.component
            title={t(route.title)}
            routes={route.routes}
            {...props}
          />
        )
      );
    },
    [route, t],
  );

  return <Route path={route.path} render={RouteComponent} />;
};

export default React.memo(PublicRoute);
