import React from 'react';
import { useTranslation } from 'react-i18next';
import icons from './icons';
import * as Styled from './SidebarItem.styled';
import { useRouteMatch } from 'react-router-dom';

type Props = {
  name: string;
  path: string;
};

const SidebarItem: React.FC<Props> = ({ name, path }) => {
  const Icon = icons[name] as any;
  const { url } = useRouteMatch();
  const { t } = useTranslation();

  return (
    <Styled.Root to={`${url}${path}`} exact activeClassName="activeLink">
      <Styled.Body>
        <Icon />
        <Styled.Title>{t(name)}</Styled.Title>
      </Styled.Body>
    </Styled.Root>
  );
};

export default SidebarItem;
