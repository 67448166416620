import React from 'react';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import * as Styled from './BaseModal.styled';

type Props = {
  onClose: () => void;
  closeButton?: boolean;
};

const BaseModal: React.FC<Props> = ({ children, closeButton, onClose }) => {
  return (
    <Styled.Root>
      {closeButton && (
        <Styled.Close onClick={onClose}>
          <CloseIcon />
        </Styled.Close>
      )}
      <Styled.Body>{children}</Styled.Body>
    </Styled.Root>
  );
};

BaseModal.defaultProps = {
  closeButton: true,
};

export default BaseModal;
