import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    root: {
      width: '395px',
    },
    input: {
      borderColor: '#ffe632',
      color: '#000',
    },
    inputButton: {
      padding: '6px',
      '& svg': {
        transition: theme.transitions.create('color', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      '&:hover': {
        backgroundColor: 'transparent',
        '& svg': {
          color: theme.palette.grey[800],
        },
      },
    },
  }),
);
