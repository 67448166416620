import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SignOutActions } from '@redux/application/actions';
import { serviceIntervalSelector } from '@redux/deviceSettings/selectors';

export default (): void => {
  const timerId = useRef<any>(0);
  const dispatch = useDispatch();
  const serviceInterval = useSelector(serviceIntervalSelector);

  const startTimer = useCallback(() => {
    if (serviceInterval === null) {
      return null;
    }

    const timeout = 1000 * 60 * serviceInterval;

    timerId.current = setTimeout(() => {
      dispatch({ type: SignOutActions.REQUESTED });
    }, timeout);
  }, [serviceInterval, dispatch]);

  const stopTimer = useCallback(() => {
    clearTimeout(timerId.current);
  }, []);

  const handleFocus = useCallback(() => {
    stopTimer();
  }, [stopTimer]);

  const handleBlur = useCallback(() => {
    startTimer();
  }, [startTimer]);

  useEffect(() => {
    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return (): void => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, [handleFocus, handleBlur]);
};
