import axios, { AxiosRequestConfig, AxiosPromise, AxiosResponse } from 'axios';
import camelize from 'utils/camelize';
import config from 'config';

const { baseUrl } = config();

axios.defaults.withCredentials = true;
axios.defaults.baseURL = baseUrl;

export enum METHODS {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
  HEAD = 'HEAD',
  DELETE = 'DELETE',
}

export type Options = {
  url: string;
  data?: any;
  params?: any;
  stub?: any;
  stubErrors?: any;
};

export type CustomAxiosRequestConfig = {
  httpMethod: METHODS;
} & Options &
  AxiosRequestConfig;

const DELAY = 1000;

const sleep = (ms: number): AxiosPromise<void> =>
  new Promise((resolve): ReturnType<any> => setTimeout(resolve, ms));

const doRequest = (opts: CustomAxiosRequestConfig): AxiosPromise<any> => {
  const optsFormated = opts;

  if (opts.data) {
    optsFormated.data = camelize.uncamel(opts.data);
  }

  if (opts.params) {
    optsFormated.params = camelize.uncamel(opts.params);
  }

  return axios({
    method: opts.httpMethod,
    headers: {
      ...opts.headers,
      'X-Api-Version': 1,
    },
    ...optsFormated,
  }).then((result: any) => ({ ...result, data: camelize.camel(result.data) }));
};

const stubRequest = (opts: CustomAxiosRequestConfig): Promise<any> => {
  return Promise.all([
    new Promise((resolve, reject) => {
      if (opts.stubErrors) {
        reject({
          response: {
            data: {
              error: { details: opts.stubErrors },
            },
          },
        });
      }

      resolve({ data: opts.stub });
    }),
    sleep(DELAY),
  ]).then((result) => result[0]);
};

const request = <T>(
  opts: CustomAxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  return opts.stub ? stubRequest(opts) : doRequest(opts);
};

export default {
  GET: <T>(options: Options): Promise<AxiosResponse<T>> =>
    request<T>({ ...options, httpMethod: METHODS.GET }),

  POST: <T>(options: Options): Promise<AxiosResponse<T>> =>
    request({ ...options, httpMethod: METHODS.POST }),

  PATCH: <T>(options: Options): Promise<AxiosResponse<T>> =>
    request({ ...options, httpMethod: METHODS.PATCH }),

  PUT: <T>(options: Options): Promise<AxiosResponse<T>> =>
    request({ ...options, httpMethod: METHODS.PUT }),

  HEAD: <T>(options: Options): Promise<AxiosResponse<T>> =>
    request({ ...options, httpMethod: METHODS.HEAD }),

  DELETE: <T>(options: Options): Promise<AxiosResponse<T>> =>
    request({ ...options, httpMethod: METHODS.DELETE }),
};
