import { all } from 'redux-saga/effects';

import applicationSaga from './application/sagas';
import parametersSaga from './parameters/sagas';
import factoriesSaga from './factories/sagas';
import userSaga from './user/sagas';
import devicesSaga from './devices/sagas';
import deviceSettingsSaga from './deviceSettings/sagas';
import alarmsSaga from './alarms/sagas';
import historySaga from './history/sagas';
import commandsSaga from './commands/sagas';
import loadersSaga from './loaders/sagas';

export default function* rootSaga(): any {
  yield all([
    loadersSaga(),
    applicationSaga(),
    parametersSaga(),
    factoriesSaga(),
    userSaga(),
    devicesSaga(),
    deviceSettingsSaga(),
    alarmsSaga(),
    historySaga(),
    commandsSaga(),
  ]);
}
