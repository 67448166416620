import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import config from 'config';

import Page from 'components/Page';
import SocketProvider from 'components/SocketProvider';
import ParamSettingModal from 'components/Modal/ParamSettingModal';
import Dashboard from './Dashboard';

import { userSelector } from '@redux/user/selectors';
import { FetchDeviceSettingsActions } from '@redux/deviceSettings/actions';
import { CustomRouteComponentProps } from 'constants/routes';

const { wsUrl } = config();

const DashboardContainer: React.FC<CustomRouteComponentProps> = (
  props: CustomRouteComponentProps,
) => {
  const { id } = useParams();
  const currentUser = useSelector(userSelector);
  const dispatch = useDispatch();

  const socketConnectionUrl = useMemo(() => {
    return `${wsUrl}/cable?auth_token=${currentUser.authToken}&uid=${id}`;
  }, [id, currentUser.authToken]);

  useEffect(() => {
    if (id) {
      dispatch({ type: FetchDeviceSettingsActions.REQUESTED, payload: { id } });
    }
  }, [dispatch, id]);

  return (
    <SocketProvider url={socketConnectionUrl}>
      <Page title={props.title}>
        <Dashboard
          routes={props.routes ? props.routes : []}
          isDrawerOpen={true}
        />
        <ParamSettingModal />
      </Page>
    </SocketProvider>
  );
};

export default DashboardContainer;
