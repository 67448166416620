import React from 'react';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { Box } from '@material-ui/core';

import { selectedParamsSelector } from '@redux/history/selectors';

import format from 'date-fns/format';

import { camelCase } from 'lodash';
import { postfix } from './utils';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

type Props = {
  data: { name: string; type: string; data: [] }[];
  modeData: { name: string; type: string; data: [] };
  chartRef: any;
  chartModeRef: any;
};

const HistoryChart: React.FC<Props> = ({ data, modeData, chartRef, chartModeRef }) => {
  const selectedParams = useSelector(selectedParamsSelector);
  const { t } = useTranslation();

  const currentLocale = i18next.language;
  const isEnLocale = currentLocale === 'en';
  const dateFormatString = isEnLocale
    ? 'MM-dd-yyyy hh:mm a'
    : 'dd.MM.yyyy HH:mm';

  const commonOptions = {
    time: {
      timezoneOffset: new Date().getTimezoneOffset(),
    },
    xAxis: {
      type: 'datetime',
      labels: {
        formatter: function (this: any) {
          const time = format(this.value, "HH:mm");

          return `${format(this.value, "dd.MM.yy")}<br/>${time === "00:00" ? "" : time}`
        },
      },
      tickPixelInterval: 150
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    }
  }

  const options = {
    ...commonOptions,
    chart: {
      marginLeft: 50,
    },
    title: {
      text: null,
    },
    yAxis: {
      title: false,
      lineWidth: 1,
    },
    series: data.map(item => ({ ...item, visible: selectedParams[item.name] || false })),
    tooltip: {
      formatter: function (this: any): any {
        const value = this.series.name === 'controllerActivity' ? t('stateParameters.connected') : this.y;

        return `
          ${format(this.x, dateFormatString)}<br/>
          <b>${t(`parameters.${this.series.name}`)}</b><br/>
          ${value}${postfix[this.series.name] || ''}
        `;
      },
    },
  };

  const modeOptions = {
    ...commonOptions,
    title: {
      text: null,
    },
    chart: {
      type: 'area',
      height: 100,
      marginLeft: 50,
    },
    yAxis: {
      max: 1,
      min: 0,
      visible: false,
    },
    tooltip: {
      formatter: function (this: any): any {
        return `
          ${format(this.x, dateFormatString)}<br/>
          <b>${t(`boilerOperationMode`)}</b><br/>
          ${t(`stateParameters.${camelCase(this.series.name)}`)}
        `;
      },
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: modeData,
  };

  return (
    <>
      <Box position="relative" height="400px">
        <Box position="absolute" width="100%">
          <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} />
        </Box>
      </Box>
      {selectedParams.cdBoilerOperationMode && (
        <Box position="relative" height="100px">
          <Box position="absolute" width="100%">
            <HighchartsReact ref={chartModeRef} highcharts={Highcharts} options={modeOptions} />
          </Box>
        </Box>
      )}
    </>
  );
};

export default HistoryChart;
