import styled, { css } from 'styled-components/macro';

import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';

type Props = {
  fixed?: boolean;
};

export const Root = styled.div<Props>`
  position: relative;
  width: 100%;
  height: 78px;
  background: #ffe632;
  z-index: 101;

  ${(props) =>
    props.fixed &&
    css`
      position: fixed;
      left: 0;
      top: 0;
    `}
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 32px;
  margin-right: 62px;
  height: 100%;
`;

export const Items = styled.div`
  margin-left: 10px;

  && > * {
    &:not(:first-child) {
      margin-left: 15px;
    }
  }
`;

export const Logo = styled(LogoIcon)`
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translate3d(0, -50%, 0);
`;

export const LogoCenter = styled(Logo)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);

  @media (max-width: 1440px) {
    display: none;
  }
`;
