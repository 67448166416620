export const UpdateAlarmsAction = '@devices/UPDATE_ALARMS';

export enum FetchAlarmsActions {
  REQUESTED = '@devices/FETCH_ALARMS_REQUESTED',
  FULFILLED = '@devices/FETCH_ALARMS_FULFILLED',
  REJECTED = '@devices/FETCH_ALARMS_REJECTED',
}

export enum UpdateAlarmsActions {
  UPDATE = '@devices/ALARM_UPDATE',
}
