import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import PercentParameter from './PercentParameter';
import TempParameter from './TempParameter';
import StateParameter from './StateParameter';
import CombinedTemperatureNew from './CombinedTemperature/CombinedTemperatureNew';
import BoilerTemperatureParameter from './Custom/BoilerTemperatureParameter';
import RoomOneTemperatureParameter from './Custom/RoomOneTemperatureParameter';
import BoilerOperationModeParameter from './Custom/BoilerOperationModeParameter';
import useCondition from 'hooks/commons/useCondition';
import { serviceLevelSelector } from '@redux/deviceSettings/selectors';
import { isCustomerSelector } from '../../../@redux/user/selectors';
import CustomFuelLevel from './Custom/CustomFuelLevel';

type Props = {
  type: string;
  unit: 'degrees_сelcius' | 'percents' | 'combined';
  value: string;
  editable: boolean;
  minValue: string;
  maxValue: string;
  valueKey: string;
  visible?: string;
  hiddenIsNull?: boolean;
  parameters?: any;
  conditions?: any;
  modal?: any;
  advancedUserOnly?: boolean;
  isAdvancedUser?: boolean;
};

const Cards: React.FC<Props> = (props) => {
  const parameter: any = {
    percents: PercentParameter,
    degrees_celcius: TempParameter,
    combinedTemperature: CombinedTemperatureNew,
    boilerTemperature: BoilerTemperatureParameter,
    roomOneTemperatureParameter: RoomOneTemperatureParameter,
    cdBoilerOperationMode: BoilerOperationModeParameter,
    cdFuelLevel: CustomFuelLevel,
  };

  const serviceLevel = useSelector(serviceLevelSelector);
  const conditionHidden = useCondition(get(props, 'conditions.hidden'));
  const conditionDisabled = useCondition(get(props, 'conditions.disabled'));
  const isCustomer = useSelector(isCustomerSelector);
  const disabled = serviceLevel !== 'edit';

  if (
    conditionHidden ||
    (props.advancedUserOnly && !props.isAdvancedUser) ||
    props.unit === undefined
  ) {
    return null;
  }

  return React.createElement(parameter[props.unit] || StateParameter, {
    editable: !conditionDisabled || props.editable,
    disabled: !isCustomer && disabled,
    ...props,
  });
};

export default Cards;
