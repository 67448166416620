import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import sidebar from 'constants/sidebar';
import { deviceSettingsSelector } from '@redux/deviceSettings/selectors';
import { ReactComponent as ArrowImage } from 'assets/icons/arrowLeft.svg';
import SidebarItem from './SidebarItem';
import * as Styled from './Sidebar.styled';
import { rolesSelector } from '../../@redux/user/selectors';
import Progress from '../Interfaces/Progress';

const Sidebar: React.FC = () => {
  const [isSmall, setSmall] = useState(false);
  const data = useSelector(deviceSettingsSelector);
  const roles = useSelector(rolesSelector);

  const handleClick = useCallback(() => {
    setSmall((state) => !state);
  }, [setSmall]);

  let filterSidebar = sidebar;

  if (
    data &&
    roles.includes('customer') &&
    !data.deviceSettings.customerRoles.includes('customer_service')
  ) {
    filterSidebar = sidebar.filter((item) => item.name !== 'serviceSettings');
  }

  return (
    <Styled.Root isSmall={isSmall}>
      <Progress visible={!data}>
        <Styled.Header>
          <Styled.ArrowButton isSmall={isSmall} onClick={handleClick}>
            <ArrowImage />
          </Styled.ArrowButton>
        </Styled.Header>
        <Styled.Body>
          {!isSmall && <Styled.Logo />}
          {filterSidebar.map((item) => (
            <SidebarItem key={item.name} {...item} />
          ))}
        </Styled.Body>
      </Progress>
    </Styled.Root>
  );
};

export default Sidebar;
