export enum AddDeviceActions {
  REQUESTED = '@devices/ADD_DEVICE_REQUESTED',
  FULFILLED = '@devices/ADD_DEVICE_FULFILLED',
  REJECTED = '@devices/ADD_DEVICE_REJECTED',
}

export enum FetchDevicesActions {
  REQUESTED = '@devices/FETCH_DEVICES_REQUESTED',
  FULFILLED = '@devices/FETCH_DEVICES_FULFILLED',
  REJECTED = '@devices/FETCH_DEVICES_REJECTED',
}

export enum DeleteDeviceActions {
  REQUESTED = '@devices/DELETE_DEVICE_REQUESTED',
  FULFILLED = '@devices/DELETE_DEVICE_FULFILLED',
  REJECTED = '@devices/DELETE_DEVICE_REJECTED',
}

export enum UpdateDeviceParameterActions {
  REQUESTED = '@devices/UPDATE_DEVICE_PARAMETER_REQUESTED',
  FULFILLED = '@devices/UPDATE_DEVICE_PARAMETER_FULFILLED',
  REJECTED = '@devices/UPDATE_DEVICE_PARAMETER_REJECTED',
}
