import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography, TextField } from '@material-ui/core';
import Avatar from 'components/Interfaces/Avatar';
import AccountCircle from '@material-ui/icons/AccountCircle';

import * as Styled from './Account.styled';
import { Link } from 'react-router-dom';
import Button from '../../components/Interfaces/NewButton';

type Props = {
  email: string;
  isDemo: boolean;
};

const Account: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <Styled.Root>
      <Styled.Wrapper>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mb="34px"
        >
          <Avatar bgcolor="#212121" mb="8px">
            <AccountCircle />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('myAccount')}
          </Typography>
        </Box>
        <TextField
          id="standard-required"
          label={t('currentEmail')}
          defaultValue={props.email}
          disabled
          fullWidth
        />
        {!props.isDemo && (
          <>
            <Button mt="12px" to="/change-email" component={Link}>
              {t('changeEmail')}
            </Button>
            <Button mt="12px" to="/change-password" component={Link}>
              {t('changePassword')}
            </Button>
          </>
        )}
      </Styled.Wrapper>
    </Styled.Root>
  );
};

export default Account;
