import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@redux/store';
import { ParametersState } from './types';
import _pick from 'lodash/pick';

import { formateParameters } from 'constants/parameters/categories';

import camelize from 'utils/camelize';

const rootSelector = (state: RootState): ParametersState => state.parameters;

export const parametersSelector = createSelector(rootSelector, (parameters) =>
  formateParameters(Object.values(parameters.items)),
);

export const parameterByNameSelector = createSelector(
  rootSelector,
  (_: any, name: string) => name,
  (parameters, name) => camelize.camel(parameters.items[name]),
);

export const parametersByNameSelector = createSelector(
  rootSelector,
  (_: any, names: string[]) => names,
  (parameters, names) => camelize.camel(_pick(parameters.items, names)),
);

export const originalParametersSelector = createSelector(
  rootSelector,
  (parameters) => parameters.items as any,
);

export const isPendingParametersSelector = createSelector(
  rootSelector,
  (parameters) => parameters.isPending,
);

export const parametersCamelCaseItemsSelector = createSelector(
  rootSelector,
  ({ items }) => camelize.camel(items),
);
