import i18n from 'i18n';

export interface FormErrorsType {
	[key: string]: string;
}

export interface ServerErrorsType {
	[key: string]: { [key: string]: string };
}

export const formErrors: FormErrorsType = {
	requiredField: i18n.t('errors.required'),
	emailNotConfirmed: i18n.t('errors.emailNotConfirmed'),
	invalidCredentials: i18n.t('errors.invalidCredentials'),
	invalidEmail: i18n.t('errors.invalidEmail'),
	invalidCurrentPassword: i18n.t('errors.invalidCurrentPassword'),
	passwordTooShort: i18n.t('errors.passwordTooShort'),
	passwordCharacters: i18n.t('errors.passwordCharacters'),
	passwordsNotMatch: i18n.t('errors.passwordsNotMatch'),
	somethingWentWrong: i18n.t('errors.somethingWentWrong')
};

export const serverErrors: ServerErrorsType = {
	password: {
		invalid: i18n.t('errors.invalidEmailOrPassword'),
		notMatch: i18n.t('errors.passwordsNotMatch'),
		contentInvalid: 'Passwords must contain at least six letters'
	},
	email: {
		taken: i18n.t('errors.emailTaken'),
		invalid: i18n.t('errors.invalidEmail')
	},
	device: {
		notFound: i18n.t('errors.deviceNotFound')
	}
};
