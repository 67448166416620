import colors from 'constants/chartColors';

const getFormattedValue = (value: string): any => {
  if (value === 'connected') {
    return 1;
  } else {
    return Number(value);
  }
};

const formatData = (data: any, name: any): any => {
  const isBoolean = name === 'controllerActivity';

  return {
    type: 'line',
    name,
    data: data[name].map((el: any) => [
      new Date(el.time).getTime(),
      getFormattedValue(el.value),
    ]),
    color: colors[name],
    marker: {
      enabled: isBoolean,
      symbol: 'circle',
    },
    ...(isBoolean
      ? {
          lineWidth: 0,
          states: {
            hover: {
              lineWidthPlus: 0,
            },
          },
        }
      : {}),
  };
};

export const formatChartData = (data: any): any => {
  return Object.keys(data).map((key) => formatData(data, key));
};

interface ChartData {
  cdBoilerOperationMode?: { value: string; time: string }[];
}

export const formatChartDataMode = (data: ChartData): any => {
  const enumData = data.cdBoilerOperationMode ? data.cdBoilerOperationMode : [];
  const keys = [...new Set(enumData.map(({ value }) => value))];

  const result = keys.map((key) => ({
    name: key,
    data: enumData.map((point, index) => [
      new Date(point.time).getTime(),
      point.value === key || (index !== 0 && enumData[index - 1].value === key)
        ? 1
        : null,
    ]),
    marker: {
      enabled: false,
      symbol: 'circle',
    },
  }));

  return result;
};
