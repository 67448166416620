import React, { useCallback, useContext, useState } from 'react';
import { Switch } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';

import ModalContext from 'context/ModalContext';
import useMemoSelector from 'hooks/useMemoSelector';
import BaseModal from '../BaseModal';
import * as Styled from './SwitchValueModal.styled';
import { parameterByNameSelector } from '@redux/parameters/selectors';

import Button from 'components/Interfaces/NewButton';
import { UpdateDeviceParameterActions } from '@redux/devices/actions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

type Props = {
  data: any;
};

const SwitchValueModal: React.FC<Props> = ({ data }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const { closeModal } = useContext(ModalContext);
  const { key, value } = useMemoSelector(parameterByNameSelector, data.key);
  const [changedValue, setValue] = useState(value);

  const handleSave = useCallback(() => {
    closeModal();
    dispatch({
      type: UpdateDeviceParameterActions.REQUESTED,
      payload: { uid: id, key, value: changedValue },
    });
  }, [id, key, changedValue, dispatch]);

  const handleChange = useCallback(
    (event, value) => {
      setValue(value);
    },
    [setValue],
  );

  return (
    <BaseModal onClose={closeModal}>
      <Styled.Root>
        <Styled.Body>
          <Styled.Title>{t(`parameters.${camelCase(data.key)}`)}</Styled.Title>
          <div>
            <span>{t('off')}</span>
            <Switch checked={changedValue} onChange={handleChange} />
            <span>{t('on')}</span>
          </div>
        </Styled.Body>
        <Styled.Buttons>
          <Button gray onClick={closeModal}>
            {t('cancel')}
          </Button>
          <Button onClick={handleSave}>{t('save')}</Button>
        </Styled.Buttons>
      </Styled.Root>
    </BaseModal>
  );
};

export default SwitchValueModal;
