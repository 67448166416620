import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';

import { Box, Menu, MenuItem, Divider } from '@material-ui/core';

import { userMenuButtons, UserMenuButtonProps } from 'constants/menus';

type Props = {
  anchorEl: Element | ((element: Element) => Element) | null;
  onMenuClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onClose: () => void;
  signOut: () => void;
};

const ProfileButton: React.FC<Props> = ({
  anchorEl,
  onMenuClick,
  onClose,
  signOut,
}) => {
  const { t } = useTranslation();
  const open = Boolean(anchorEl);

  return (
    <Box>
      <div style={{ cursor: 'pointer' }} onClick={onMenuClick}>
        <ProfileIcon />
      </div>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={onClose}
      >
        {userMenuButtons.map((item: UserMenuButtonProps, index) => (
          <MenuItem key={index} component={Link} to={item.to} onClick={onClose}>
            {t(item.title)}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem onClick={signOut}>{t('signOut')}</MenuItem>
      </Menu>
    </Box>
  );
};

export default React.memo(ProfileButton);
