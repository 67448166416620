import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import Typography from 'components/Interfaces/Typography';
import Button from 'components/Interfaces/NewButton';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import * as Styled from './ResultMessages.styled';

type Props = {
  resultView: string;
  successMessage: string;
  onButtonClick: () => void;
};

const ResultMessages: React.FC<Props> = ({
  resultView,
  successMessage,
  onButtonClick,
}) => {
  const { t } = useTranslation();

  return (
    <Styled.Root>
      <Styled.Wrapper>
        <>
          {resultView === 'success' ? (
            <Styled.IconSuccess>
              <CheckCircleIcon />
            </Styled.IconSuccess>
          ) : (
            <Styled.IconFailure>
              <ErrorOutlineIcon />
            </Styled.IconFailure>
          )}
          <Typography variant="h5" mt="30px">
            {resultView === 'success'
              ? t(successMessage)
              : t('errors.somethingWentWrong')}
          </Typography>
          <Button
            mt="48px"
            color="primary"
            variant="contained"
            onClick={onButtonClick}
          >
            {t('continue')}
          </Button>
        </>
      </Styled.Wrapper>
    </Styled.Root>
  );
};

export default memo(ResultMessages);
