import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import ModalContext from 'context/ModalContext';
import Button from 'components/Interfaces/NewButton';
import BaseModal from '../BaseModal';
import * as Styled from './WarningModal.styled';

type Props = {
  data: any;
};

const WarningModal: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const { closeModal } = useContext(ModalContext);

  return (
    <BaseModal onClose={closeModal}>
      <Styled.Root>
        <Styled.Body>
          <Styled.Title>{t(data.text)}</Styled.Title>
        </Styled.Body>
        <Styled.Buttons>
          <Button onClick={closeModal}>{t('okay')}</Button>
        </Styled.Buttons>
      </Styled.Root>
    </BaseModal>
  );
};

export default WarningModal;
