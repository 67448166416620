import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomRouteComponentProps } from 'constants/routes';

import Page from 'components/Page';
import Typography from 'components/Interfaces/Typography';

import * as Styled from './PrivacyPolicy.styled';
import Header from 'components/Header';

const PrivacyPolicy: React.FC<CustomRouteComponentProps> = (
  props: CustomRouteComponentProps,
) => {
  const { t } = useTranslation();

  return (
    <Page title={props.title}>
      <Header title="privacyPolicy" lang back />
      <Styled.Text>
        <Typography
          mb={3}
          dangerouslySetInnerHTML={{ __html: t('files:privacy') }}
        />
      </Styled.Text>
    </Page>
  );
};

export default memo(PrivacyPolicy);
