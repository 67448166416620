import React from 'react';
import { Typography, Select as SelectComponent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import getSelectOptions from '../../../../../utils/getSelectOptions';

type Props = {
  value: any;
  enum: string[];
  onChange: (value: string) => void;
};

// const useStyles = makeStyles(() => ({
//   root: {
//     width: 500,
//     marginTop: 20,
//   },
//   input: {
//     width: '100%',
//   },
// }));

const Command: React.FC<Props> = (props) => {
  // const classes = useStyles();
  // const selectOptions = getSelectOptions(options);

  // const handleChange = React.useCallback(
  //   (e) => {
  //     onChange(e.target.value);
  //   },
  //   [onChange],
  // );

  return null;
};

export default React.memo(Command);
