import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import commonEn from './locales/en/common.json';
import commonRu from './locales/ru/common.json';
import privacyRu from './locales/ru/privacy';
import privacyEn from './locales/en/privacy';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    keySeparator: '.',
    debug: true,
    resources: {
      en: {
        common: commonEn,
        files: {
          privacy: privacyEn,
        },
      },
      ru: {
        common: commonRu,
        files: {
          privacy: privacyRu,
        },
      },
    },
    ns: ['common'],
    defaultNS: 'common',
    interpolation: { escapeValue: false },
  });

export default i18n;
