import { all, call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { push } from 'connected-react-router';

import authAPI from 'api/auth';
import { CurrentUserActions, ConfirmUserActions } from './actions';
import { ModalActions } from '@redux/modal/actions';

import { ConfirmActionType } from './interfaces';

function* getCurrentUserSaga(): SagaIterator<void> {
  try {
    const { data } = yield call(authAPI.getCurrentUser);

    yield put({ type: CurrentUserActions.FULFILLED, payload: data });
  } catch (e) {
    yield put({ type: CurrentUserActions.REJECTED });
    yield put(push('/signin'));
  }
}

function* confirmUserSaga({ payload }: ConfirmActionType): SagaIterator<void> {
  try {
    const { data } = yield call(authAPI.confirmUser, payload);

    yield put({ type: ConfirmUserActions.FULFILLED, payload: data });
    yield put(push('/add-device'));
    yield put({ type: ModalActions.OPEN });
  } catch (e) {
    yield put({ type: ConfirmUserActions.REJECTED });
    yield put(push('/signin'));
    yield put({ type: ModalActions.OPEN });
  }
}

function* userSagas(): SagaIterator<void> {
  yield all([
    takeLatest(CurrentUserActions.REQUESTED, getCurrentUserSaga),
    takeLatest(ConfirmUserActions.REQUESTED, confirmUserSaga),
  ]);
}

export default userSagas;
