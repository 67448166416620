import styled from 'styled-components/macro';

export const Text = styled.div`
  padding: 120px 80px;
  background-color: #fff;

  h1,
  h2,
  h3 {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    padding: 28px 0;
  }

  ul {
    margin: 14px 0;
  }

  li {
    padding-left: 20px;
  }
`;
