import useMemoSelector from '../useMemoSelector';
import { parametersByNameSelector } from '../../@redux/parameters/selectors';

export default (conditions: any[], defaultValue?: boolean): boolean => {
  if (conditions === undefined) {
    return defaultValue !== undefined ? defaultValue : false;
  }

  const keys = conditions.map(({ key }) => key);
  const parameters = Object.values<any>(
    useMemoSelector(parametersByNameSelector, keys),
  );

  const params = useMemoSelector(parametersByNameSelector, ['Devices_model']);

  for (let i = 0; i < parameters.length; i++) {
    const parameter = parameters[i];
    const condition = conditions[i];

    if (condition.notEqual) {
      return String(parameter.value) !== String(condition.value);
    }

    if (String(parameter.value) === String(condition.value)) {
      return true;
    }
  }

  return false;
};
