import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@redux/store';
import { FactoriesState } from './types';

const rootSelector = (state: RootState): FactoriesState => state.factories;

export const factoryItemsSelector = createSelector(
  rootSelector,
  (factories) => factories.items,
);

export const isFactoriesPendingSelector = createSelector(
  rootSelector,
  (factories) => factories.isPending,
);
