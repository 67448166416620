import styled from 'styled-components/macro';

export const Grid = styled.div`
  padding: 35px 25px;
`;

export const EmptyContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 80px);
`;

export const EmptyDesctiption = styled.div`
  padding-top: 10px;
  font-size: 16px;
`;
