import { styled } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import {
  compose,
  spacing,
  typography,
  sizing,
  positions,
} from '@material-ui/system';

export default styled(Paper)(compose(spacing, typography, sizing, positions));
