import styled from 'styled-components/macro';

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 386px;
  min-width: 270px;

  && {
    .MuiInputBase-root {
      &:before {
        display: none;
      }
    }
  }
`;
