import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ConnectionType } from 'constants/addDeviceInstructionTypes';

import PageWrapper from 'components/PageWrapper';
import Typography from 'components/Interfaces/Typography';
import Button from 'components/Interfaces/NewButton';
import Paper from 'components/Interfaces/Paper';

import { Box } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import * as Styled from './Step.styled';

type Props = {
  step: number;
  isCurrent: boolean;
  onStepChange: (action: string, step: number) => void;
  onConnectionTypeChange: (type: ConnectionType) => void;
};

const FirstStep: React.FC<Props> = ({
  step,
  isCurrent,
  onStepChange,
  onConnectionTypeChange,
}) => {
  const { t } = useTranslation();

  const handleGoToNextStep = useCallback(() => {
    onStepChange('next', step);
  }, [onStepChange, step]);

  const selectWifiConnection = useCallback(() => {
    onConnectionTypeChange(ConnectionType.WIFI);
    handleGoToNextStep();
  }, [handleGoToNextStep, onConnectionTypeChange]);

  const selectCableConnection = useCallback(() => {
    onConnectionTypeChange(ConnectionType.CABLE);
    handleGoToNextStep();
  }, [handleGoToNextStep, onConnectionTypeChange]);

  if (!isCurrent) {
    return null;
  }

  return (
    <PageWrapper>
      <Styled.Root>
        <Paper
          p={5}
          position="relative"
          width="550px"
          height="200px"
          style={{ boxShadow: 'none' }}
        >
          <Typography variant="h5" mb="30px">
            {t('step')} {step}
          </Typography>
          <Typography variant="body1" mb={5}>
            {t('instructionPowerup')}
          </Typography>
          <Styled.ButtonWrapper>
            <Box display="flex" />
            <Box display="flex">
              <Button
                color="primary"
                variant="contained"
                endIcon={<ArrowRightIcon />}
                onClick={selectWifiConnection}
                mr={2}
              >
                {t('useWifiConnection')}
              </Button>
              <Button
                color="primary"
                variant="contained"
                endIcon={<ArrowRightIcon />}
                onClick={selectCableConnection}
              >
                {t('useCableConnection')}
              </Button>
            </Box>
          </Styled.ButtonWrapper>
        </Paper>
      </Styled.Root>
    </PageWrapper>
  );
};

export default memo(FirstStep);
