import { createSlice } from '@reduxjs/toolkit';

import { FetchFactoriesActions } from './actions';
import { FactoriesState } from './types';

const initialState: FactoriesState = {
  items: [],
  isPending: false,
};

const factories = createSlice({
  name: 'factories',
  initialState,
  reducers: {},
  extraReducers: {
    [FetchFactoriesActions.REQUESTED]: (state): void => {
      state.isPending = true;
    },
    [FetchFactoriesActions.FULFILLED]: (state, action): void => {
      state.items = action.payload;
      state.isPending = false;
    },
    [FetchFactoriesActions.REJECTED]: (state): void => {
      state.isPending = false;
    },
  },
});

const { reducer } = factories;

export default reducer;
