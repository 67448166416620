import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { combineReducers } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import applications from './application';
import parameters from './parameters';
import factories from './factories';
import user from './user';
import devices from './devices';
import deviceSettings from './deviceSettings';
import modal from './modal';
import paramModal from './paramModal';
import alarms from './alarms';
import dataHistory from './history';
import loaders from './loaders';

import sagas from '@redux/sagas';

const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory();

const store = configureStore({
  reducer: combineReducers({
    router: connectRouter(history),
    applications,
    parameters,
    factories,
    user,
    devices,
    deviceSettings,
    modal,
    paramModal,
    alarms,
    dataHistory,
    loaders,
  }),
  middleware: [
    ...getDefaultMiddleware(),
    routerMiddleware(history),
    sagaMiddleware,
  ],
  devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(sagas);

export type RootState = ReturnType<typeof store.getState>;

export default store;
