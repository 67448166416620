import styled, { css } from 'styled-components/macro';

type Props = {
  editable?: boolean;
};

export const Root = styled.div<Props>`
  position: relative;
  height: 310px;
  min-width: 244px;
  background: #fff;
  cursor: pointer;
  user-select: none;
  text-align: center;

  ${(props) =>
    props.editable === false &&
    css`
      cursor: default;
      pointer-events: none;
    `}
`;

export const Body = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 100px;
  left: 0;
  width: 100%;
`;

export const Title = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  width: 100%;
  height: 74px;
  font-size: 14px;
  padding: 0 20px;
  box-sizing: border-box;
  font-weight: 600;
  background: #e5e5e5;
`;

export const Icon = styled.div`
  position: absolute;
  top: 22px;
  left: 38px;
`;

export const HiddenButton = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
  pointer-events: all;
`;
