import styled from "styled-components/macro";
import { spacing, palette } from "@material-ui/system";
import Avatar from "@material-ui/core/Avatar";

export default styled(Avatar)<any>`
  && {
    ${spacing}
    ${palette}
  }
`;
