import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';

import ModalContext from 'context/ModalContext';
import BaseModal from '../BaseModal';
import * as Styled from './HardwareUpdate.styled';

type Props = {
  data: any;
};

const HardwareUpdate: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { closeModal } = useContext(ModalContext);

  return (
    <BaseModal onClose={closeModal} closeButton={false}>
      <Styled.Root>
        <Styled.Body>
          <Styled.Progress>
            <CircularProgress size={60} />
          </Styled.Progress>
          <Styled.Title>{t(`hardwareUpdating`)}</Styled.Title>
        </Styled.Body>
      </Styled.Root>
    </BaseModal>
  );
};

export default HardwareUpdate;
