export const getCurrentServiceInterval = (value: number): string => {
  switch (value) {
    case 3:
      return '3_hours';
    case 24:
      return '1_day';
    case 168:
      return '1_week';
    case 720:
      return '1_month';
    case null:
      return 'always';
    default:
      return '';
  }
};
