import React from 'react';

type Props = {
  openModal: (type: string, data?: any) => void;
  closeModal: () => void;
};

const ModalContext = React.createContext<Props>({
  openModal: () => {},
  closeModal: () => {},
});

export default ModalContext;
