import React from 'react';
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  userSelector,
  isCustomerSelector,
  rolesSelector,
} from '@redux/user/selectors';
import { CustomRouteProps } from 'constants/routes';

const PrivateRoute: React.FC<CustomRouteProps> = (route: CustomRouteProps) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const history = useHistory();

  const user = useSelector(userSelector);
  const roles = useSelector(rolesSelector);
  const isCustomer = useSelector(isCustomerSelector);

  if (route.role && !roles.includes(route.role)) {
    history.goBack();
    return null;
  }

  if (user.id === null) {
    return <Redirect to={{ pathname: '/signin' }} />;
  }

  if (
    isCustomer &&
    user.devicesUids.length === 0 &&
    pathname !== '/add-device'
  ) {
    return <Redirect to={{ pathname: '/add-device' }} />;
  }

  return (
    <Route
      path={route.path}
      render={(routeProps): any =>
        route.component && (
          <route.component
            title={t(route.title)}
            routes={route.routes}
            {...routeProps}
          />
        )
      }
    />
  );
};

export default React.memo(PrivateRoute);
