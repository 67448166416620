import styled from 'styled-components/macro';

export const Root = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 40px;
  width: 100%;

  button {
    margin-right: 12px;
    font-size: 12px;

    &:last-child {
      margin-right: 0;
    }
  }
`;
