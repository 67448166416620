import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import _find from 'lodash/find';

import DevicesMenuButton from './DevicesMenuButton';
import useDevicesList from 'hooks/commons/useDevicesList';

const DevicesMenuButtonContainer: React.FC = () => {
  const { id: uid } = useParams();
  const [isPending, devices] = useDevicesList();

  if (isPending) {
    return null;
  }

  const currentDevice = _find(devices, { uid });

  return <DevicesMenuButton current={currentDevice} devices={devices} />;
};

export default memo(DevicesMenuButtonContainer);
