import { all, call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import alarmsApi from 'api/alarms';
import { FetchAlarmsActions } from './actions';

import { FetchAlarmsActionType } from './interfaces';

function* fetchAlarmsSaga({
  payload,
}: FetchAlarmsActionType): SagaIterator<void> {
  try {
    const { data } = yield call(alarmsApi.fetchAlarms, payload);

    yield put({
      type: FetchAlarmsActions.FULFILLED,
      payload: data,
    });
  } catch (e) {
    yield put({ type: FetchAlarmsActions.REJECTED });
  }
}

function* alarmsSaga(): SagaIterator<void> {
  yield all([takeLatest(FetchAlarmsActions.REQUESTED, fetchAlarmsSaga)]);
}

export default alarmsSaga;
