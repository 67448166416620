import { all, call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import factoriesApi from 'api/factories';
import { FetchFactoriesActions } from './actions';

function* fetchFactoriesSaga(): SagaIterator<void> {
  try {
    const { data } = yield call(factoriesApi.fetch);

    yield put({
      type: FetchFactoriesActions.FULFILLED,
      payload: data.factories,
    });
  } catch (e) {
    yield put({ type: FetchFactoriesActions.REJECTED });
  }
}

function* factoriesSaga(): SagaIterator<void> {
  yield all([takeLatest(FetchFactoriesActions.REQUESTED, fetchFactoriesSaga)]);
}

export default factoriesSaga;
