import i18n from 'i18n';
import { camelCase } from 'lodash';

export default (value: any, unit: string, type: string): string => {
  if (value === null) {
    return i18n.t(`noData`);
  }

  switch (unit) {
    case 'boolean':
      return i18n.t(`stateParameters.${value}`);

    case 'days':
      return `${Number(value).toFixed(1)} ${i18n.t('units.d')}`;

    case 'hours':
      return `${Number(value).toFixed(1)} ${i18n.t('units.h')}`;

    case 'minutes':
      return `${Number(value).toFixed(1)} ${i18n.t('units.min')}`;

    case 'seconds':
      return `${Number(value).toFixed(1)} ${i18n.t('units.sec')}`;

    case 'degrees_celcius':
      return `${Number(value).toFixed(1)}°C`;

    case 'revolutions_per_minute':
      return `${value} rpm`;

    case 'percents':
      return `${Number(value).toFixed(1)}%`;

    case 'command':
      return ``;

    case null:
      if (type === 'Float' || type === 'Integer') {
        return Number(value).toFixed(1);
      } else {
        return i18n.t(`stateParameters.${camelCase(value)}`);
      }

    default:
      return Number(value).toFixed(1);
  }
};
