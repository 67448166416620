export enum CurrentUserActions {
  REQUESTED = '@user/GET_CURRENT_USER_REQUESTED',
  FULFILLED = '@user/GET_CURRENT_USER_FULFILLED',
  REJECTED = '@user/GET_CURRENT_USER_REJECTED',
}

export enum ConfirmUserActions {
  REQUESTED = '@user/CONFIRM_USER_REQUESTED',
  FULFILLED = '@user/CONFIRM_USER_FULFILLED',
  REJECTED = '@user/CONFIRM_USER_REJECTED',
}

export enum ResetPasswordActions {
  REQUESTED = '@user/RESET_PASSWORD_REQUESTED',
  FULFILLED = '@user/RESET_PASSWORD_FULFILLED',
  REJECTED = '@user/RESET_PASSWORD_REJECTED',
}
