import { createSlice } from '@reduxjs/toolkit';
import {
  SignInActions,
  SignUpActions,
  SignOutActions,
} from '@redux/application/actions';
import { ApplicationType } from './interfaces';

const initialState: ApplicationType = {
  isPending: false,
};

const application = createSlice({
  name: 'application',
  initialState,
  reducers: {},
  extraReducers: {
    [SignInActions.REQUESTED]: (state): void => {
      state.isPending = true;
    },
    [SignInActions.FULFILLED]: (state): void => {
      state.isPending = false;
    },
    [SignInActions.REJECTED]: (state): void => {
      state.isPending = false;
    },
    [SignUpActions.REQUESTED]: (state): void => {
      state.isPending = true;
    },
    [SignUpActions.FULFILLED]: (state): void => {
      state.isPending = false;
    },
    [SignUpActions.REJECTED]: (state): void => {
      state.isPending = false;
    },
    [SignOutActions.REQUESTED]: (state): void => {
      state.isPending = true;
    },
    [SignOutActions.FULFILLED]: (state): void => {
      state.isPending = false;
    },
    [SignOutActions.REJECTED]: (state): void => {
      state.isPending = false;
    },
  },
});

const { reducer } = application;

export default reducer;
