import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

import InputField from 'components/InputField';
import Button from 'components/Interfaces/NewButton';
import CheckboxField from 'components/Fields/CheckboxField';
import Link from 'components/Interfaces/Link';

import { Box, Typography } from '@material-ui/core';

import { SignUpRequest } from 'api/auth/types';

import validationSchema from './validationSchema';
import * as Styled from './SignUp.styled';

type Props = {
  isPending: boolean;
  onSubmit: (
    values: SignUpRequest,
    actions: FormikHelpers<SignUpRequest>,
  ) => void;
};

const SignUp: React.FC<Props> = ({ isPending, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        confirmPassword: '',
        privacyPolicy: false,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(props): React.ReactElement => (
        <Styled.Root onSubmit={props.handleSubmit}>
          <InputField
            label="Email"
            name="email"
            type="email"
            mt="16px"
            mb="12px"
          />
          <InputField
            label={t('password')}
            name="password"
            type="password"
            mt="16px"
            mb="12px"
          />
          <InputField
            label={t('confirmPassword')}
            name="confirmPassword"
            type="password"
            mt="16px"
            mb="12px"
          />
          <Box display="flex">
            <CheckboxField
              name="privacyPolicy"
              label={t('agreeWith')}
              fullWidth={false}
            />
            <Box position="relative" top="9px" left="-11px">
              <Link to="/privacy-policy">
                <Typography color="textSecondary" variant="body1">
                  {t('toPrivacyPolicy')}
                </Typography>
              </Link>
            </Box>
          </Box>
          <Button type="submit" color="primary" mt="20px" disabled={isPending}>
            {t('signUp')}
          </Button>
        </Styled.Root>
      )}
    </Formik>
  );
};

export default SignUp;
