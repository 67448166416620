import styled from 'styled-components/macro';

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 40px;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 80px);
`;

export const LastStep = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  & > div {
    height: auto;
  }
`;
