import styled from 'styled-components/macro';

export const Root = styled.div`
  .react-calendar__tile--active {
    background-color: #fce55a !important;
    color: #1a1a1a;
  }
`;
export const Label = styled.label`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  display: block;
`;
