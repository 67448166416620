import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Menu, MenuItem } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { languages, LanguagesTypes } from 'constants/menus';
import i18next from 'i18next';

const LanguageButton: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t, i18n } = useTranslation();
  const currentLocale = i18next.language;

  const handleMenuClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback(
    (lang) => {
      i18n.changeLanguage(lang.key).then(() => {});
      handleMenuClose();
    },
    [i18n, handleMenuClose],
  );

  const open = Boolean(anchorEl);

  return (
    <Box mr={2}>
      <Button
        aria-label="change language button"
        aria-controls="language-menu"
        aria-haspopup="true"
        color="inherit"
        disableRipple={true}
        disableFocusRipple={true}
        onClick={handleMenuClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {t(currentLocale)}
      </Button>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleMenuClose}
      >
        {languages.map((item: LanguagesTypes, index) => (
          <MenuItem key={index} onClick={(): void => handleClick(item)}>
            {t(item.key)}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default React.memo(LanguageButton);
