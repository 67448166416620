import { camelCase, snakeCase } from 'lodash';

interface TransformObject {
  [key: string]: any;
}

const camel = (obj: TransformObject): TransformObject => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camel(v));
  } else if (obj && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camel(obj[key]),
      }),
      {},
    );
  }
  return obj;
};

const uncamel = (obj: TransformObject): TransformObject => {
  if (Array.isArray(obj)) {
    return obj.map((v) => uncamel(v));
  } else if (obj && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [snakeCase(key)]: uncamel(obj[key]),
      }),
      {},
    );
  }
  return obj;
};

export default { camel, uncamel };
