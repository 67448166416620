import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import Page from 'components/Page';
import Progress from 'components/Interfaces/Progress';
import Alarms from './Alarms';

import { FetchAlarmsActions } from '@redux/alarms/actions';
import {
  alarmsSelector,
  numberOfPagesSelector,
  areAlarmsPendingSelector,
} from '@redux/alarms/selectors';
import { CustomRouteComponentProps } from 'constants/routes';

const sortAlarmsFunc = (a: any, b: any): number => {
  return new Date(b.from).getTime() - new Date(a.from).getTime();
};

const AlarmsContainer: React.FC<CustomRouteComponentProps> = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const alarms = useSelector(alarmsSelector);
  const areAlarmsPending = useSelector(areAlarmsPendingSelector);
  const numberOfPages = useSelector(numberOfPagesSelector);
  const [page, setPage] = useState(1);

  const handleFetchDevices = useCallback(
    (page) => {
      setPage(page);
      dispatch({
        type: FetchAlarmsActions.REQUESTED,
        payload: { id, page },
      });
    },
    [dispatch, id],
  );

  useEffect(() => {
    handleFetchDevices(page);
  }, [page, handleFetchDevices]);

  const handlePageChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      handleFetchDevices(value);
    },
    [handleFetchDevices],
  );

  return (
    <Page title={props.title}>
      <Progress visible={areAlarmsPending}>
        <Alarms
          items={alarms.slice().sort(sortAlarmsFunc)}
          page={page}
          pageCount={numberOfPages}
          onPageChange={handlePageChange}
        />
      </Progress>
    </Page>
  );
};

export default AlarmsContainer;
