import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@redux/store';
import { ParamModalType } from './interfaces';
import { originalParametersSelector } from '@redux/parameters/selectors';

const rootSelector = (state: RootState): ParamModalType => state.paramModal;

export const isParamModalOpenSelector = createSelector(
  rootSelector,
  ({ valueKey }) => Boolean(valueKey),
);

export const modalParamSelector = createSelector(
  [rootSelector, originalParametersSelector],
  ({ valueKey, parameter }: any, parameters) =>
    parameters[valueKey] || parameter,
);
