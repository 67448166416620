import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { Menu, MenuItem, Box } from '@material-ui/core';

import { ExportActions } from '@redux/history/actions';
import { isCdBoilerOperationModeActiveSelector } from '@redux/history/selectors';
import Button from '../../../components/Interfaces/NewButton';

type Props = {
  onExportPngChart: () => void;
  onExportPngChartMode: () => void;
};

const Export: React.FC<Props> = ({
  onExportPngChart,
  onExportPngChartMode,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOperationModeActive = useSelector(
    isCdBoilerOperationModeActiveSelector,
  );

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExportCSV = useCallback(() => {
    dispatch({
      type: ExportActions.EXPORT_CSV,
    });
  }, [dispatch]);

  return (
    <div>
      <Box marginTop="15px">
        <Button
          color="primary"
          size="small"
          aria-controls="export-menu"
          onClick={handleClick}
        >
          {t('downloadChart')}
        </Button>
      </Box>
      <Menu
        id="export-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleExportCSV}>{t('downloadCSV')}</MenuItem>
        <MenuItem onClick={onExportPngChart}>{t('downloadParamsPng')}</MenuItem>
        {isOperationModeActive && (
          <MenuItem onClick={onExportPngChartMode}>
            {t('downloadBoilerModePng')}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default Export;
