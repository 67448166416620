import { ReactComponent as CurrentDataIcon } from 'assets/icons/currentData.svg';
import { ReactComponent as UserParametersIcon } from 'assets/icons/userParameters.svg';
import { ReactComponent as StatIcon } from 'assets/icons/stat.svg';
import { ReactComponent as AlarmIcon } from 'assets/icons/alarm.svg';
import { ReactComponent as DeviceSettingsIcon } from 'assets/icons/deviceSettings.svg';
import { ReactComponent as ServiceParametersIcon } from 'assets/icons/serviceParameters.svg';

export default {
  currentData: CurrentDataIcon,
  userSettings: UserParametersIcon,
  serviceSettings: ServiceParametersIcon,
  history: StatIcon,
  alarms: AlarmIcon,
  deviceSettings: DeviceSettingsIcon,
} as any;
