import styled from 'styled-components/macro';

export const Root = styled.div`
  min-width: 572px;

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #ffe632;
  }
`;

export const Body = styled.div`
  margin-top: 30px;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 40px;
  width: 100%;

  button {
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }
`;
