import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

import androidQRCode from 'assets/qr_android_play.png';
import iosQRCode from 'assets/qr_app_store.png';

import ModalContext from 'context/ModalContext';
import Button from '../../Interfaces/NewButton';
import BaseModal from '../BaseModal';
import * as Styled from './HardwareAutosetting.styled';

type Props = {
  data: any;
};

const storeVariations = [
  { id: 0, title: 'Android (Google Play)', image: androidQRCode },
  { id: 1, title: 'iOS (App Store)', image: iosQRCode },
];

const HardwareAutosetting: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { closeModal } = useContext(ModalContext);

  const handleOkay = useCallback(() => {
    closeModal();
    dispatch(push(`/device/${data.deviceId}`));
  }, [closeModal, dispatch, data]);

  return (
    <BaseModal onClose={closeModal} closeButton={false}>
      <Styled.Root>
        <Styled.Body>
          <Styled.Title>{t(`hardwareAutosetting`)}</Styled.Title>

          <Styled.Codes>
            {storeVariations.map((store) => (
              <Styled.Code key={store.id}>
                <Styled.CodeTitle>{store.title}</Styled.CodeTitle>
                <Styled.CodeQR>
                  <Styled.CodeQRImage src={store.image} />
                </Styled.CodeQR>
              </Styled.Code>
            ))}
          </Styled.Codes>

          <Styled.Buttons>
            <Button onClick={handleOkay}>{t('okay')}</Button>
          </Styled.Buttons>
        </Styled.Body>
      </Styled.Root>
    </BaseModal>
  );
};

export default HardwareAutosetting;
