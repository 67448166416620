import { AxiosResponse } from 'axios';
import request from 'api/request';

import { FetchFactoryResponse } from './types';

const fetch = (): Promise<AxiosResponse<FetchFactoryResponse>> =>
  request.GET<FetchFactoryResponse>({
    url: '/factories',
  });

export default {
  fetch,
};
