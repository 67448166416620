import React from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';

import Typography from '../../../Interfaces/Typography/Typography';
import Button from '../../../Interfaces/Button/Button';

import Switch from './Switch';
import Select from './Select';
import Slider from './Slider';
import Command from './Command';

type Props = {
  parameterKey: string;
  value: any;
  unit: any;
  valueType: string;
  onSave: (unit: string, key: string, value: any) => void;
  onClose: () => void;
};

const ComponentsByType: any = {
  float: Slider,
  integer: Slider,
  string: Select,
  boolean: Switch,
  command: Command,
};

const Parameters: React.FC<Props> = ({
  parameterKey,
  value,
  unit,
  valueType,
  onSave,
  onClose,
  ...rest
}) => {
  const { t } = useTranslation();
  const type = valueType.toLowerCase();
  const Component = ComponentsByType[type];
  const [stateValue, setStateValue] = React.useState(value);

  const onModalClose = React.useCallback(() => {
    onClose();
  }, [onClose]);

  const handleChange = React.useCallback(
    (value) => {
      setStateValue(() => {
        if (type === 'integer' || type === 'float') {
          return Number(value);
        }

        if (type === 'boolean') {
          return Boolean(value);
        }

        return value;
      });
    },
    [type, setStateValue],
  );

  const handleSave = React.useCallback(() => {
    onSave(unit, parameterKey, stateValue);
  }, [parameterKey, stateValue, onSave]);

  return (
    <div>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        pt={5}
        pb={3}
      >
        <Typography variant="h5" maxWidth={{ xs: '400px', sm: '480px' }}>
          {t(`parameters.${camelCase(parameterKey)}`)}
        </Typography>
        <Component
          key={parameterKey}
          parameterKey
          {...rest}
          unit={unit}
          value={stateValue}
          onChange={handleChange}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" mt="50px">
        <Button variant="contained" mr={2} onClick={onModalClose}>
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSave}
        >
          {unit === 'command' ? t('apply') : t('save')}
        </Button>
      </Box>
    </div>
  );
};

export default React.memo(Parameters);
