import { all, call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import devicesApi from 'api/devices';
import {
  FetchParametersAction,
  FetchParametersWithoutReloadAction,
} from './actions';
import { FetchParametersActionType } from './types';

function* fetchParametersSaga({
  payload,
}: FetchParametersActionType): SagaIterator<void> {
  try {
    const {
      data: { parameters },
    } = yield call(devicesApi.fetchDeviceParameters, payload.id);

    const parametersWithKey: any = {};
    parameters.forEach((parameter: any) => {
      parametersWithKey[parameter.key] = parameter;
    });

    yield put({
      type: FetchParametersAction.FULFILLED,
      payload: parametersWithKey,
    });
  } catch (e) {
    yield put({ type: FetchParametersAction.REJECTED });
  }
}

function* fetchParametersWithoutReloadSaga({
  payload,
}: FetchParametersActionType): SagaIterator<void> {
  try {
    const {
      data: { parameters },
    } = yield call(devicesApi.fetchDeviceParameters, payload.id);

    const parametersWithKey: any = {};
    parameters.forEach((parameter: any) => {
      parametersWithKey[parameter.key] = parameter;
    });

    yield put({
      type: FetchParametersWithoutReloadAction.FULFILLED,
      payload: parametersWithKey,
    });
  } catch (e) {
    yield put({ type: FetchParametersWithoutReloadAction.REJECTED });
  }
}

function* parametersSaga(): SagaIterator<void> {
  yield all([
    takeLatest(FetchParametersAction.REQUESTED, fetchParametersSaga),
    takeLatest(
      FetchParametersWithoutReloadAction.REQUESTED,
      fetchParametersWithoutReloadSaga,
    ),
  ]);
}

export default parametersSaga;
