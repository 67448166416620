import React from 'react';

import LowTierDeviceModal from '../Modal/LowTierDeviceModal';
import SelectValueModal from './SelectValueModal';
import NumberValueModal from './NumberValueModal';
import SwitchValueModal from './SwitchValueModal';
import CommandValueModal from './CommandValueModal';
import BoilerValueModal from './BoilerValueModal';
import HardwareAutosetting from './HardwareAutosetting';
import HardwareUpdate from './HardwareUpdate/HardwareUpdate';
import WarningModal from './WarningModal';
import * as Styled from './Modal.styled';
import TimeModal from './TimeModal/TimeModal';

type Props = {
  type: string;
  data?: any;
};

const getModal = (type: string, data: any): any => {
  switch (type) {
    case 'selectValueModal':
      return <SelectValueModal data={data} />;
    case 'time':
      return <TimeModal data={data} />;
    case 'numberValueModal':
      return <NumberValueModal data={data} />;
    case 'switchValueModal':
      return <SwitchValueModal data={data} />;
    case 'commandValueModal':
      return <CommandValueModal data={data} />;
    case 'boilerValueModal':
      return <BoilerValueModal data={data} />;
    case 'warningModal':
      return <WarningModal data={data} />;
    case 'hardWareUpdateModal':
      return <HardwareUpdate data={data} />;
    case 'hardwareAutosettingModal':
      return <HardwareAutosetting data={data} />;
    default:
      return null;
  }
};

const Modal: React.FC<Props> = ({ type, data }) => {
  return <Styled.Root>{getModal(type, data)}</Styled.Root>;
};

export default Modal;
