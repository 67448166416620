import { createSlice } from '@reduxjs/toolkit';
import {
  AddDeviceActions,
  FetchDevicesActions,
  DeleteDeviceActions,
} from '@redux/devices/actions';
import { DevicesType } from './interfaces';
import { SignInActions, SignOutActions } from '../application/actions';

const initialState: DevicesType = {
  items: [],
  itemCount: null,
  pageCount: null,
  isPending: true,
};

const devices = createSlice({
  name: 'devices',
  initialState,
  reducers: {},
  extraReducers: {
    [SignOutActions.REQUESTED]: (state): void => {
      state.items = [];
      state.itemCount = null;
      state.itemCount = null;
    },
    [AddDeviceActions.FULFILLED]: (state, { payload }): void => {
      state.items.push(payload);
    },
    [AddDeviceActions.REJECTED]: (state): void => {
      state.items = [];
    },
    [FetchDevicesActions.FULFILLED]: (
      state,
      { payload: { devices, meta } },
    ): void => {
      state.items = devices;
      state.itemCount = meta.totalCount;
      state.pageCount = meta.totalPages;
      state.isPending = false;
    },
    [DeleteDeviceActions.FULFILLED]: (state, { payload }): void => {
      state.items = state.items.filter((item) => item.uid !== payload.uid);
      state.isPending = false;
    },
    [DeleteDeviceActions.REJECTED]: (state): void => {
      state.isPending = false;
    },
  },
});

const { reducer } = devices;

export default reducer;
