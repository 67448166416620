export const postfix: { [key: string]: string } = {
  cdBoilerTemperature: '°C',
  cdFlueGasTemperature: '°C',
  cdReturnTemperature: '°C',
  cdFeederTemperature: '°C',
  cdDhwTemperature: '°C',
  cdMixer1Temperature: '°C',
  cdRoom1Temperature: '°C',
  cdMixer2Temperature: '°C',
  cdUpperBufferTemperature: '°C',
  cdLowerBufferTemperature: '°C',
  cdOutsideTemperature: '°C',
  cdRoom2Temperature: '°C',
  cdFanPower: '%',
  cdBoilerPower: '%',
  cdFuelLevel: '%',
}