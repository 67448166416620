import styled from 'styled-components/macro';

type Props = {
  inner?: boolean;
};

export const Root = styled.div<Props>`
  display: flex;
  align-items: center;
  padding: ${({ inner }): any => (inner ? '10px 40px' : '18px 40px')};
  font-size: ${({ inner }): any => (inner ? '16px' : '18px')};
  background: ${({ inner }): any => (inner ? '#e8e8e8' : '')};
  user-select: none;
  cursor: pointer;

  &:hover {
    background: ${({ inner }): any => (inner ? '#fce55a' : '')};
  }
`;

export const Arrow = styled.div`
  margin-left: auto;
  color: #171717;

  svg {
    width: 30px;
    height: 30px;
  }
`;
