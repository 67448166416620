import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import DatePicker from './DatePicker';
import Export from './Export';

import { Box } from '@material-ui/core';

import Button from 'components/Interfaces/NewButton';
import { UpdateHistoryParamsActions } from '@redux/history/actions';
import { dateFromSelector, dateToSelector } from '@redux/history/selectors';

import {
  getYesterdaysDate,
  getWeekAgoDate,
  getMonthAgoDate,
} from 'utils/getDates';

import useStyles from './styles';
import { useSelector } from 'react-redux';

type Props = {
  createChart: () => void;
  onExportPngChart: () => void;
  onExportPngChartMode: () => void;
};

const monthAgo = getMonthAgoDate();

const Controls: React.FC<Props> = ({
  createChart,
  onExportPngChart,
  onExportPngChartMode,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const dateFrom = useSelector(dateFromSelector);
  const dateTo = useSelector(dateToSelector);

  const [activeButton, setActiveButton] = useState<string | null>(null);

  const updateFromDate = useCallback(
    (date) => {
      const stringifiedDate = JSON.parse(JSON.stringify(date));

      dispatch({
        type: UpdateHistoryParamsActions.UPDATE_FROM_DATE,
        payload: { stringifiedDate },
      });
    },
    [dispatch],
  );

  const updateToDate = useCallback(
    (date) => {
      const stringifiedDate = JSON.parse(JSON.stringify(date));

      dispatch({
        type: UpdateHistoryParamsActions.UPDATE_TO_DATE,
        payload: { stringifiedDate },
      });
    },
    [dispatch],
  );

  const handleDateFromChange = useCallback(
    (value) => {
      updateFromDate(value);
      setActiveButton(null);
    },
    [updateFromDate],
  );

  const handleDateToChange = useCallback(
    (value) => {
      updateToDate(value);
      setActiveButton(null);
    },
    [updateToDate],
  );

  const handleSetLastDay = useCallback(() => {
    updateFromDate(getYesterdaysDate());
    updateToDate(new Date());
    setActiveButton('lastDay');
    createChart();
  }, [updateToDate, updateFromDate]);

  const handleSetLastWeek = useCallback(() => {
    updateFromDate(getWeekAgoDate());
    updateToDate(new Date());
    setActiveButton('lastWeek');
    createChart();
  }, [updateToDate, updateFromDate]);

  const handleSetLastMonth = useCallback(() => {
    updateFromDate(getMonthAgoDate());
    updateToDate(new Date());
    setActiveButton('lastMonth');
    createChart();
  }, [updateToDate, updateFromDate]);

  const currentLocale = i18next.language;

  return (
    <Box display="flex" marginBottom="35px" justifyContent="space-between">
      <Box display="flex">
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            justifyContent="space-between"
            width="490px"
            marginLeft="35px"
            marginBottom="25px"
          >
            <DatePicker
              label={t('from')}
              value={dateFrom}
              minDate={monthAgo}
              maxDate={new Date()}
              onChange={handleDateFromChange}
            />
            <DatePicker
              label={t('to')}
              value={dateTo}
              minDate={monthAgo}
              maxDate={new Date()}
              onChange={handleDateToChange}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            width="500px"
            marginLeft="30px"
          >
            <Button
              className={clsx(classes.button, {
                [classes.isActive]: activeButton === 'lastDay',
              })}
              color="primary"
              size="small"
              onClick={handleSetLastDay}
            >
              {t('lastDay')}
            </Button>
            <Button
              className={clsx(classes.button, {
                [classes.isActive]: activeButton === 'lastWeek',
              })}
              color="primary"
              size="small"
              onClick={handleSetLastWeek}
            >
              {t('lastWeek')}
            </Button>
            <Button
              className={clsx(classes.button, {
                [classes.isActive]: activeButton === 'lastMonth',
              })}
              color="primary"
              size="small"
              onClick={handleSetLastMonth}
            >
              {t('lastMonth')}
            </Button>
          </Box>
        </Box>
        <Box>
          <Box marginTop="15px" marginLeft="45px">
            <Button
              color="primary"
              // variant="contained"
              size="small"
              onClick={createChart}
            >
              {t('createChart')}
            </Button>
          </Box>
        </Box>
      </Box>
      <Export
        onExportPngChart={onExportPngChart}
        onExportPngChartMode={onExportPngChartMode}
      />
    </Box>
  );
};

export default Controls;
