import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FetchDevicesActions } from '@redux/devices/actions';
import { areDevicesPendingSelector } from '@redux/devices/selectors';
import { devicesSelector } from '@redux/devices/selectors';

export default (): [boolean, any[]] => {
  const isPending = useSelector(areDevicesPendingSelector);
  const devices = useSelector(devicesSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: FetchDevicesActions.REQUESTED,
      payload: { page: 0, filter: 'all', search: '' },
    });
  }, []);

  return [isPending, devices];
};
