export type SidebarProps = {
  // title: string;
  // path: string[];
  // icon: string;
  name: string;
  path: string;
};

const sidebar: SidebarProps[] = [
  {
    name: 'currentData',
    path: '',
    // title: 'currentData',
    // path: [''],
    // icon: 'HomeIcon',
  },
  {
    name: 'userSettings',
    path: '/settings/user',
    // title: 'userSettings',
    // path: ['/settings/user'],
    // icon: 'ContactsIcon',
  },
  {
    name: 'serviceSettings',
    path: '/settings/service',
    // title: 'serviceSettings',
    // path: ['/settings/service'],
    // icon: 'BuildIcon',
  },
  {
    name: 'history',
    path: '/history',

    // title: 'history',
    // path: ['/history'],
    // icon: 'HistoryIcon',
  },
  {
    name: 'alarms',
    path: '/alarms',

    // title: 'alarms',
    // path: ['/alarms'],
    // icon: 'WarningIcon',
  },
  {
    name: 'deviceSettings',
    path: '/settings/device',

    // title: 'deviceSettings',
    // path: ['/settings/device'],
    // icon: 'SettingsIcon',
  },
];

export default sidebar;
