import styled, { css } from 'styled-components/macro';
import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';

type Props = {
  color?: string;
  background?: string;
  editable?: boolean;
};

export const Root = styled.div<Props>`
  position: relative;
  height: 310px;
  min-width: 244px;
  background: ${({ background }) => background};
  cursor: pointer;
  user-select: none;

  ${(props) =>
    props.editable === false &&
    css`
      cursor: default;
      pointer-events: none;
    `}

  ${(props) =>
    props.color &&
    css`
      color: ${props.color};

      svg,
      path {
        fill: ${props.color};
      }
    `}
`;

export const Wrapper = styled.div`
  font-size: 40px;
  line-height: 70px;
`;

export const Value = styled.div`
  position: absolute;
  line-height: 0.9;
  left: 38px;
  bottom: 88px;
  font-size: 32px;
`;

export const Title = styled.div`
  position: absolute;
  left: 38px;
  bottom: 32px;
  font-size: 14px;
  font-weight: 300;
`;

export const Logo = styled(LogoIcon)`
  position: absolute;
  top: 40px;
  left: 38px;
  width: 55px;
  font-size: 14px;
  font-weight: 300;
`;
