import { AxiosResponse } from 'axios';
import request from 'api/request';

import {
  AddDeviceRequest,
  AddDeviceResponse,
  FetchDevicesRequest,
  FetchDevicesResponse,
  DeleteDeviceResponse,
  UpdateDeviceParameterRequest,
  UpdateDeviceParameterResponse,
} from './types';

const addDevice = (
  data: AddDeviceRequest,
): Promise<AxiosResponse<AddDeviceResponse>> =>
  request.POST<AddDeviceResponse>({
    url: '/devices/bind',
    data,
  });

const getFilterParams = (filter: string): string => {
  switch (filter) {
    case 'active':
      return 'connected';
    case 'disabled':
      return 'disconnected';
    default:
      return 'active';
  }
};

const fetchDevices = ({
  page,
  filter,
  search,
}: FetchDevicesRequest): Promise<AxiosResponse<FetchDevicesResponse>> =>
  request.GET<FetchDevicesResponse>({
    url: `/devices`,
    params: {
      page: page,
      perPage: 10,
      controllerStatus: filter !== 'all' ? getFilterParams(filter) : undefined,
      search: search !== '' ? search : undefined,
    },
  });

const fetchDeviceParameters = (
  id: number,
): Promise<AxiosResponse<AddDeviceResponse>> =>
  request.GET<AddDeviceResponse>({
    url: `/devices/${id}/parameters`,
  });

// const fetchDeviceParameters = (
//   id: number,
// ): Promise<AxiosResponse<AddDeviceResponse>> =>
//   request.GET<AddDeviceResponse>({
//     url: `/devices/${id}/parameters`,
//     stub: paramsStub,
//   });

const deleteDevice = (
  id: number,
): Promise<AxiosResponse<DeleteDeviceResponse>> =>
  request.DELETE<DeleteDeviceResponse>({
    url: `/devices/${id}/unbind`,
  });

const updateDeviceParameter = ({
  uid,
  key,
  value,
}: UpdateDeviceParameterRequest): Promise<
  AxiosResponse<UpdateDeviceParameterResponse>
> =>
  request.PUT<UpdateDeviceParameterResponse>({
    url: `/devices/${uid}/parameters`,
    data: {
      key,
      value,
    },
  });

export default {
  addDevice,
  fetchDevices,
  fetchDeviceParameters,
  deleteDevice,
  updateDeviceParameter,
};
