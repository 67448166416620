import styled from 'styled-components/macro';

export const Root = styled.div`
  margin: 0 8px 16px;
`;

export const Label = styled.label`
  padding: 6px 16px;
  color: rgba(0, 0, 0, 0.26);
  border-radius: 4px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.75;
  background-color: rgba(0, 0, 0, 0.12);
  cursor: pointer;
`;

export const Input = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;

  :checked ~ label {
    color: #fff;
    background-color: ${(props): any => props.color};
  }
`;
