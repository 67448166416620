import React from 'react';
import { useField } from 'formik';
import styled from 'styled-components/macro';
import { spacing } from '@material-ui/system';

import FormControl from '@material-ui/core/FormControl';
import Typography from 'components/Interfaces/Typography';

import { SpacingProps } from '@material-ui/system';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FormControlLabel, Checkbox } from '@material-ui/core';

type Props = {
  label: string;
  name: string;
  description?: string;
  disabled?: boolean;
  fullWidth?: boolean;
};

const StyledFormControl = styled(FormControl)`
  && {
    ${spacing}

    .MuiCheckbox-colorSecondary.Mui-checked {
      color: #ffe632;
    }

    .MuiFormHelperText-root {
      position: absolute;
      bottom: -12px;
    }
  }
`;

const CheckboxField: React.FC<Props & SpacingProps> = ({
  name,
  label,
  description,
  disabled,
  fullWidth,
  ...props
}) => {
  const [field, meta] = useField(name);

  const labelId = name + '-label';
  const error = meta.touched && meta.error ? meta.error : null;

  return (
    <StyledFormControl error={error !== null} fullWidth={fullWidth} {...props}>
      <FormControlLabel
        control={
          <Checkbox
            checked={field.value}
            onChange={field.onChange}
            name={name}
          />
        }
        disabled={disabled}
        id={labelId}
        label={label}
      />
      <Typography pl="34px" fontSize="14px" color="textSecondary">
        {description}
      </Typography>
      <FormHelperText>{error}</FormHelperText>
    </StyledFormControl>
  );
};

export default React.memo(CheckboxField);
