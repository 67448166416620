import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';

import ModalContext from 'context/ModalContext';
import useMemoSelector from 'hooks/useMemoSelector';
import BaseModal from '../BaseModal';
import * as Styled from './TimeModal.styled';
import { parameterByNameSelector } from '../../../@redux/parameters/selectors';

import Button from '../../Interfaces/NewButton';
import { UpdateDeviceParameterActions } from '../../../@redux/devices/actions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import InputMask from 'react-input-mask';
import { addMinutes, format, isValid } from 'date-fns';

// import { makeStyles } from '@material-ui/core/styles';

const mask = '14.07.23 15:34:23';

type Props = {
  data: any;
};

// const useStyles = makeStyles(() => ({
//   root: {
//     width: 500,
//     marginTop: 20,
//   },
//   input: {
//     width: '100%',
//   },
// }));

const TimeModal: React.FC<Props> = ({ data }) => {
  const dispatch = useDispatch();
  //@ts-ignore
  const { id } = useParams();
  const { t } = useTranslation();
  const { closeModal } = useContext(ModalContext);

  const { key, value } = useMemoSelector(parameterByNameSelector, data.key);

  const [enteredDateTime, setEnteredDateTime] = useState(
    format(
      addMinutes(new Date(value), new Date().getTimezoneOffset()),
      'dd.MM.yy HH:mm:ss',
    ),
  );

  const enteredData = useMemo(() => {
    if (enteredDateTime.length !== mask.length) {
      return {};
    }

    const [date, time] = enteredDateTime.split(' ');
    const [day, month, year] = date.split('.');
    const [hour, min, sec] = time.split(':');

    const value = `20${year}-${month}-${day}T${hour}:${min}:${sec}Z`;
    const res = new Date(value);

    return {
      value: isValid(res) ? addMinutes(res, 0).toISOString() : undefined,
      willSet: isValid(res),
    };
  }, [enteredDateTime]);

  const disabled =
    enteredDateTime.length !== mask.length || !enteredData.willSet;

  const handleSave = useCallback(() => {
    if (enteredData.value) {
      closeModal();

      dispatch({
        type: UpdateDeviceParameterActions.REQUESTED,
        payload: { uid: id, key, value: enteredData.value },
      });
    }
  }, [id, key, enteredData?.value, dispatch]);

  return (
    <BaseModal onClose={closeModal}>
      <Styled.Root>
        <Styled.Title>{t(`parameters.${camelCase(data.key)}`)}</Styled.Title>
        <Styled.Body>
          <InputLabel id="1" />
          <InputMask
            mask="99.99.99 99:99:99"
            value={enteredDateTime}
            onChange={(e) => {
              setEnteredDateTime(e.target.value);
            }}
            disabled={false}
            maskChar=" "
          >
            {() => <TextField />}
          </InputMask>
        </Styled.Body>
        <Styled.Buttons>
          <Button gray onClick={closeModal}>
            {t('cancel')}
          </Button>
          <Button disabled={disabled} onClick={handleSave}>
            {t('save')}
          </Button>
        </Styled.Buttons>
      </Styled.Root>
    </BaseModal>
  );
};

export default TimeModal;
