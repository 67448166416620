import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Modal from 'components/Modal';
import Parameters from './Parameters';

import { ParamModalActions } from '@redux/paramModal/actions';

import {
  isParamModalOpenSelector,
  modalParamSelector,
} from '@redux/paramModal/selectors';
import { UpdateDeviceParameterActions } from '@redux/devices/actions';
import { SendCommandActions } from '@redux/commands/actions';

type Props = {};

const ParamSettingModal: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const modalParameters = useSelector(modalParamSelector);
  const isParamModalOpen = useSelector(isParamModalOpenSelector);

  const handleModalClose = useCallback(() => {
    dispatch({ type: ParamModalActions.CLOSE });
  }, [dispatch]);

  const handleSave = useCallback(
    (unit, key, value) => {
      if (unit === 'command') {
        dispatch({
          type: SendCommandActions.REQUESTED,
          payload: {
            uid: id,
            command: value,
          },
        });

        return dispatch({ type: ParamModalActions.CLOSE });
      }
      dispatch({
        type: UpdateDeviceParameterActions.REQUESTED,
        payload: { uid: id, key, value },
      });

      dispatch({ type: ParamModalActions.CLOSE });
    },
    [dispatch, id],
  );

  return (
    <Modal open={isParamModalOpen} handleClose={handleModalClose}>
      {isParamModalOpen && (
        <Parameters
          parameterKey={modalParameters.key}
          {...modalParameters}
          onClose={handleModalClose}
          onSave={handleSave}
        />
      )}
    </Modal>
  );
};

export default memo(ParamSettingModal);
