import { createSlice } from '@reduxjs/toolkit';
import _find from 'lodash/find';

import {
  FetchAlarmsActions,
  UpdateAlarmsAction,
  UpdateAlarmsActions,
} from '@redux/alarms/actions';
import { Alarm, AlarmsType } from './interfaces';

const initialState: AlarmsType = {
  items: [],
  pageCount: null,
  isPending: false,
};

const alarms = createSlice({
  name: 'alarms',
  initialState,
  reducers: {},
  extraReducers: {
    [UpdateAlarmsAction]: (state, { payload }): void => {
      let item = _find(state.items, { uid: payload.uid });

      if (item) {
        item = Object.assign(item, payload);
      } else {
        state.items.push(payload);
      }
    },
    [FetchAlarmsActions.REQUESTED]: (state): void => {
      state.isPending = true;
    },
    [FetchAlarmsActions.FULFILLED]: (
      state,
      { payload: { alarms, meta } },
    ): void => {
      state.items = alarms;
      state.pageCount = meta.totalPages;
      state.isPending = false;
    },
    [FetchAlarmsActions.REJECTED]: (state): void => {
      state.isPending = false;
    },

    [UpdateAlarmsActions.UPDATE]: (state, { payload: { alarms } }): void => {
      alarms.forEach((alarm: Alarm) => {
        const currIdx = state.items.findIndex((a) => a.from === alarm.from);
        if (currIdx !== -1)
          state.items[currIdx] = Object.assign(state.items[currIdx], alarm);
        else state.items.unshift(alarm);
      });
    },
  },
});

const { reducer } = alarms;

export default reducer;
