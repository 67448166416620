import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@redux/store';
import { ModalType } from './interfaces';

const rootSelector = (state: RootState): ModalType => state.modal;

export const isModalOpenSelector = createSelector(
  rootSelector,
  ({ isOpen }) => isOpen,
);
