import { createSlice } from '@reduxjs/toolkit';
import { ModalActions } from '@redux/modal/actions';
import { ModalType } from './interfaces';

const initialState: ModalType = {
  isOpen: false,
};

const modal = createSlice({
  name: 'modal',
  initialState,
  reducers: {},
  extraReducers: {
    [ModalActions.OPEN]: (state): void => {
      state.isOpen = true;
    },
    [ModalActions.CLOSE]: (state): void => {
      state.isOpen = false;
    },
  },
});

const { reducer } = modal;

export default reducer;
