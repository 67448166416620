import React, { useCallback } from 'react';
import { SpaceProps, LayoutProps } from 'styled-system';
import * as Styled from './Button.styled';

type Props = {
  children: any;
  component?: any;
  gray?: boolean;
  red?: boolean;
  disabled?: boolean;
  onClick?: (event: MouseEvent) => void;
};

const Button: React.FC<
  Props &
    SpaceProps &
    LayoutProps &
    React.ButtonHTMLAttributes<HTMLButtonElement> &
    any
> = ({ children, component, gray, disabled, onClick, ...rest }) => {
  const handleClick = useCallback(
    (event) => {
      onClick && onClick(event);
    },
    [onClick],
  );

  return (
    <Styled.Root
      as={component}
      {...rest}
      gray={gray}
      disabled={disabled}
      onClick={handleClick}
    >
      {children}
    </Styled.Root>
  );
};

export default Button;
