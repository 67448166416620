import React, { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ModalContext from 'context/ModalContext';
import Button from 'components/Interfaces/NewButton';
import BaseModal from '../BaseModal';
import * as Styled from './BoilerValueModal.styled';
import { SendCommandActions } from '../../../@redux/commands/actions';
import { UpdateDeviceParameterActions } from '../../../@redux/devices/actions';
import { useDispatch } from 'react-redux';

type Props = {
  data: any;
};

const BoilerValueModal: React.FC<Props> = ({ data }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const { closeModal } = useContext(ModalContext);
  const isActive = data.value !== 'turned_off';

  const handleClick = useCallback(() => {
    dispatch({
      type: SendCommandActions.REQUESTED,
      payload: {
        uid: id,
        command: isActive ? 'boiler_turn_off' : 'boiler_turn_on',
      },
    });

    // dispatch({
    //   type: UpdateDeviceParameterActions.FULFILLED,
    //   payload: {
    //     uid: id,
    //     key: 'CD_Boiler_operation_mode',
    //     value: isActive ? 'turned_off' : 'on',
    //   },
    // });

    closeModal();
  }, []);

  return (
    <BaseModal onClose={closeModal}>
      <Styled.Root>
        <Styled.Body>
          <Styled.Title>{t(isActive ? 'offBoiler' : 'onBoiler')}</Styled.Title>
        </Styled.Body>
        <Styled.Buttons>
          <Button gray onClick={closeModal}>
            {t('cancel')}
          </Button>
          <Button red={isActive} onClick={handleClick}>
            {t(isActive ? 'disable' : 'enable')}
          </Button>
        </Styled.Buttons>
      </Styled.Root>
    </BaseModal>
  );
};

export default BoilerValueModal;
