import { createSlice } from '@reduxjs/toolkit';
import _merge from 'lodash/merge';
import {
  FetchDeviceSettingsActions,
  UpdateDeviceSettingsActions,
  FetchModuleVersionsActions,
  FetchControllerVersionsActions,
  UpdateModuleVersionActions,
  UpdateControllerVersionActions,
  ChangeDeviceSettingsActions,
  DeviceSettingsModuleVerisonModalActions,
  DeviceSettingsControllerVerisonModalActions,
} from '@redux/deviceSettings/actions';
import { DeviceSettingsType } from './interfaces';

const initialState: DeviceSettingsType = {
  data: null,
  isPending: false,
  isModuleVersionModalOpen: false,
  isControllerVersionModalOpen: false,
  moduleVersions: [],
  controllerVerisons: [],
  isModuleVersionUpdatePending: false,
  isControllerVersionUpdatePending: false,
};

const deviceSettings = createSlice({
  name: 'deviceSettings',
  initialState,
  reducers: {},
  extraReducers: {
    [ChangeDeviceSettingsActions.REQUESTED]: (state, { payload }): void => {
      _merge(state.data, payload);
    },
    [FetchDeviceSettingsActions.REQUESTED]: (state): void => {
      state.isPending = true;
    },
    [FetchDeviceSettingsActions.FULFILLED]: (state, { payload }): void => {
      state.data = payload;
      state.isPending = false;
    },
    [FetchDeviceSettingsActions.REJECTED]: (state): void => {
      state.isPending = false;
    },
    [UpdateDeviceSettingsActions.REQUESTED]: (state): void => {
      state.isPending = true;
    },
    [UpdateDeviceSettingsActions.FULFILLED]: (state): void => {
      state.isPending = false;
    },
    [UpdateDeviceSettingsActions.REJECTED]: (state): void => {
      state.isPending = false;
    },
    [DeviceSettingsModuleVerisonModalActions.OPEN]: (state): void => {
      state.isModuleVersionModalOpen = true;
    },
    [DeviceSettingsModuleVerisonModalActions.CLOSE]: (state): void => {
      state.isModuleVersionModalOpen = false;
    },
    [DeviceSettingsControllerVerisonModalActions.OPEN]: (state): void => {
      state.isControllerVersionModalOpen = true;
    },
    [DeviceSettingsControllerVerisonModalActions.CLOSE]: (state): void => {
      state.isControllerVersionModalOpen = false;
    },
    [FetchModuleVersionsActions.FULFILLED]: (state, action): void => {
      state.moduleVersions = action.payload;
    },
    [FetchControllerVersionsActions.FULFILLED]: (state, action): void => {
      state.controllerVerisons = action.payload;
    },
    [UpdateModuleVersionActions.REQUESTED]: (state): void => {
      state.isModuleVersionUpdatePending = true;
    },
    [UpdateModuleVersionActions.FULFILLED]: (state): void => {
      state.isModuleVersionUpdatePending = false;
    },
    [UpdateModuleVersionActions.REJECTED]: (state): void => {
      state.isModuleVersionUpdatePending = false;
    },
    [UpdateControllerVersionActions.REQUESTED]: (state): void => {
      state.isControllerVersionUpdatePending = true;
    },
    [UpdateControllerVersionActions.FULFILLED]: (state): void => {
      state.isControllerVersionUpdatePending = false;
    },
    [UpdateControllerVersionActions.REJECTED]: (state): void => {
      state.isControllerVersionUpdatePending = false;
    },
  },
});

const { reducer } = deviceSettings;

export default reducer;
