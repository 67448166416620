import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';

import store, { history } from '@redux/store';

import App from 'components/App';
import theme from 'styles/theme';
import routes from 'constants/routes';

import 'styles/reset.css';
import 'styles/root.css';
import 'styles/react-datetime-picker.css';

import * as serviceWorker from './serviceWorker';

import 'constants/parameters/categories';

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <App routes={routes} />
          </SnackbarProvider>
        </ThemeProvider>
      </React.StrictMode>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
