import React, { memo, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Typography from 'components/Interfaces/Typography';

import deviceStubImage from 'assets/controller1.png';

import * as Styled from './DeviceBlock.styled';
import useStyles from './styles';

type Props = {
  type: string;
  identifier: string;
  title: string;
  userHasDeviceAccess: boolean;
  softVersion: number;
  controllerVersion: number;
  isModuleConnected: boolean;
  isControllerConnected: boolean;
};

const DeviceBlock: React.FC<Props> = ({
  type,
  identifier,
  title,
  userHasDeviceAccess,
  softVersion,
  controllerVersion,
  isModuleConnected,
  isControllerConnected,
}) => {
  const getIsActiveText = useCallback((isConnected: boolean): string => {
    return isConnected ? 'on' : 'off';
  }, []);

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <NavLink
      to={`/device/${identifier}`}
      className={clsx(classes.wrapperLink, {
        [classes.disable]: !userHasDeviceAccess,
      })}
    >
      <Styled.Root>
        <Styled.Image
          src={deviceStubImage}
          alt={title}
          display={{ xs: 'none', md: 'block' }}
          mr={3}
        />
        <Styled.Params>
          <Styled.ParamBlock mr={4}>
            <Styled.Param>
              <Typography variant="caption" color="textSecondary" mr={0.5}>
                {t('controllerModel')}
              </Typography>
              <Typography variant="caption">{type}</Typography>
            </Styled.Param>
            <Styled.Param>
              <Typography variant="caption" color="textSecondary" mr={0.5}>
                ID:
              </Typography>
              <Typography variant="caption">{identifier}</Typography>
            </Styled.Param>
            <Styled.Param>
              <Typography variant="caption" color="textSecondary" mr={0.5}>
                {t('label')}
              </Typography>
              <Typography variant="caption">{title}</Typography>
            </Styled.Param>
          </Styled.ParamBlock>
          <Styled.ParamBlock mr={3}>
            <Styled.Param>
              <Typography variant="caption" color="textSecondary" mr={0.5}>
                {t('moduleVersion')}
              </Typography>
              <Typography variant="caption">{softVersion}</Typography>
            </Styled.Param>
            <Styled.Param>
              <Typography variant="caption" color="textSecondary" mr={0.5}>
                {t('controllerVersion')}
              </Typography>
              <Typography variant="caption">{controllerVersion}</Typography>
            </Styled.Param>
          </Styled.ParamBlock>
          <Styled.ParamBlock>
            <Styled.Param>
              <Typography variant="caption" color="textSecondary" mr={0.5}>
                {t('moduleActivity')}
              </Typography>
              <div
                className={clsx(classes.badge, {
                  [classes.connected]: isModuleConnected,
                  [classes.disconnected]: !isModuleConnected,
                })}
              >
                <Typography variant="caption">
                  {getIsActiveText(isModuleConnected)}
                </Typography>
              </div>
            </Styled.Param>
            <Styled.Param>
              <Typography variant="caption" color="textSecondary" mr={0.5}>
                {t('controllerActivity')}
              </Typography>
              <div
                className={clsx(classes.badge, {
                  [classes.connected]: isControllerConnected,
                  [classes.disconnected]: !isControllerConnected,
                })}
              >
                <Typography variant="caption">
                  {getIsActiveText(isControllerConnected)}
                </Typography>
              </div>
            </Styled.Param>
          </Styled.ParamBlock>
        </Styled.Params>
      </Styled.Root>
    </NavLink>
  );
};

export default memo(DeviceBlock);
