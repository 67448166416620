import React, { useCallback, useContext } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

import DownloadVersionModal from 'components/Modal/DownloadVersionModal';
import InputField from 'components/InputField';
import SelectField from 'components/Fields/SelectField';
import CheckboxField from 'components/Fields/CheckboxField';
import Button from 'components/Interfaces/NewButton';
import Typography from 'components/Interfaces/Typography';
import { parametersByNameSelector } from '@redux/parameters/selectors';
import useMemoSelector from 'hooks/useMemoSelector';

import { Box, FormGroup, Link } from '@material-ui/core';

import validationSchema from './validationSchema';

import { DeviceSettingsData } from '@redux/deviceSettings/interfaces';
import { UpdateDeviceSettingsRequest } from 'api/deviceSettings/types';
import { serviceIntervals } from 'constants/deviceSettings';

import {
  DeviceSettingsControllerVerisonModalActions,
  DeviceSettingsModuleVerisonModalActions,
  UpdateControllerVersionActions,
} from '@redux/deviceSettings/actions';
import { UpdateModuleVersionActions } from '@redux/deviceSettings/actions';
import {
  controllerVersionsSelector,
  isControllerVersionModalOpenSelector,
  isModuleVersionModalOpenSelector,
  isModuleVersionUpdatePendingSelector,
  moduleVersionsSelector,
} from '@redux/deviceSettings/selectors';
import deviceSettingsAPI from 'api/deviceSettings';
import { UpdateVersionRequest } from 'api/deviceSettings/types';

import { getCurrentServiceInterval } from './utils';
import submissionError from 'utils/submissionError';
import getSelectOptions from 'utils/getSelectOptions';

import * as Styled from './DeviceSettings.styled';
import { useSelector, useDispatch } from 'react-redux';
import UserRole from 'components/UserRole';
import ModalContext from 'context/ModalContext';

type Props = {
  data: DeviceSettingsData;
  isPending: boolean;
  deviceId: string;
  wifiSignalValue: number;
  onSubmit: (
    values: UpdateDeviceSettingsRequest,
    actions: FormikHelpers<UpdateDeviceSettingsRequest>,
  ) => void;
  onDeleteClick: () => void;
};

const DeviceSettings: React.FC<Props> = ({
  data,
  isPending,
  deviceId,
  wifiSignalValue,
  onSubmit,
  onDeleteClick,
}) => {
  const {
    deviceSettings: {
      title,
      uid,
      demo,
      moduleStatus,
      controllerStatus,
      serviceLevel,
      serviceInterval,
      alarmNotification,
      newModuleSoftware,
      customerRoles,
      moduleSoftwareUpdateAvailable,
      controllerSoftwareUpdateAvailable,
    },
    deviceParameters,
  } = data;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { openModal } = useContext(ModalContext);

  const isControllerVersionModalOpen = useSelector(
    isControllerVersionModalOpenSelector,
  );

  const isModuleVersionModalOpen = useSelector(
    isModuleVersionModalOpenSelector,
  );

  const isModuleVersionUpdatePending = useSelector(
    isModuleVersionUpdatePendingSelector,
  );

  // const [moduleUpdateResultView, setModuleUpdateResultView] = useState('');

  const serviceLevelOptions = getSelectOptions(serviceLevel.enum);
  const serviceIntervalOptions = getSelectOptions(serviceIntervals);
  const currentServiceInterval = getCurrentServiceInterval(serviceInterval);
  const controllerVersion = deviceParameters.find(
    (item) => item.key === 'DS_SoftVer_Controller_version',
  );
  const moduleVersion = deviceParameters.find(
    (item) => item.key === 'DS_SoftVer_Internet_module_version',
  );

  const moduleVersions = useSelector(moduleVersionsSelector);
  const controllerVersions = useSelector(controllerVersionsSelector);
  const params = useMemoSelector(parametersByNameSelector, [
    'Devices_WIFI',
    'Devices_IP',
    'Ethernet_IP',
  ]);

  const handleModuleVersionModalOpen = useCallback(() => {
    // setModuleUpdateResultView('');
    dispatch({ type: DeviceSettingsModuleVerisonModalActions.OPEN });
  }, [dispatch]);

  const handleModuleVersionModalClose = useCallback(() => {
    dispatch({ type: DeviceSettingsModuleVerisonModalActions.CLOSE });
  }, []);

  const handleControllerVersionModalOpen = useCallback(() => {
    // setModuleUpdateResultView('');
    dispatch({ type: DeviceSettingsControllerVerisonModalActions.OPEN });
  }, [dispatch]);

  const handleControllerVersionModalClose = useCallback(() => {
    dispatch({ type: DeviceSettingsControllerVerisonModalActions.CLOSE });
  }, []);

  const handleWarningAdvancedUser = useCallback((props) => {
    if (!props.values.advancedUser) {
      openModal('warningModal', { text: t('advancedUserInfo') });
    }
  }, []);

  const handleUpdateModuleVersion = useCallback(
    (
      values: UpdateVersionRequest,
      actions: FormikHelpers<UpdateVersionRequest>,
    ) => {
      dispatch({ type: UpdateModuleVersionActions.REQUESTED });
      return deviceSettingsAPI
        .updateVersion(deviceId, values.softwareId)
        .then(({ data }) => {
          dispatch({
            type: UpdateModuleVersionActions.FULFILLED,
            payload: data,
          });
          dispatch({ type: DeviceSettingsModuleVerisonModalActions.CLOSE });
          openModal('hardWareUpdateModal', {});
        })
        .catch((error) => {
          dispatch({ type: UpdateModuleVersionActions.REJECTED });
          actions.setErrors(submissionError(error.response));
        });
    },
    [dispatch, deviceId],
  );

  const handleUpdateControllerVersion = useCallback(
    (
      values: UpdateVersionRequest,
      actions: FormikHelpers<UpdateVersionRequest>,
    ) => {
      dispatch({ type: UpdateControllerVersionActions.REQUESTED });
      return deviceSettingsAPI
        .updateVersion(deviceId, values.softwareId)
        .then(({ data }) => {
          dispatch({
            type: UpdateControllerVersionActions.FULFILLED,
            payload: data,
          });
          dispatch({ type: DeviceSettingsControllerVerisonModalActions.CLOSE });
          openModal('hardWareUpdateModal', {});
        })
        .catch((error) => {
          dispatch({ type: UpdateControllerVersionActions.REJECTED });
          actions.setErrors(submissionError(error.response));
        });
    },
    [dispatch, deviceId],
  );

  return (
    <Box width="100%" pt="40px">
      <Styled.Root>
        <Formik
          initialValues={{
            title,
            serviceLevel: serviceLevel.value,
            serviceInterval: currentServiceInterval,
            alarmNotificationPush: alarmNotification.push,
            alarmNotificationEmail: alarmNotification.email,
            customerService: customerRoles.includes('customer_service'),
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(props): React.ReactElement => (
            <Styled.Form onSubmit={props.handleSubmit}>
              <Styled.Row>
                <Styled.LabelWrapper>
                  <Typography variant="body1" color="textSecondary" pt="4px">
                    {t('label')}
                  </Typography>
                </Styled.LabelWrapper>
                <Styled.FieldWrapper>
                  <InputField
                    label={t('label')}
                    name="title"
                    type="text"
                    mt="16px"
                    mb="12px"
                    disabled={demo}
                  />
                </Styled.FieldWrapper>
              </Styled.Row>
              <Styled.Row>
                <Styled.LabelWrapper>
                  <Typography variant="body1" color="textSecondary">
                    UID:
                  </Typography>
                </Styled.LabelWrapper>
                <Styled.FieldWrapper>
                  <Typography variant="body1">{uid}</Typography>
                </Styled.FieldWrapper>
              </Styled.Row>
              <Styled.Row>
                <Styled.LabelWrapper>
                  <Typography variant="body1" color="textSecondary">
                    {t('controllerVersion')}
                  </Typography>
                </Styled.LabelWrapper>
                <Styled.FieldWrapper>
                  <Typography variant="body1">
                    {controllerVersion && controllerVersion.value}
                  </Typography>
                  {controllerSoftwareUpdateAvailable && (
                    <Box ml="40px">
                      <Link
                        style={{ color: '#000' }}
                        component="button"
                        onClick={handleControllerVersionModalOpen}
                        type="button"
                      >
                        <Typography variant="body1" textAlign="left">
                          <div>{t('changeVersion')}</div>
                          {newModuleSoftware && (
                            <Typography variant="caption" color="textSecondary">
                              {t('newVersionAvailable')}
                            </Typography>
                          )}
                        </Typography>
                      </Link>
                    </Box>
                  )}
                </Styled.FieldWrapper>
              </Styled.Row>
              <Styled.Row>
                <Styled.LabelWrapper>
                  <Typography variant="body1" color="textSecondary">
                    {t('moduleVersion')}
                  </Typography>
                </Styled.LabelWrapper>
                <Styled.FieldWrapper>
                  <Typography variant="body1">
                    {moduleVersion && moduleVersion.value}
                  </Typography>
                  {moduleSoftwareUpdateAvailable && (
                    <Box ml="40px">
                      <Link
                        style={{ color: '#000' }}
                        component="button"
                        onClick={handleModuleVersionModalOpen}
                        type="button"
                      >
                        <Typography variant="body1" textAlign="left">
                          <div>{t('changeVersion')}</div>
                          {newModuleSoftware && (
                            <Typography variant="caption" color="textSecondary">
                              {t('newVersionAvailable')}
                            </Typography>
                          )}
                        </Typography>
                      </Link>
                    </Box>
                  )}
                </Styled.FieldWrapper>
              </Styled.Row>
              <Styled.Row>
                <Styled.LabelWrapper>
                  <Typography variant="body1" color="textSecondary">
                    {t('parameters.devicesWIFI')}
                  </Typography>
                </Styled.LabelWrapper>
                <Styled.FieldWrapper>
                  <Typography variant="body1">
                    {params['devicesWifi']?.value}
                  </Typography>
                </Styled.FieldWrapper>
              </Styled.Row>

              <Styled.Row>
                <Styled.LabelWrapper>
                  <Typography variant="body1" color="textSecondary">
                    {t('parameters.devicesIP')}
                  </Typography>
                </Styled.LabelWrapper>
                <Styled.FieldWrapper>
                  <Typography variant="body1">
                    {params['devicesIp']?.value}
                  </Typography>
                </Styled.FieldWrapper>
              </Styled.Row>
              <Styled.Row>
                <Styled.LabelWrapper>
                  <Typography variant="body1" color="textSecondary">
                    {t('parameters.ethernetIP')}
                  </Typography>
                </Styled.LabelWrapper>
                <Styled.FieldWrapper>
                  <Typography variant="body1">
                    {params['ethernetIp']?.value}
                  </Typography>
                </Styled.FieldWrapper>
              </Styled.Row>

              <Styled.Row>
                <Styled.LabelWrapper>
                  <Typography variant="body1" color="textSecondary">
                    {t('wifiSignalQuality')}
                  </Typography>
                </Styled.LabelWrapper>
                <Styled.FieldWrapper>
                  <Typography variant="body1">{wifiSignalValue}</Typography>
                </Styled.FieldWrapper>
              </Styled.Row>
              <Styled.Row>
                <Styled.LabelWrapper>
                  <Typography variant="body1" color="textSecondary">
                    {t('moduleActivity')}
                  </Typography>
                </Styled.LabelWrapper>
                <Styled.FieldWrapper>
                  <Typography variant="body1">
                    {moduleStatus && t(moduleStatus)}
                  </Typography>
                </Styled.FieldWrapper>
              </Styled.Row>
              <Styled.Row>
                <Styled.LabelWrapper>
                  <Typography variant="body1" color="textSecondary">
                    {t('controllerActivity')}
                  </Typography>
                </Styled.LabelWrapper>
                <Styled.FieldWrapper>
                  <Typography variant="body1">
                    {controllerStatus && t(controllerStatus)}
                  </Typography>
                </Styled.FieldWrapper>
              </Styled.Row>
              <UserRole role="customer">
                <Styled.Row>
                  <Styled.LabelWrapper>
                    <Typography variant="body1" color="textSecondary" pt="12px">
                      {t('serviceLevel')}
                    </Typography>
                  </Styled.LabelWrapper>
                  <Styled.FieldWrapper>
                    <SelectField
                      name="serviceLevel"
                      label={t('serviceLevel')}
                      mt="16px"
                      items={serviceLevelOptions}
                    />
                  </Styled.FieldWrapper>
                </Styled.Row>
                <Styled.Row>
                  <Styled.LabelWrapper>
                    <Typography variant="body1" color="textSecondary" pt="12px">
                      {t('sessionLength')}
                    </Typography>
                  </Styled.LabelWrapper>
                  <Styled.FieldWrapper>
                    <SelectField
                      name="serviceInterval"
                      label={t('sessionLength')}
                      mt="16px"
                      items={serviceIntervalOptions}
                      disabled={props.values.serviceLevel !== 'edit'}
                    />
                  </Styled.FieldWrapper>
                </Styled.Row>
                <Styled.Row>
                  <Styled.LabelWrapper>
                    <Typography variant="body1" color="textSecondary">
                      {t('alarmNotifications')}
                    </Typography>
                  </Styled.LabelWrapper>
                  <Styled.FieldWrapper>
                    <FormGroup row>
                      <CheckboxField
                        name="alarmNotificationPush"
                        label={t('pushNotifications')}
                      />
                      {/* <CheckboxField
                        name="alarmNotificationEmail"
                        label={t('email')}
                      /> */}
                    </FormGroup>
                  </Styled.FieldWrapper>
                </Styled.Row>
                <Styled.Row>
                  <Styled.LabelWrapper>
                    <Typography variant="body1" color="textSecondary">
                      {t('advancedUser')}
                    </Typography>
                  </Styled.LabelWrapper>
                  <Styled.FieldWrapper
                    onClick={(): void => handleWarningAdvancedUser(props)}
                  >
                    <FormGroup row>
                      <CheckboxField name="customerService" label="" />
                    </FormGroup>
                  </Styled.FieldWrapper>
                </Styled.Row>
              </UserRole>
              <Button type="submit" mt="20px" disabled={isPending}>
                {t('save')}
              </Button>
            </Styled.Form>
          )}
        </Formik>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          mt="20px"
        >
          <UserRole role="customer">
            {!demo && (
              <Button red onClick={onDeleteClick}>
                {t('deleteThisDevice')}
              </Button>
            )}
          </UserRole>
        </Box>
      </Styled.Root>
      <DownloadVersionModal
        deviceId={deviceId}
        warning={t('beforeChangeModuleVersion')}
        versions={moduleVersions}
        open={isModuleVersionModalOpen}
        onSubmit={handleUpdateModuleVersion}
        onClose={handleModuleVersionModalClose}
        pending={isModuleVersionUpdatePending}
      />
      <DownloadVersionModal
        deviceId={deviceId}
        warning={t('beforeChangeControllerVersion')}
        versions={controllerVersions}
        open={isControllerVersionModalOpen}
        onSubmit={handleUpdateControllerVersion}
        onClose={handleControllerVersionModalClose}
        pending={isModuleVersionUpdatePending}
      />
    </Box>
  );
};

export default DeviceSettings;
