import React from 'react';

import CombinedTemperature from 'components/Parameters/Cards/CombinedTemperature/CombinedTemperatureNew';

type Props = {
  value: string;
  unit: string;
  valueKey: string;
  editable: boolean;
  parameters: any;
};

const BoilerTemperatureParameter: React.FC<Props> = (props) => {
  const {
    boilerSHeatSource,
    cdBoilerTemperature,
    boilerSPresentBoilerTemperature,
    boilerSBoilerPresetTemperatureInGMode,
  } = props.parameters;

  const firstParameter = React.useMemo(() => {
    switch (boilerSHeatSource.value) {
      case 'burner':
      case 'reserve_boiler':
        return boilerSPresentBoilerTemperature;
      default:
        return boilerSBoilerPresetTemperatureInGMode;
    }
  }, [
    boilerSHeatSource,
    boilerSBoilerPresetTemperatureInGMode,
    boilerSPresentBoilerTemperature,
  ]);

  const editKey = React.useMemo(() => {
    switch (boilerSHeatSource.value) {
      case 'burner':
      case 'reserve_boiler':
        return 'BoilerS_Present_boiler_temperature';
      default:
        return 'BoilerS_Boiler_preset_temperature_in_G_mode';
    }
  }, [
    boilerSHeatSource,
    boilerSBoilerPresetTemperatureInGMode,
    boilerSPresentBoilerTemperature,
  ]);

  return (
    <CombinedTemperature
      {...props}
      editKey={editKey}
      specifiedTemperatureKeys={{
        bmoreAuto: 'CD_Present_boiler_temperature',
        ecomax: editKey,
      }}
      modalType="numberValueModal"
      parameters={{ firstParameter, cdBoilerTemperature }}
    />
  );
};

export default React.memo(BoilerTemperatureParameter);
