import React from 'react';
import styled from 'styled-components/macro';
import { CircularProgress } from '@material-ui/core';

type Props = {
  visible: boolean;
  children?: any;
};

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  svg {
    color: #ffe632;
  }
`;

const Progress: React.FC<Props> = ({ visible, children }) => {
  const [isReady, setReady] = React.useState(false);

  React.useEffect(() => {
    setReady(true);
  }, []);

  if (!visible && isReady) {
    return children;
  }

  return (
    <Root>
      <CircularProgress />
    </Root>
  );
};

export default React.memo(Progress);
