import React, { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Link from 'components/Interfaces/Link';

import EmailIcon from '@material-ui/icons/Email';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import { Typography, Box, FormHelperText } from '@material-ui/core';
import { ReactComponent as BemoreIcon } from 'assets/icons/bemoreIcon.svg';

import * as Styled from './FormWrapper.styled';

type LinkProps = {
  to: string;
  nameKey: string;
};

type Props = {
  title: string;
  icon?: 'lock' | 'add' | 'lockOpen' | 'email';
  children: ReactNode;
  links?: LinkProps[];
  error?: string | null;
};

const FormWrapper: React.FC<Props> = ({
  title,
  icon,
  children,
  links,
  error,
}) => {
  const getIcon = useCallback((icon) => {
    switch (icon) {
      case 'lock':
        return <BemoreIcon />;
      case 'lockOpen':
        return <BemoreIcon />;
      case 'add':
        return <AddToPhotosIcon />;
      case 'email':
        return <EmailIcon />;
    }
  }, []);

  const { t } = useTranslation();

  return (
    <Styled.Root>
      <Styled.Wrapper>
        <Box mb="8px">{getIcon(icon)}</Box>
        <Typography component="h1" variant="h5">
          {title}
        </Typography>
        {error && (
          <Box position="absolute" top="83px">
            <FormHelperText error>{error}</FormHelperText>
          </Box>
        )}
        {children}
        {links && (
          <Styled.Links>
            {links.map((item) => (
              <Link key={item.to} to={item.to}>
                <Typography color="textPrimary" variant="body1">
                  {t(item.nameKey)}
                </Typography>
              </Link>
            ))}
          </Styled.Links>
        )}
      </Styled.Wrapper>
    </Styled.Root>
  );
};

export default React.memo(FormWrapper);
