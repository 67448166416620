import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    popper: {
      zIndex: 999,
    },
    paper: {
      maxWidth: '400px',
      border: '1px solid',
      fontSize: '14px',
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
    },
    tableContainer: {
      maxWidth: '1000px',
      margin: '0 auto 100px',
    },
    tableHead: {
      '& th': {
        fontWeight: '600',
      },
    },
  }),
);
