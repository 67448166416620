import React from 'react';
import { camelCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

import Typography from 'components/Interfaces/Typography';
import BaseParameter from '../BaseParameter';

type Props = {
  unit: any;
  value: any;
  valueKey: string;
  editable: boolean;
};

const StateParameter: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <BaseParameter {...props} editKey={props.valueKey}>
      <Grid container justify="center">
        <Typography fontSize="40px" lineHeight="70px">
          {props.unit === ''
            ? props.value
            : t(`stateParameters.${camelCase(props.value)}`)}
        </Typography>
      </Grid>
    </BaseParameter>
  );
};

export default StateParameter;
