import React, {
  memo,
  useEffect,
  useCallback,
  useState,
  useContext,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

import Page from 'components/Page';
import FormWrapper from 'components/FormWrapper';
import EmailConfirmedModal from 'components/Modal/EmailConfirmedModal';
import ModalContext from 'context/ModalContext';

import { ModalActions } from '@redux/modal/actions';
import { AddDeviceActions } from '@redux/devices/actions';
import { FetchFactoriesActions } from '@redux/factories/actions';
import { isModalOpenSelector } from '@redux/modal/selectors';
import { factoryItemsSelector } from '@redux/factories/selectors';
import { isFactoriesPendingSelector } from '@redux/factories/selectors';

import devicesAPI from 'api/devices';
import { AddDeviceRequest } from 'api/devices/types';

import { CustomRouteComponentProps } from 'constants/routes';
import { ConnectionType } from 'constants/addDeviceInstructionTypes';
import addDeviceSteps from 'constants/addDeviceSteps';
import submissionError from 'utils/submissionError';

import Header from '../../components/Header';
import Step from './Step';
import AddDevice from './AddDevice';
import FirstStep from './Step/FirstStep';

const AddDeviceContainer: React.FC<CustomRouteComponentProps> = () => {
  const dispatch = useDispatch();
  const { openModal } = useContext(ModalContext);

  const [currentStep, setCurrentStep] = useState(1);
  const [
    currentConnectionType,
    setCurrentConnectionType,
  ] = useState<ConnectionType | null>(null);

  const factories = useSelector(factoryItemsSelector);
  const isFactoriesPending = useSelector(isFactoriesPendingSelector);
  const isModalOpen = useSelector(isModalOpenSelector);

  const { t } = useTranslation();

  useEffect(() => {
    dispatch({ type: FetchFactoriesActions.REQUESTED });
  }, [dispatch]);

  const handleSubmit = useCallback(
    (values: AddDeviceRequest, actions: FormikHelpers<AddDeviceRequest>) => {
      return devicesAPI
        .addDevice(values)
        .then(({ data }) => {
          dispatch({ type: AddDeviceActions.FULFILLED, payload: data });
          openModal('hardwareAutosettingModal', {
            deviceId: values.uid,
          });
        })
        .catch((error) => {
          dispatch({ type: AddDeviceActions.REJECTED });
          actions.setErrors(submissionError(error.response));
        });
    },
    [dispatch],
  );

  const handleStepChange = useCallback((action: string, step: number) => {
    switch (action) {
      case 'next':
        setCurrentStep(step + 1);
        break;
      case 'exact':
        setCurrentStep(step);
        break;
      case 'prev':
        setCurrentStep(step - 1);
        break;
      default:
        setCurrentStep(step);
    }
  }, []);

  const handleConnectionTypeChange = useCallback(
    (type: ConnectionType) => {
      setCurrentConnectionType(type);
    },
    [setCurrentConnectionType],
  );

  const handleModalClose = useCallback(() => {
    dispatch({ type: ModalActions.CLOSE });
  }, [dispatch]);

  const stepsCount =
    currentConnectionType !== null
      ? addDeviceSteps[currentConnectionType].length + 2
      : -1;

  return (
    <>
      <Header title="addDevice" back fixed />

      <FirstStep
        step={1}
        isCurrent={currentStep === 1}
        onStepChange={handleStepChange}
        onConnectionTypeChange={handleConnectionTypeChange}
      />

      {currentConnectionType !== null &&
        addDeviceSteps[currentConnectionType].map((step, i) => (
          <Step
            key={i}
            text={step}
            step={i + 2}
            maxStep={stepsCount}
            onStepChange={handleStepChange}
            isCurrent={currentStep === i + 2}
          />
        ))}

      <Step
        step={stepsCount}
        maxStep={stepsCount}
        onStepChange={handleStepChange}
        isCurrent={currentStep === stepsCount}
      >
        <FormWrapper title={t('addDevice')} icon="add">
          <AddDevice
            factories={factories}
            isPending={isFactoriesPending}
            onSubmit={handleSubmit}
          />
        </FormWrapper>
      </Step>
      <EmailConfirmedModal open={isModalOpen} handleClose={handleModalClose} />
    </>
  );
};

const AddDeviceContainerWrapper: React.FC<CustomRouteComponentProps> = (
  props,
) => {
  return (
    <Page title={props.title}>
      <AddDeviceContainer {...props} />
    </Page>
  );
};

export default memo(AddDeviceContainerWrapper);
